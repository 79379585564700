var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("questions", {
        attrs: {
          stay_id: _vm.stay_id,
          category: _vm.QuestionCategory.SOCIAL_HISTORY,
          parentObject: _vm.syndrome
        }
      }),
      _vm._v(" "),
      _c("generic", {
        attrs: { stay_id: _vm.stay_id, fields: _vm.socialHistoryNotesField }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }