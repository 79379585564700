import {
    array, string as dstring, boolean as dboolean, number as dnumber, nullable, exact, guard
} from 'decoders'

export interface StayFilters {
    /** care type (classification) selection */
    cares: number[]
    diagnoses: number[]
    doctors: number[]
    hospitals: number[]
    spaces: number[]
    specialities: number[]
}


export type FilterParamModifier = 'all' | 'empty'
export type FilterParam = number[] | FilterParamModifier

// NOTE: if changing this, update store/modules/user getter stayFilters/validFilters variable
export interface StayFiltersParams {
    cares: FilterParam
    diagnoses: FilterParam
    doctors: FilterParam
    hospitals: FilterParam
    spaces: FilterParam
    specialities: FilterParam
}

export interface Hyperacutes {
    start: string | null
    end: string | null
    doctors: number[]
}

export interface UserState {
    // read-only
    username: string
    alt_username: string
    display_name: string
    /** number of alternate username logins so far */
    alt_logins: number
    /** flag for whether current session is logged in with alternate username */
    is_alt: boolean
    groups: string[]
    hospitals: number[]
    is_senior: boolean
    is_superuser: boolean
    is_temp: boolean
    pwd_expired: boolean | null

    // superuser-editable-only
    speciality: number | null
    telestroke_mode: boolean

    // editable, user attribute
    triage_mode: boolean

    // actual ux preferences
    anonymise: boolean
    /** flag for appending signature to notes */
    appendSig: boolean
    asapVizSites: string[]
    asmtSidebarDocked: boolean,
    asmtTextDocked: boolean,
    breakdowns: {
        hyperacutes: Hyperacutes,
    },
    debug_mode: boolean
    extras_mode: boolean
    filters: StayFilters
    hidden_columns: string[]
    hidden_stays: number[]
    hide_stays: boolean
    home_stay_ids: boolean
    /** determines whether to include the print header when printing */
    print_header: boolean
    /** an optional custom signature to append to EMR notes */
    signature: string
    /** continuously save in TextArea component */
    textAreaContSave: boolean
    textOutputPlain: boolean
    textOutputWardV1: boolean
    team: number | null
    /** runs incoming JSON through decoders */
    typeCheckJSON: boolean
}

const userStateDef = {
    username: dstring,
    alt_username: dstring,
    display_name: dstring,
    alt_logins: dnumber,
    is_alt: dboolean,
    groups: array(dstring),
    hospitals: array(dnumber),
    is_senior: dboolean,
    is_superuser: dboolean,
    is_temp: dboolean,

    speciality: nullable(dnumber),
    telestroke_mode: dboolean,

    triage_mode: dboolean,

    anonymise: dboolean,
    appendSig: dboolean,
    asapVizSites: array(dstring),
    asmtSidebarDocked: dboolean,
    asmtTextDocked: dboolean,
    breakdowns: exact({
        hyperacutes: exact({
            start: nullable(dstring),
            end: nullable(dstring),
            doctors: array(dnumber),
        }),
    }),
    debug_mode: dboolean,
    extras_mode: dboolean,
    filters: exact({
        cares: array(dnumber),
        diagnoses: array(dnumber),
        doctors: array(dnumber),
        hospitals: array(dnumber),
        spaces: array(dnumber),
        specialities: array(dnumber),
    }),
    hidden_columns: array(dstring),
    hidden_stays: array(dnumber),
    hide_stays: dboolean,
    home_stay_ids: dboolean,
    print_header: dboolean,
    pwd_expired: nullable(dboolean),
    signature: dstring,
    textAreaContSave: dboolean,
    textOutputAdmV1: dboolean,
    textOutputPlain: dboolean,
    textOutputWardV1: dboolean,
    team: nullable(dnumber),
    typeCheckJSON: dboolean,
}

const userState = exact(userStateDef)

export const userStateDecoder = guard(userState, { style: 'simple' })

// sanity check that decoder matches state
try { const _userState: UserState = userStateDecoder({}) } catch (e) { }
