var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "scrwl-dd scrollable",
      class: { wrap: _vm.wrapText },
      attrs: { "label-cols-sm": _vm.labelCols, "label-class": "pb-0" },
      scopedSlots: _vm._u([
        {
          key: "label",
          fn: function() {
            return [
              _vm.label && !_vm.hide_label
                ? _c(
                    "label",
                    { staticClass: "m-0" },
                    [
                      _vm._v(
                        "\n            " + _vm._s(_vm.label) + "\n            "
                      ),
                      _vm.useHelpIcon
                        ? [
                            _c("fnt-a-icon", {
                              attrs: {
                                icon: ["fal", "question-circle"],
                                id: _vm.helpIconHtmlId
                              }
                            }),
                            _vm._v(" "),
                            _c(
                              "b-tooltip",
                              {
                                attrs: {
                                  target: _vm.helpIconHtmlId,
                                  title: _vm.helpText,
                                  triggers: "click"
                                }
                              },
                              [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(_vm.helpText) +
                                    "\n                "
                                )
                              ]
                            )
                          ]
                        : _vm._e()
                    ],
                    2
                  )
                : _vm._e()
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _vm._v(" "),
      _c(
        "b-dd",
        {
          class: _vm.extraClasses,
          attrs: {
            variant: _vm.variant,
            "toggle-class": _vm.toggleClasses,
            size: _vm.size,
            right: _vm.alignRight,
            "no-flip": _vm.noFlip,
            text: _vm.selectedTextShort,
            title: _vm.selectedText
          },
          on: { hide: _vm.hideHandler, hidden: _vm.hiddenHandler }
        },
        [
          _vm.addClearAll
            ? _c(
                "b-dd-item-btn",
                {
                  attrs: { title: _vm.clearAllText },
                  on: { click: _vm.clearSelected }
                },
                [_c("em", [_vm._v(_vm._s(_vm.clearAllText))])]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.localItems, function(item) {
            return _c(
              "b-dd-item-btn",
              {
                key: item[_vm.valueField],
                class: { active: _vm.isSelected(item[_vm.valueField]) },
                attrs: { title: item[_vm.textField] },
                on: {
                  click: function($event) {
                    return _vm.toggleSelect(item[_vm.valueField])
                  }
                }
              },
              [
                _c("span", { staticClass: "item-txt" }, [
                  _vm._v(_vm._s(item[_vm.textField]))
                ]),
                _vm._v(" "),
                _vm.isSelected(item[_vm.valueField])
                  ? _c("fnt-a-icon", { attrs: { icon: ["fal", "check"] } })
                  : _vm._e(),
                _vm._v(" "),
                item[_vm.noteField] !== undefined
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "item-txt font-weight-light text-white-60 ml-2"
                      },
                      [_vm._v("  " + _vm._s(item[_vm.noteField]))]
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("div", { staticClass: "desc text-white-60 mt-1" }, [
        _vm.selectedItemNote
          ? _c("div", [
              _c(
                "p",
                { staticClass: "mb-0", attrs: { title: "Additional notes" } },
                [_c("strong", [_vm._v("Add. notes")])]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "font-weight-light mb-0" }, [
                _vm._v(_vm._s(_vm.selectedItemNote))
              ])
            ])
          : _vm._e(),
        _vm._v(" "),
        !_vm.useHelpIcon && _vm.helpText
          ? _c("div", { domProps: { innerHTML: _vm._s(_vm.helpText) } })
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }