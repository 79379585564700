var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "scrwl-dd scrollable mb-0",
      attrs: { "label-class": "pb-0" }
    },
    [
      _c(
        "b-dd",
        { attrs: { variant: "primary", text: _vm.selectedText } },
        [
          _vm.atLeastOne
            ? [
                _vm._l(_vm.groupedDoctors, function(speciality) {
                  return [
                    _c("b-dd-text", { key: "sp_" + speciality.id }, [
                      _c("em", [_vm._v(_vm._s(speciality.title))])
                    ]),
                    _vm._v(" "),
                    speciality.doctors.length
                      ? _vm._l(speciality.doctors, function(doctor) {
                          return _c(
                            "b-dd-item-btn",
                            {
                              key: "doc_" + doctor.id,
                              staticClass: "ml-3",
                              class: { active: _vm.isSelected(doctor.id) },
                              attrs: { title: doctor.hoverText },
                              on: {
                                click: function($event) {
                                  $event.stopPropagation()
                                  return _vm.toggleSelect(doctor.id)
                                }
                              }
                            },
                            [
                              _vm._v(
                                "\n                        " +
                                  _vm._s(doctor.last_name) +
                                  "\n                        "
                              ),
                              _vm.isSelected(doctor.id)
                                ? _c("fnt-a-icon", {
                                    attrs: { icon: ["fal", "check"] }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        })
                      : _c("b-dd-text", { staticClass: "ml-3" }, [
                          _c("em", [_vm._v("None")])
                        ])
                  ]
                })
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }