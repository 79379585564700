













































import Vue from "vue"
import { cloneDeep, filter, find, includes, indexOf, map } from "lodash-es"
import utils from 'utils'

export default Vue.extend({
    props: {
        debugMode: {
            type: Boolean,
            default: false
        },
        debugLabelText: String,
        value: {},
        horizontal: {
            type: Boolean,
            default: false
        },
        fullWidth: {
            type: Boolean,
            default: false
        },
        label: {
            type: String,
            default: ""
        },
        /** hide the label */
        hide_label: {
            type: Boolean,
            default: false
        },
        wrapText: {
            type: Boolean,
            default: false
        },
        /** TODO: this may no longer be used. Prepare to deprecate/remove -RC */
        ddClasses: {
            type: String
        },
        ddToggleClasses: {
            type: String
        },
        variant: {
            type: String,
            default: null
        },
        size: {
            type: String as () => 'sm' | 'md' | 'lg',
            default: 'md' as 'sm' | 'md' | 'lg'
        },
        alignRight: {
            type: Boolean,
            default: false
        },
        noFlip: {
            type: Boolean,
            default: false,
        },
        items: {
            type: Array as () => Array<any>,
            required: true
        },
        addNullItem: {
            type: Boolean,
            default: false
        },
        nullItemValue: {
            default: null
        },
        nullItemText: {
            type: String,
            default: '[ Other ]'
        },
        chooseText: {
            type: String,
            default: "[ Choose ]"
        },
        selectAll: {
            type: Boolean,
            default: false
        },
        textField: {
            type: String,
            default: "text"
        },
        valueField: {
            type: String,
            default: "id",
        },
        noteField: {
            type: String,
            default: 'note',
        },
        multi: {
            type: Boolean,
            default: false
        },
        truncateText: {
            type: Boolean,
            default: false
        },
        truncateMaxLength: {
            type: Number,
            default: 30
        },
        /** this also delays emitting the selected value until the dropdown is closed */
        closeOnSelect: {
            type: Boolean,
            default: true
        },
        addClearAll: {
            type: Boolean,
            default: false
        },
        clearAllText: {
            type: String,
            default: '[All]'
        },
        helpText: {
            type: String,
        },
        useHelpIcon: {
            type: Boolean,
            default: false
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            selected: this.multi ? cloneDeep(this.value) as any : this.value,
            selecting: false,
            helpIconHtmlId: `${uid}___help`,
        }
    },
    computed: {
        labelCols(): boolean | number {
            return this.horizontal ? 4 : false
        },
        extraClasses(): string {
            const classes = []
            if (this.ddClasses) classes.push(this.ddClasses)
            if (this.fullWidth) classes.push('w-100')
            return classes.join(' ')
        },
        toggleClasses(): string {
            const classes = ['text-truncate']
            if (this.ddToggleClasses) classes.push(this.ddToggleClasses)
            if (this.extraClasses) classes.push(this.extraClasses)
            return classes.join(' ')
        },
        localItems(): any[] {
            const items = filter(cloneDeep(this.items), item => !item.hide)
            if (this.addNullItem) {
                const nullItem: { [k: string]: any } = {}
                nullItem[this.valueField] = this.nullItemValue
                nullItem[this.textField] = this.nullItemText
                items.push(nullItem)
            }
            return items
        },
        selectedItem(): any {
            return find(this.localItems, [this.valueField, this.selected])
        },
        selectedItems(): any[] {
            const self = this
            return filter(this.localItems, function(item) {
                return includes(self.selected, item[self.valueField])
            })
        },
        selectedText(): string {
            let text = ''
            if (this.multi) {
                if (this.selectedItems.length !== 0)
                    text = map(this.selectedItems, item => item[this.textField]).join(", ")
            }
            else {
                if (this.selectedItem)
                    text = this.selectedItem[this.textField]
            }
            if (!text)
                text = this.chooseText

            return text
        },
        selectedTextShort(): string {
            if (this.truncateText && this.selectedText.length > this.truncateMaxLength)
                return `${this.selectedText.slice(0, this.truncateMaxLength)}...`
            return this.selectedText
        },
        selectedItemNote(): string | undefined {
            if (this.multi || !this.selectedItem) return
            return this.selectedItem.note || undefined
        },
    },
    watch: {
        value(val) {
            if (this.multi) {
                if (!utils.isEqual(val, this.selected))
                    this.selected = cloneDeep(val)
            }
            else
                this.selected = val
        },
        selected() {
            if (this.closeOnSelect)
                this.emitValue()
        }
    },
    methods: {
        isSelected(val: any): boolean {
            return this.multi
                ? includes(this.selected, val)
                : this.selected === val
        },
        toggleSelect(val: any, event: Event) {
            this.selecting = true
            if (this.isSelected(val)) {
                if (this.multi) {
                    const i = indexOf(this.selected, val)
                    if (i !== -1)
                        this.selected.splice(i, 1)
                }
                else
                    this.selected = null
            }
            else {
                if (this.multi)
                    this.selected.push(val)
                else
                    this.selected = val
            }
        },
        emitValue() {
            if (this.multi)
                this.$emit('input', cloneDeep(this.selected))
            else
                this.$emit('input', this.selected)
        },
        hideHandler(event: any) {
            if (!this.closeOnSelect && this.selecting)
                event.preventDefault()
            this.selecting = false
        },
        hiddenHandler(event: any) {
            if (!this.closeOnSelect)
                this.emitValue()
            this.$emit('selection-done')
        },
        clearSelected() {
            this.selected = this.multi ? [] : this.nullItemValue
        }
    }
})
