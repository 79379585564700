






























import mixins from "vue-typed-mixins"

import text, { PBR, SECTION_START } from "utils/text"
import { fieldText } from "utils/text/helpers"
import { TextDetailsExt } from 'utils/text/interfaces'
import {
    background,
    presentingComplaintFull,
    socialHistory
} from "utils/text/clinic_history"
import { diagnosis } from "utils/text/diagnosis"
import { examination } from "utils/text/exam"
import { baselineInvestigations } from "utils/text/investigation"
import {
    activeIssuesWithSummaryText,
    resolvedIssuesTitles
} from "utils/text/issue"
import { historicalMedications } from "utils/text/meds"
import { admInvsSummary } from "utils/text/investigation"
import { managementPlan } from "utils/text/mgmtPlan"
import { admissionNihss } from "utils/text/nihss"
import { patientDetailsWithHospital } from "utils/text/patient"
import { attending } from "utils/text/team"
import stays from "@store/stays"
import { Stay } from "models/data/stay"

import DisplayMixin from "@mixins/Display.vue"
import Attending from "@shared/stay-inputs/Attending.vue"
import TextBase from "../TextBase.vue"

export default mixins(DisplayMixin).extend({
    components: {
        Attending,
        TextBase
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showExtras: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        usePlain: {
            get(): boolean {
                return this.$store.direct.getters.user.textOutputPlain
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.setTextOutputPlain(val)
            }
        },
        markup(): string {
            if (!this.stay) return ""

            const plain = this.usePlain
            const textDetails: TextDetailsExt = {
                stay: this.stay,
                anonymise: this.$store.direct.state.user.anonymise,
                plain,
                noInfo: true
            }

            let markup = SECTION_START
            let title = "Acute Assessment and Management Plan"
            if (this.telestrokeMode) title = `Telestroke ${title}`
            markup += `${title}  \n`

            markup += attending(textDetails, "adm", plain) + PBR
            markup += patientDetailsWithHospital(textDetails) + PBR

            markup += SECTION_START
            markup += presentingComplaintFull(textDetails, plain) + PBR
            markup += background(textDetails) + PBR
            markup += historicalMedications(textDetails) + PBR
            markup += socialHistory(textDetails) + PBR

            markup += SECTION_START
            markup += examination(textDetails, plain) + PBR
            markup += admissionNihss(textDetails, {
                advancedDesc: true,
                heading: "",
                plain
            })
            markup += PBR

            markup += SECTION_START
            markup += baselineInvestigations(textDetails, plain) + PBR

            markup += SECTION_START
            markup += diagnosis(textDetails, plain) + PBR

            // Issues
            if (this.telestrokeMode) {
                markup += activeIssuesWithSummaryText(textDetails, plain)
                markup += PBR
                markup += resolvedIssuesTitles(textDetails, true, plain) + PBR
                // phase out once all active stays are using issues above
                markup += text.otherIssues(textDetails)
            } else {
                markup += activeIssuesWithSummaryText(textDetails, plain)
                markup += PBR
                markup += resolvedIssuesTitles(textDetails, false, plain) + PBR
            }

            markup += SECTION_START
            markup += text.thrombolysis(textDetails, plain)
            markup += managementPlan(textDetails, plain)

            const planNotes = fieldText(textDetails, "management_plan.notes")
            if (planNotes.length) markup += planNotes + PBR

            markup += admInvsSummary(textDetails, plain)
            markup += PBR

            return markup
        }
    },
    methods: {
        markAdmissionComplete() {
            if (this.stay && this.stay.stage !== 'WARD')
                stays.extActSetPathValue(this.stay_id, "stage", "WARD")
            // stays.extActSetPathValue(this.stay_id, "admission_emr_notes", this.notes)
            stays.extActSetPathValue(this.stay_id, "admission_nihss.editable", false)
            stays.mutTriggerLoadStays()
        }
    }
})
