




















import mixins from 'vue-typed-mixins'
import { each, filter, find, includes, map, sortBy, uniqBy, values } from 'lodash-es'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import StaysFilterMixin from '@shared/StaysFilterMixin.vue'
import SurgeryMixin from '@shared/SurgeryMixin.vue'
import CollapseIndicator from '@shared/CollapseIndicator.vue'
import MdtPresent from './MDTPresent.vue'
import OverviewTable from './OverviewTable.vue'
import Print from '@home/Print.vue'
import StayFilters from "@shared/StayFilters.vue"
import TsScopeSelect from '@shared/TSScopeSelect.vue'

export default mixins(DelayRouteLeaveMixin, StaysFilterMixin, SurgeryMixin).extend({
    components: {
        CollapseIndicator,
        MdtPresent,
        OverviewTable,
        Print,
        StayFilters,
        TsScopeSelect,
    },
    data() {
        return {
            mdtHtmlId: '',
            mdtOpened: false,
        }
    },
    beforeCreate() {
        stays.mutClearStays()
    },
    created() {
        this.pull()
    },
    mounted() {
        this.$store.direct.commit.session.setOnDashboard(true)
    },
    beforeDestroy() {
        this.$store.direct.commit.session.setOnDashboard(false)
    },
    computed: {
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        stays(): Stay[] {
            if (!stays.state.stays) return []
            return values(stays.state.stays)
        },
        // this is copied over from home/v2/Stays.vue. should be consolidated in the future
        advOrderedStays(): Stay[] {
            const orderedStays = sortBy(this.stays, stay => {
                return stay.location ? `${stay.location.ward}${stay.location.bed}` : ''
            })
            let reorderedStays: Stay[] = []
            const specialityIds = this.$store.direct.getters.templates.specialityIds

            each(specialityIds, spId => {
                const speciality = this.$store.direct.getters.templates.speciality(spId)
                if (speciality) {
                    const locations: string[] = speciality.location_order.split(',')
                    each(locations, locDef => {
                        const wardBed = locDef.trim().split('-')
                        if (wardBed.length === 1) {
                            const ward = wardBed[0]
                            const stays: Stay[] = filter(orderedStays, stay => (!!stay.location && stay.location.ward === ward))
                            reorderedStays.push(...stays)
                        }
                        else if (wardBed.length === 2) {
                            const ward = wardBed[0], bed = wardBed[1]
                            const stay: Stay | undefined = find(orderedStays, stay => (!!stay.location && stay.location.ward === ward && stay.location.bed === bed))
                            if (stay)
                                reorderedStays.push(stay)
                        }
                    })
                }
            })

            if (reorderedStays.length === 0)
                return orderedStays

            const reorderedIds = map(reorderedStays, 'id')
            const otherStays = filter(orderedStays, stay => !includes(reorderedIds, stay.id))
            reorderedStays.push(...otherStays)
            reorderedStays = uniqBy(reorderedStays, 'id')

            return reorderedStays
        },
        stay_ids(): number[] {
            return map(this.advOrderedStays, s => s.id)
        },
        useSpecialities(): boolean {
            return !this.isTSSiteUser
        },
        useHospitals(): boolean {
            return this.isTSSiteUser
        },
    },
    methods: {
        $_pull() {
            return stays.actPullStays({
                url: '/dashboard/overview/',
                params: this.params,
            })
        },
    },
})
