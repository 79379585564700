var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container mt-4" },
    [
      _c(
        "div",
        { staticClass: "row justify-content-center align-items-end" },
        [
          _vm.telestrokeMode
            ? _c("filter-hospitals", {
                staticClass: "mr-4",
                on: { update: _vm.pull }
              })
            : _vm._e(),
          _vm._v(" "),
          _c("doctors-filter", {
            staticClass: "mr-3",
            model: {
              value: _vm.doctors,
              callback: function($$v) {
                _vm.doctors = $$v
              },
              expression: "doctors"
            }
          }),
          _vm._v(" "),
          _c("dates-controls", { attrs: { start: _vm.start, end: _vm.end } })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-tabs",
        {
          ref: "tabs",
          staticClass: "mt-4",
          attrs: { justified: "", "content-class": "mt-4" },
          model: {
            value: _vm.activeTab,
            callback: function($$v) {
              _vm.activeTab = $$v
            },
            expression: "activeTab"
          }
        },
        [
          _c("b-tab", { attrs: { title: "Hyperacute reviews" } }, [
            _vm.notReady
              ? _c("div", { staticClass: "text-center" }, [_c("b-spinner")], 1)
              : _c(
                  "div",
                  [
                    _c("data-tables", {
                      attrs: {
                        data: _vm.data,
                        tables: _vm.tables.hyperacuteService(_vm.telestrokeMode)
                      }
                    }),
                    _vm._v(" "),
                    _c("hyperacute-review", {
                      staticClass: "mt-2",
                      attrs: {
                        loading: _vm.loading,
                        presentationTypes: _vm.presentationTypes
                      }
                    })
                  ],
                  1
                )
          ]),
          _vm._v(" "),
          !_vm.telestrokeMode
            ? _c("b-tab", { attrs: { title: "Summary of service" } }, [
                _vm.notReady
                  ? _c(
                      "div",
                      { staticClass: "text-center" },
                      [_c("b-spinner")],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("h3", { staticClass: "text-center" }, [
                          _vm._v("Summary of stroke service")
                        ]),
                        _vm._v(" "),
                        _c("data-tables", {
                          attrs: { data: _vm.data, tables: _vm.tables.service }
                        }),
                        _vm._v(" "),
                        _c("data-tables", {
                          attrs: {
                            data: _vm.data,
                            tables: _vm.tables.doorToNeedle
                          }
                        }),
                        _vm._v(" "),
                        _c("data-tables", {
                          attrs: {
                            data: _vm.data,
                            tables: _vm.tables.serviceExtra
                          }
                        }),
                        _vm._v(" "),
                        _c("strokes-nurse-reviewed", {
                          attrs: { data: _vm.data }
                        })
                      ],
                      1
                    )
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.telestrokeMode
            ? _c(
                "b-tab",
                { attrs: { title: "Stroke care" } },
                [
                  _c("data-tables", {
                    attrs: { data: _vm.data, tables: _vm.tables.strokeCare }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      false && _vm.data && _vm.data.stays
        ? _vm._l(_vm.data.stays, function(stay) {
            return _c("stay-edit-modal", {
              key: _vm.stayEditModalId(stay.id),
              attrs: {
                modalId: _vm.stayEditModalId(stay.id),
                stay_id: stay.id
              },
              on: {
                hidden: function($event) {
                  return _vm.editingDone(stay.id)
                }
              }
            })
          })
        : _vm._e(),
      _vm._v(" "),
      _c("stay-edit-modal", {
        attrs: { modalId: "viz-stay-edit-modal", stay_id: _vm.editStayID },
        on: { hidden: _vm.pull }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }