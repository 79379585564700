var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("care-classification", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _vm.isNeurologyStay
        ? [
            _c("last-seen-well", { attrs: { stay_id: _vm.stay_id } }),
            _vm._v(" "),
            _c("admission-time", {
              staticClass: "my-4",
              attrs: { stay_id: _vm.stay_id, label: "Triage time (as per EMR)" }
            }),
            _vm._v(" "),
            _vm.isThrombolysis
              ? _c("thrombolysis-time", {
                  staticClass: "mb-4",
                  attrs: { stay_id: _vm.stay_id, stacked: "" }
                })
              : _vm._e()
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("questions", {
        attrs: {
          stay_id: _vm.stay_id,
          category: _vm.QuestionCategory.DATA_CAPTURE,
          parentObject: _vm.syndrome,
          isRapidAssessment: _vm.isRapidAssessment,
          firstItemTopBorder: false
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }