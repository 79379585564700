var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "row align-items-start",
      class: {
        "justify-content-center": !_vm.asmtVisible,
        "scrwl-h90": _vm.notesDocked
      }
    },
    [
      _vm.asmtVisible
        ? _c(
            "div",
            {
              staticClass: "col",
              class: {
                "overflow-auto": _vm.notesDocked,
                "mb-3": _vm.notesDocked,
                "scrwl-h100": _vm.notesDocked
              }
            },
            [
              _c(
                "b-tabs",
                { attrs: { "nav-class": "mb-2", pills: "", lazy: "" } },
                [
                  !_vm.isTempUser
                    ? [
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              active: _vm.tab === "clinic_history",
                              title: "Clinic History"
                            }
                          },
                          [
                            _c("clinic-history", {
                              attrs: { stay_id: _vm.stay_id }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              active: _vm.tab === "exam",
                              title: "Examination"
                            }
                          },
                          [
                            _c("examination", {
                              attrs: { stay_id: _vm.stay_id }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              active: _vm.tab === "investigation",
                              title: "Investigations"
                            }
                          },
                          [
                            _c("baselines", { attrs: { stay_id: _vm.stay_id } })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "b-tab",
                          {
                            attrs: {
                              active: _vm.tab === "management",
                              title: "Management"
                            }
                          },
                          [
                            _c("management", {
                              attrs: { stay_id: _vm.stay_id }
                            })
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("data-capture-tab", {
                          attrs: { stay_id: _vm.stay_id, tab: _vm.tab }
                        })
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.notesDocked
                    ? _c(
                        "b-tab",
                        {
                          attrs: {
                            active: _vm.tab === "notes",
                            title: "EMR Notes"
                          }
                        },
                        [
                          _c("admission-text", {
                            attrs: { stay_id: _vm.stay_id }
                          })
                        ],
                        1
                      )
                    : _vm._e()
                ],
                2
              )
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.notesDocked
        ? _c(
            "div",
            {
              class: {
                "col-6 col-xl-4": _vm.asmtVisible,
                "col col-md-10 col-xl-8": !_vm.asmtVisible,
                "mb-3": _vm.notesDocked,
                "scrwl-h100": _vm.notesDocked
              }
            },
            [
              _c("admission-text", {
                attrs: { stay_id: _vm.stay_id, showExtras: false }
              })
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }