

























import mixins from 'vue-typed-mixins'
import { each, filter, get, includes, map, max, random, sortBy, values } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { StayProxy, StrokeBed } from 'models/data/dashboard'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import SurgeryMixin from '@shared/SurgeryMixin.vue'
import CollapseIndicator from '@shared/CollapseIndicator.vue'
import DoctorsFilter from './DoctorsFilterIgnorePrefs.vue'
import MdtPresent from './MDTPresent.vue'
import OverviewTable from './OverviewTable.vue'


export default mixins(DelayRouteLeaveMixin, SurgeryMixin).extend({
    components: {
        CollapseIndicator,
        DoctorsFilter,
        MdtPresent,
        OverviewTable,
    },
    data() {
        return {
            mdtHtmlId: '',
            mdtOpened: false,
            selectedDoctors: [] as number[],
            strokeUnitBeds: [] as StrokeBed[],
        }
    },
    computed: {
        stays(): Stay[] {
            if (!stays.state.stays)
                return []

            const allStays = values(stays.state.stays)

            if (this.selectedDoctors.length)
                return filter(allStays, stay => includes(this.selectedDoctors, get(stay, 'doctor.id')))

            return sortBy(allStays, stay => {
                return stay.location ? `${stay.location.ward}${stay.location.bed}` : ''
            })
        },
        nonStrokeUnitStays(): Stay[] {
            return filter(this.stays, ['location.is_stroke_bed', false])
        },
        /** This always enumerates to a list of "stays" representing all stroke units beds.
         *  The stay may be an actual stay, or a proxy "stay" to ensure the bed is displayed.
         */
        strokeUnitStays(): (Stay | StayProxy)[] {
            const stays = filter(this.stays, ['location.is_stroke_bed', true])
            let randomID = (max(map(stays, s => s.id)) || 1) + random(0, (new Date()).valueOf())
            let rows: (Stay | StayProxy)[] = []

            each(this.strokeUnitBeds, location => {
                let matched = false

                if (location.bed) {
                    each(stays, stay => {
                        if (!stay.location) return

                        if (stay.location.ward === location.ward
                            && stay.location.bed === location.bed) {
                            rows.push(stay)
                            matched = true
                            return false  // break loop
                        }

                        return
                    })
                }
                else {  // stroke "bed" is any bed in this location's ward
                    matched = true  // always "matches", since there no way for us to know how many rows to add for a ward
                    rows = rows.concat(
                        filter(
                            stays,
                            stay => (!!stay.location && stay.location.ward === location.ward)
                        )
                    )
                }

                if (!matched)
                    rows.push({
                        id: randomID++,
                        location: { ward: location.ward, room: '', bed: location.bed, is_stroke_bed: true }
                    })
            })

            return rows
        },
    },
    beforeCreate() {
        stays.mutClearStays()
    },
    created() {
        this.fetchStrokeBeds()
        .then(res => {
            return this.fetchStayData()
        })
    },
    mounted() {
        this.$store.direct.commit.session.setOnDashboard(true)
    },
    beforeDestroy() {
        this.$store.direct.commit.session.setOnDashboard(false)
    },
    methods: {
        fetchStrokeBeds() {
            const self = this
            return utils
                .request
                .get('/stroke-beds/')
                .then(res => {
                    self.strokeUnitBeds = res.body
                })
        },
        fetchStayData() {
            return stays.actPullStays({ url: '/dashboard/overview-stroke/' })
        }
    }
})
