var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-base", {
    staticClass: "scrwl-h100",
    attrs: {
      stay_id: _vm.stay_id,
      markup: _vm.markup,
      tag: "dsch",
      doConfirm: false,
      showExtras: false,
      useSignature: false
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }