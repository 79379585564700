



import Vue from 'vue'
import { each, find, get, has } from 'lodash-es'

export default Vue.extend({
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
    },
    mounted() {
        if (!this.isTempUser)
            return
        if (this.$store.direct.state.session.copyBtnLit)
            return

        window.setTimeout(() => {
            const btns = document.querySelectorAll('.tox-tinymce button')
            const copyBtn_ = find(btns, btn => (btn as HTMLElement).innerText === 'Copy All')

            if (!copyBtn_)
                return

            const copyBtn = copyBtn_ as HTMLElement
            const btnRect = copyBtn.getBoundingClientRect()
            const btnHalfH = btnRect.height / 2  // button half-height
            const x = btnRect.x + (btnRect.width / 2)
            const y = btnRect.y + btnHalfH

            const overlay = document.createElement('div')
            overlay.classList.add('scrwl-overlay')
            const spotlightText = `background: radial-gradient(circle at ${x}px ${y}px, transparent, #000 18%);`
            overlay.style.cssText += spotlightText

            function escListener(ev: KeyboardEvent) {
                console.log(ev)
                if (ev.key === 'Escape')
                    removeOverlay()
            }

            function removeOverlay() {
                document.removeEventListener('keyup', escListener)
                document.body.removeChild(overlay)
            }

            document.addEventListener('keyup', escListener)

            const helpBox = document.createElement('div')
            const helpBoxY = btnRect.bottom - btnHalfH
            helpBox.className = 'p-3 bg-primary border'
            helpBox.style.cssText = `position: absolute; left: ${btnRect.right}px; top: ${helpBoxY}px;`

            const helpText = 'Click "Copy All" to copy text.'
            const helpTextBox = document.createElement('p')
            helpTextBox.innerText = helpText
            helpBox.appendChild(helpTextBox)

            const dismissBox = document.createElement('div')
            dismissBox.innerHTML += '<span class="text-monospace">[Click OK or press Esc to dismiss this message]</span><br>'
            const okBtn = document.createElement('button')
            okBtn.type = 'button'
            okBtn.className = 'btn btn-info'
            okBtn.innerText = 'OK'
            okBtn.addEventListener('click', () => { removeOverlay() })
            okBtn.addEventListener('touchend', () => { removeOverlay() })
            dismissBox.appendChild(okBtn)
            helpBox.appendChild(dismissBox)

            overlay.appendChild(helpBox)
            document.body.appendChild(overlay)
            this.$store.direct.commit.session.copyBtnLit()
        }, 1000)
    }
})
