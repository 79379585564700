import { map } from 'lodash-es'
import {
    allCapsSnakeToUpperFirst,
    doPlainIndent,
    fieldText,
    freeTextSubHtmlBreaks,
} from './helpers'
import { TextDetails } from './interfaces'
import { genericQuestionsText } from './question'
import { wardRoundsMedications } from './meds'
import { QuestionCategory } from 'models/med_templates/question'
import stays from '@store/stays'


export function wardExam({ stay }: TextDetails, plain: boolean = false): string {
    if (!(stay && stay.ward_round)) return ""

    let lines: string[] = []

    const text = genericQuestionsText(
        {
            stay,
            category: QuestionCategory.WARD_EXAM,
            getQuestionGroupsFrom: stays.getSpeciality(stay.id),
        }
    )

    if (text.length)
        lines.push(text)

    const notes = fieldText({ stay }, 'ward_round.exam_notes')
    if (notes)
        lines.push(notes)

    if (lines.length === 0)
        return ''

    if (plain) {
        lines.unshift('Exam:')
        return doPlainIndent(lines.join('<br>'))
    }
    lines.unshift('**Exam:**')
    return lines.join("  \n") + "\n\n"
}

export function wardProgress({ stay }: TextDetails, plain: boolean = false): string {
    let text = fieldText({ stay }, 'ward_round.progress')
    if (!text) return ""
    return plain ? doPlainIndent(`Progress:<br>${text}`) : `**Progress:**<br>${text}\n\n`
}

export function wardProgressPlain(textDetails: TextDetails): string {
    return wardProgress(textDetails, true)
}

export function discussed({ stay }: TextDetails, plain: boolean = false): string {
    if (!(stay && stay.ward_round)) return ""

    let lines: string[] = []

    const discussionChecklist = stay.ward_round.discussion_checklist || []
    const discussedText = map(discussionChecklist, allCapsSnakeToUpperFirst).join(", ")

    if (discussedText.length) lines.push(discussedText)

    const discussionNotes = stay.ward_round.patient_doctor_notes
    if (discussionNotes) lines.push(freeTextSubHtmlBreaks(discussionNotes))

    if (!lines.length) return ""

    if (plain) {
        lines.unshift('Discussed:')
        return doPlainIndent(lines.join('<br>'))
    }
    return "**Discussed:**<br>" + lines.join("<br>") + "\n\n"
}

export { wardRoundsMedications }
