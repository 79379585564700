var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.diagnosis
    ? _c("div", [
        _vm.diagnosis.help_text
          ? _c("p", { staticClass: "mb-0" }, [
              _vm._v("\n        " + _vm._s(_vm.diagnosis.help_text) + "\n    ")
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.diagnosis.link
          ? _c("p", { staticClass: "mb-0" }, [
              _vm._v("\n        ["),
              _c(
                "a",
                {
                  staticClass: "text-info",
                  attrs: { href: _vm.diagnosis.link, target: "_blank" }
                },
                [_vm._v("Link")]
              ),
              _vm._v("]\n    ")
            ])
          : _vm._e()
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }