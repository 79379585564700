var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.telestrokeMode
    ? _c("div", [
        _c("div", { staticClass: "row mt-2" }, [
          _c("div", { staticClass: "col" }, [
            !_vm.isTempUser
              ? _c(
                  "div",
                  { staticClass: "d-flex flex-row align-items-end" },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex scrawl-grouped" },
                      [
                        _c("filter-hospitals", { on: { update: _vm.pull } }),
                        _vm._v(" "),
                        _c("filter-doctors", { on: { update: _vm.pull } }),
                        _vm._v(" "),
                        _c("ts-scope-select", {
                          model: {
                            value: _vm.tsScope_,
                            callback: function($$v) {
                              _vm.tsScope_ = $$v
                            },
                            expression: "tsScope_"
                          }
                        })
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "show-discharged",
                      _vm._b(
                        {
                          staticClass: "d-flex flex-row align-items-end ml-3",
                          on: { update: _vm.pull }
                        },
                        "show-discharged",
                        _vm.discharge,
                        false,
                        true
                      )
                    ),
                    _vm._v(" "),
                    _c("print", {
                      staticClass: "ml-2",
                      attrs: {
                        stay_ids: _vm.orderedStaysIDs,
                        label: "Print",
                        showLabel: true
                      }
                    }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "d-flex align-items-end ml-auto" },
                      [
                        _c("search-stays"),
                        _vm._v(" "),
                        _c("create-stay", { staticClass: "ml-2" })
                      ],
                      1
                    )
                  ],
                  1
                )
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [
              _c("stays", {
                attrs: {
                  showSite: _vm.telestrokeMode,
                  showLocation: !_vm.telestrokeMode,
                  tsScope: _vm.tsScope
                },
                on: {
                  "visible-stays": function($event) {
                    _vm.normalStays = $event
                  }
                }
              })
            ],
            1
          )
        ]),
        _vm._v(" "),
        _c("hr"),
        _vm._v(" "),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "col" },
            [_c("create-stay", { staticClass: "float-right mt-2" })],
            1
          )
        ]),
        _vm._v(" "),
        _vm.consultsEnabled
          ? _c("div", { staticClass: "row mb-3" }, [
              _c(
                "div",
                { staticClass: "col" },
                [
                  _c("h3", [_vm._v("Consultations")]),
                  _vm._v(" "),
                  _c("stays", {
                    attrs: { stayType: "secondary" },
                    on: {
                      "visible-stays": function($event) {
                        _vm.consultStays = $event
                      }
                    }
                  })
                ],
                1
              )
            ])
          : _vm._e()
      ])
    : _c(
        "div",
        [
          _c("div", { staticClass: "row mt-2" }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "d-flex flex-row align-items-end" },
                [
                  _c("stay-filters", { on: { update: _vm.pull } }),
                  _vm._v(" "),
                  _vm.onlyOneSpeciality
                    ? [
                        _c(
                          "show-discharged",
                          _vm._b(
                            {
                              staticClass:
                                "d-flex flex-row align-items-end ml-3",
                              on: { update: _vm.pull }
                            },
                            "show-discharged",
                            _vm.discharge,
                            false,
                            true
                          )
                        ),
                        _vm._v(" "),
                        _vm.feedOn
                          ? _c(
                              "b-btn",
                              { attrs: { to: { name: "paddock-search-v3" } } },
                              [_vm._v("Find")]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        _c("print", {
                          staticClass: "ml-2",
                          attrs: {
                            stay_ids: _vm.orderedStaysIDs,
                            label: "Print",
                            showLabel: true
                          }
                        }),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "d-flex align-items-end ml-auto" },
                          [_c("create-stay", { staticClass: "ml-2" })],
                          1
                        )
                      ]
                    : _vm._e()
                ],
                2
              )
            ])
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _vm.onlyOneSpeciality
            ? [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [
                      _c("stays", {
                        attrs: {
                          showSite: _vm.telestrokeMode,
                          showLocation: !_vm.telestrokeMode
                        },
                        on: {
                          "visible-stays": function($event) {
                            _vm.normalStays = $event
                          }
                        }
                      })
                    ],
                    1
                  )
                ]),
                _vm._v(" "),
                _c("hr"),
                _vm._v(" "),
                _c("div", { staticClass: "row" }, [
                  _c(
                    "div",
                    { staticClass: "col" },
                    [_c("create-stay", { staticClass: "float-right mt-2" })],
                    1
                  )
                ]),
                _vm._v(" "),
                _vm.consultsEnabled
                  ? _c("div", { staticClass: "row mb-3" }, [
                      _c(
                        "div",
                        { staticClass: "col" },
                        [
                          _c("h3", [_vm._v("Consultations")]),
                          _vm._v(" "),
                          _c("stays", {
                            attrs: { stayType: "secondary" },
                            on: {
                              "visible-stays": function($event) {
                                _vm.consultStays = $event
                              }
                            }
                          })
                        ],
                        1
                      )
                    ])
                  : _vm._e()
              ]
            : _c(
                "div",
                {
                  staticClass:
                    "d-flex align-items-center justify-content-center h-100 mt-5"
                },
                [_vm._m(0)]
              )
        ],
        2
      )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h2", [
      _c("em", [_vm._v("Please select a speciality to use this page.")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }