var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("text-area", {
        attrs: {
          id: _vm.htmlId,
          placeholder: _vm.placeholderText,
          label: "Admission summary"
        },
        model: {
          value: _vm.admissionSummary,
          callback: function($$v) {
            _vm.admissionSummary = $$v
          },
          expression: "admissionSummary"
        }
      }),
      _vm._v(" "),
      _c("b-tooltip", { attrs: { target: _vm.htmlId, placement: "auto" } }, [
        _c("div", {
          staticClass: "text-info",
          staticStyle: { "text-align": "left" },
          domProps: {
            innerHTML: _vm._s(_vm.replaceBreaks(_vm.placeholderText, "<br>"))
          }
        })
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }