var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-modal",
    {
      attrs: { title: "Patient Details", id: _vm.htmlIds.modal, "ok-only": "" }
    },
    [
      _c("p", [_c("em", [_vm._v("Edit details below:")])]),
      _vm._v(" "),
      _c(
        "b-form",
        [
          _c(
            "b-form-group",
            { attrs: { label: "MRN", "label-for": _vm.htmlIds.mrn } },
            [
              _c("b-input", {
                attrs: { id: _vm.htmlIds.mrn, type: "text" },
                model: {
                  value: _vm.mrn,
                  callback: function($$v) {
                    _vm.mrn = $$v
                  },
                  expression: "mrn"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: { label: "First Name", "label-for": _vm.htmlIds.firstName }
            },
            [
              _c("b-input", {
                attrs: { id: _vm.htmlIds.firstName, type: "text" },
                model: {
                  value: _vm.firstName,
                  callback: function($$v) {
                    _vm.firstName = $$v
                  },
                  expression: "firstName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-form-group",
            {
              attrs: { label: "Last Name", "label-for": _vm.htmlIds.lastName }
            },
            [
              _c("b-input", {
                attrs: { id: _vm.htmlIds.lastName, type: "text" },
                model: {
                  value: _vm.lastName,
                  callback: function($$v) {
                    _vm.lastName = $$v
                  },
                  expression: "lastName"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm.approxAge && !_vm.dob
            ? _c(
                "b-form-group",
                { attrs: { label: "Age", "label-for": _vm.htmlIds.approxAge } },
                [
                  _c("b-input", {
                    staticClass: "scrwl",
                    attrs: {
                      id: _vm.htmlIds.approxAge,
                      readonly: "",
                      disabled: "",
                      type: "number",
                      number: ""
                    },
                    model: {
                      value: _vm.approxAge,
                      callback: function($$v) {
                        _vm.approxAge = $$v
                      },
                      expression: "approxAge"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "b-form-group",
            { attrs: { label: "Date of Birth" } },
            [
              _c("date-time-picker", {
                staticClass: "w-100",
                attrs: {
                  placeholder: "Enter Date of Birth",
                  config: {
                    altFormat: "d M Y",
                    dateFormat: "Y-m-d",
                    static: true
                  }
                },
                model: {
                  value: _vm.dob,
                  callback: function($$v) {
                    _vm.dob = $$v
                  },
                  expression: "dob"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }