
















import Vue from 'vue'
import * as commonmark from 'commonmark'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import { background, socialHistory } from 'utils/text/clinic_history'
import { historicalMedications } from 'utils/text/meds'

import CardLapsible from '@shared/CardLapsible.vue'
import CardLapsibleV2 from '@shared/CardLapsible_v2.vue'
import HistoricalMedications from '@admission/HistoricalMedications.vue'
import MedicalHistory from '@admission/MedicalHistory.vue'
import SocialHistory from '@admission/SocialHistory.vue'

export default Vue.extend({
    components: {
        CardLapsible,
        CardLapsibleV2,
        HistoricalMedications,
        MedicalHistory,
        SocialHistory,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        backgroundHTML(): string {
            const bgMarkup = background({ stay: this.stay, hideTitle: true, noInfo: true })
            return this.cmWriter.render(this.cmReader.parse(bgMarkup))
        },
        medsHTML(): string {
            const medsMarkup = historicalMedications({ stay: this.stay, hideTitle: true, noInfo: true })
            return this.cmWriter.render(this.cmReader.parse(medsMarkup))
        },
        socialHTML(): string {
            const socialMarkup = socialHistory({ stay: this.stay, hideTitle: true, noInfo: true })
            return this.cmWriter.render(this.cmReader.parse(socialMarkup))
        }
    },
})
