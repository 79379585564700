var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "mt-3",
      attrs: { "label-class": "pb-0" },
      scopedSlots: _vm._u(
        [
          _vm.debugMode
            ? {
                key: "description",
                fn: function() {
                  return [
                    _c("span", { staticClass: "text-monospace" }, [
                      _vm._v("[" + _vm._s(_vm.debugText) + "]")
                    ])
                  ]
                },
                proxy: true
              }
            : null,
          {
            key: "label",
            fn: function() {
              return [
                _vm._v("\n        Location \n        "),
                _c(
                  "b-btn",
                  {
                    attrs: {
                      variant: _vm.editing ? "success" : "info",
                      size: "sm"
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        _vm.editing = !_vm.editing
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.editing ? "Save" : "Edit") +
                        "\n        "
                    )
                  ]
                ),
                _vm._v(" "),
                _vm.editing
                  ? _c(
                      "b-btn",
                      {
                        attrs: { variant: "warning", size: "sm" },
                        on: {
                          click: function($event) {
                            $event.stopPropagation()
                            return _vm.cancelEdit.apply(null, arguments)
                          }
                        }
                      },
                      [_vm._v("Cancel")]
                    )
                  : _vm._e()
              ]
            },
            proxy: true
          }
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-sm" },
          [
            _c(
              "b-form-group",
              { attrs: { label: "Ward" } },
              [
                _c("b-form-input", {
                  staticClass: "scrwl",
                  attrs: {
                    disabled: !_vm.editing,
                    "aria-disabled": !_vm.editing,
                    trim: ""
                  },
                  model: {
                    value: _vm.location.ward,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "ward", $$v)
                    },
                    expression: "location.ward"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm" },
          [
            _c(
              "b-form-group",
              { attrs: { label: "Room" } },
              [
                _c("b-form-input", {
                  staticClass: "scrwl",
                  attrs: {
                    disabled: !_vm.editing,
                    "aria-disabled": !_vm.editing,
                    trim: ""
                  },
                  model: {
                    value: _vm.location.room,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "room", $$v)
                    },
                    expression: "location.room"
                  }
                })
              ],
              1
            )
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col-sm" },
          [
            _c(
              "b-form-group",
              { attrs: { label: "Bed" } },
              [
                _c("b-form-input", {
                  staticClass: "scrwl",
                  attrs: {
                    disabled: !_vm.editing,
                    "aria-disabled": !_vm.editing,
                    trim: ""
                  },
                  model: {
                    value: _vm.location.bed,
                    callback: function($$v) {
                      _vm.$set(_vm.location, "bed", $$v)
                    },
                    expression: "location.bed"
                  }
                })
              ],
              1
            )
          ],
          1
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }