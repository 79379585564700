









import mixins from 'vue-typed-mixins'
import { find, includes, } from 'lodash-es'
import stays from '@store/stays'

import { PropExtInfo, LayoutGroupExt } from 'models'
import { Stay } from 'models/data/stay'
import { DisplayList, LayoutLookup } from 'models/layout'
import { NestedProp } from 'models/schema'

import DisplayMixin from '@mixins/Display.vue'
import Generic from '@sections/Generic.vue'

const displayInfo: { [k: string]: DisplayList } = {
    presentation: [
        {
            field: 'last_seen_well',
            force_label: 'Last seen well prior to presentation to hospital',
            dropdown: true,
            full_width: true
        },
        {
            field: 'lsw_time',
            hidden: false
        }
    ],
}

const layouts: LayoutLookup = {
    presentation: [
        { fields: [ 'last_seen_well', 'lsw_time' ], alignment: 'inline' }
    ]
}

export default mixins(DisplayMixin).extend({
    components: {
        Generic,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        forceShow: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            displayInfo
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        isStroke(): boolean {
            if (this.stay && this.stay.syndrome) {
                const syndrome = find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome])
                return includes(['Stroke', 'Intracranial haemorrhage'], syndrome?.title)
            }
            return false
        },
        show(): boolean {
            return this.forceShow || this.isStroke
        },
        presentationSchema(): NestedProp | undefined {
            return this.getSectionSchema('presentation')
        },
        presentationFields(): PropExtInfo[] {
            if (this.presentationSchema)
                return this.generatePropExtInfo('presentation',
                    this.presentationSchema.children, this.displayInfo.presentation)
            return []
        },
        presentationGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups('presentation', this.presentationFields, layouts.presentation)
        },
        presentationMsg(): string {
            return this.presentationSchema ? '' : 'Schema loading...'
        },
    }
})
