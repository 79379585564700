var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-check",
        {
          staticClass: "scrawl-checkbox mt-3",
          attrs: { size: "lg" },
          model: {
            value: _vm.allNormal,
            callback: function($$v) {
              _vm.allNormal = $$v
            },
            expression: "allNormal"
          }
        },
        [_vm._v("All normal")]
      ),
      _vm._v(" "),
      _c("generic", {
        attrs: { stay_id: _vm.stay_id, groups: _vm.sensationGroups }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }