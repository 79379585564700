


































































import Vue from 'vue'
import utils from 'utils'
import BetaFeatures from '@misc/BetaFeatures.vue'
import Changelog from '@misc/Changelog.vue'
import ChangePassword from '@misc/ChangePasswordModal.vue'
import DebugControl from "@debug/DebugControl.vue"
import ExtrasControl from '@debug/ExtrasControl.vue'
import TelestrokeModeControl from '@debug/TelestrokeModeControl.vue'
import TriageModeControl from '@debug/TriageModeControl.vue'
import TypeCheckControl from '@debug/TypeCheckControl.vue'
import UserSignature from '@misc/UserSignature.vue'

export default Vue.extend({
    components: {
        BetaFeatures,
        Changelog,
        ChangePassword,
        DebugControl,
        ExtrasControl,
        UserSignature,
        TelestrokeModeControl,
        TriageModeControl,
        TypeCheckControl,
    },
    props: {
        helpModalId: {
            type: String,
        }
    },
    computed: {
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        canSuspendPrivileges(): boolean {
            return this.$store.direct.getters.user.canSuspendPrivileges
        },
        suspendPrivileges: {
            get(): boolean {
                return this.$store.direct.state.session.suspendPrivileges
            },
            set(val: boolean) {
                this.$store.direct.commit.session.suspendPrivileges(val)
            }
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        betaEnabled(): boolean {
            return this.$store.direct.getters.user.betaEnabled
        },
        canChangeHome(): boolean {
            return this.$store.direct.getters.user.canChangeHome
        },
        userDisplayName(): string {
            return this.$store.direct.state.user.display_name || 'User'
        },
        anonymise: {
            get(): boolean {
                return this.$store.direct.state.user.anonymise
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.setAnonymise(val)
            }
        },
        textAreaContSave: {
            get(): boolean {
                return this.$store.direct.state.user.textAreaContSave
            },
            set() {
                this.$store.direct.commit.user.toggleTextAreaContSave()
            }
        },
    },
    methods: {
        logout() {
            this.$store.direct.dispatch.session.logout()
        },
        triggerBrowserException() {
            throw 'manually triggered exception'
        },
        triggerServerException(doCatch: boolean=true) {
            utils.request.get('/raise_exception/')
            .catch(err => {
                if (doCatch)
                    utils.handleRequestError(err)
                else
                    throw 'deliberately re-thrown server error'
            })
        },
    }
})
