var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("div", { staticClass: "col-12 col-sm-8" }, [
        _c(
          "div",
          { staticClass: "d-flex align-items-center mb-2" },
          [
            _c("h3", { staticClass: "mb-0" }, [
              _c("span", { staticClass: "text-uppercase" }, [
                _vm._v(_vm._s(_vm.patient.last_name))
              ]),
              _vm._v(
                ", " +
                  _vm._s(_vm.patient.first_name) +
                  " (" +
                  _vm._s(_vm.patient.age) +
                  ")\n            "
              )
            ]),
            _vm._v(" "),
            !_vm.hasPasFeed && !_vm.isTempUser
              ? _c(
                  "b-btn",
                  {
                    directives: [
                      {
                        name: "b-modal",
                        rawName: "v-b-modal",
                        value: _vm.patientModalId,
                        expression: "patientModalId"
                      }
                    ],
                    staticClass: "ml-1",
                    attrs: { size: "sm", variant: "info" }
                  },
                  [_vm._v("Edit")]
                )
              : _vm._e(),
            _vm._v(" "),
            _c("neuro-acute-care-summary", {
              staticClass: "ml-2",
              attrs: { stay_id: _vm.stay_id }
            }),
            _vm._v(" "),
            _vm.isConsult
              ? _c("h6", { staticClass: "ml-2 mb-0" }, [
                  _vm._v("[Consultation]")
                ])
              : _vm._e()
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "d-flex align-items-center mb-2" },
          [
            _c(
              "h5",
              { staticClass: "d-inline mb-0" },
              [
                _vm._v(
                  "\n                MRN: " +
                    _vm._s(_vm.patient.mrn) +
                    "\n                "
                ),
                _vm.isPowerUser
                  ? _c("span", { staticClass: "progress-bar-striped" }, [
                      _vm._v("| Stage: " + _vm._s(_vm.stage))
                    ])
                  : _vm._e(),
                _vm._v("\n                | Syndrome: "),
                _c(
                  "router-link",
                  {
                    attrs: {
                      to: _vm.changeSyndromeLink,
                      title: "Change syndrome"
                    }
                  },
                  [_c("u", [_vm._v(_vm._s(_vm.syndromeText))])]
                ),
                _vm._v("\n                | "),
                _c("span", { attrs: { title: "Length of Stay" } }, [
                  _vm._v("LoS:")
                ]),
                _vm._v(
                  " " +
                    _vm._s(_vm.stay ? _vm.stay.length_of_stay : "?") +
                    " days\n                "
                ),
                _vm.showSurgery
                  ? [
                      _vm._v("\n                    | "),
                      _c("span", { attrs: { title: "Post-Op Day" } }, [
                        _vm._v("POD:")
                      ]),
                      _vm._v(" "),
                      _c(
                        "b-link",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle",
                              value: _vm.surgeryModalId,
                              expression: "surgeryModalId"
                            }
                          ],
                          attrs: { variant: "link" }
                        },
                        [_c("u", [_vm._v(_vm._s(_vm.postOpDay))])]
                      )
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.abfScore
                  ? _c("span", [_vm._v(" | ABF: " + _vm._s(_vm.abfScore))])
                  : _vm._e(),
                _vm._v(" "),
                _vm.syndromeLink
                  ? _c("span", [
                      _vm._v("\n                    ["),
                      _c(
                        "a",
                        {
                          staticClass: "text-info",
                          attrs: {
                            target: "_blank",
                            href: _vm.syndromeLink,
                            title: _vm.syndromeLink
                          }
                        },
                        [_vm._v(_vm._s(_vm.syndromeLinkText))]
                      ),
                      _vm._v("]\n                ")
                    ])
                  : _vm._e()
              ],
              2
            ),
            _vm._v(" "),
            !_vm.telestrokeMode || (_vm.telestrokeMode && _vm.isTSSiteUser)
              ? _c(
                  "b-btn-group",
                  { staticClass: "ml-2" },
                  _vm._l(_vm.altViews, function(view) {
                    return view.visible
                      ? _c(
                          "b-btn",
                          {
                            key: view.route,
                            attrs: {
                              disabled: view.disabled,
                              size: "sm",
                              variant: "outline-info"
                            },
                            on: {
                              click: function($event) {
                                $event.stopPropagation()
                                return _vm.goToRoute(view.route)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(view.label) +
                                "\n                "
                            )
                          ]
                        )
                      : _vm._e()
                  }),
                  1
                )
              : _vm._e()
          ],
          1
        )
      ]),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass:
            "col-12 col-sm-4 mb-2 d-flex align-items-end justify-content-end"
        },
        [
          _vm.isDeveloper
            ? _c(
                "button",
                {
                  staticClass: "btn btn-outline-info btn-sm mr-2",
                  attrs: { type: "button" },
                  on: {
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.cycleChoicesDisplay.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm._v("\n            Choices\n            "),
                  _vm.choicesDisplayWrap
                    ? _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["far", "arrow-to-right"] }
                      })
                    : _c("fnt-a-icon", {
                        staticClass: "svg",
                        attrs: { icon: ["far", "arrow-to-bottom"] }
                      })
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "btn btn-outline-success btn-sm mr-2",
              attrs: { type: "button" },
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.refreshData.apply(null, arguments)
                }
              }
            },
            [_vm._v("Refresh")]
          ),
          _vm._v(" "),
          !_vm.isTempUser
            ? [
                _c(
                  "b-btn",
                  {
                    attrs: {
                      variant: "outline-info",
                      size: "sm",
                      id: _vm.cycleLayoutId
                    },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.cycleDockedStates.apply(null, arguments)
                      }
                    }
                  },
                  [_vm._v("\n                Cycle layout\n            ")]
                ),
                _vm._v(" "),
                _c(
                  "b-tooltip",
                  { attrs: { target: _vm.cycleLayoutId, triggers: "hover" } },
                  [
                    _c("p", { staticClass: "mb-0" }, [
                      _vm._v(
                        "\n                    Sidebar " +
                          _vm._s(_vm.sidebarDocked ? "docked" : "hidden")
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                    Assessment " +
                          _vm._s(_vm.asmtVisible ? "visible" : "hidden")
                      ),
                      _c("br"),
                      _vm._v(
                        "\n                    Text output " +
                          _vm._s(_vm.textDocked ? "docked" : "hidden") +
                          "\n                "
                      )
                    ])
                  ]
                )
              ]
            : _vm._e(),
          _vm._v(" "),
          !_vm.isTempUser && _vm.showAttending
            ? _c("attending", {
                staticClass: "ml-2",
                attrs: {
                  btnSize: "sm",
                  stay_id: _vm.stay_id,
                  section: _vm.attendingSection
                }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.showNihssSelect
            ? _c("text-nihss-select", {
                staticClass: "ml-2",
                attrs: { size: "sm", stay_id: _vm.stay_id }
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.RN_recentNotes.length
            ? _c("dropdown", {
                staticClass: "mb-0 ml-2",
                attrs: {
                  size: "sm",
                  label: "Recent notes",
                  useHelpIcon: "",
                  helpText:
                    "These are only available during this session. They will be cleared once you close the tab and/or log out.",
                  items: _vm.RN_recentNotes,
                  textField: "created"
                },
                model: {
                  value: _vm.RN_selected,
                  callback: function($$v) {
                    _vm.RN_selected = $$v
                  },
                  expression: "RN_selected"
                }
              })
            : _vm._e()
        ],
        2
      ),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      _c("patient-modal", {
        attrs: { stay_id: _vm.stay_id },
        on: {
          "patient-modal-id": function($event) {
            _vm.patientModalId = $event
          }
        }
      }),
      _vm._v(" "),
      _c("b-collapse", { attrs: { id: _vm.surgeryModalId } }, [
        _c(
          "div",
          { staticClass: "col-12" },
          [_c("surgery-time", { attrs: { stay_id: _vm.stay_id } })],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-12" }, [
      _c("hr", { staticClass: "mt-1 mb-2" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }