var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c("div", { staticClass: "row" }, [
        _c("p", { staticClass: "mb-0 col-12" }, [
          _vm.telestrokeMode && _vm.stay.hospital
            ? _c("span", { staticClass: "text-nowrap" }, [
                _vm._v(_vm._s(_vm.stay.hospital.title))
              ])
            : _vm._e(),
          _c("br"),
          _vm._v(" "),
          _c("span", [_vm._v(_vm._s(_vm.locationDesc(_vm.stay)))])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }