




















import mixins from 'vue-typed-mixins'
import StayMixin from '@mixins/Stay.vue'
import { clone, each, filter, find, findIndex, includes, map, maxBy, reject, sortBy, startsWith } from 'lodash-es'
import { Item } from 'models/base'
import { NIHSS as NIHSS_i, NIHSS_ADM_DISPLAY } from 'models/data/nihss'
import { nihssDescAndScore, nihssTitleToDisplay } from 'utils/text/nihss'
import Dropdown from '@shared/inputs/Dropdown.vue'

interface NIHSS_i_Ext extends NIHSS_i {
    desc: string
}

export default mixins(StayMixin).extend({
    components: {
        Dropdown,
    },
    props: {
        size: {
            type: String
        },
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        nihssLabels(): Item[] {
            return this.$store.direct.getters.templates.speciality(this.stay?.speciality)?.nihss_labels || []
        },
        labelIds(): number[] {
            return map(this.nihssLabels || [], item => item.id)
        },
        sortedNihss(): NIHSS_i_Ext[] {
            const nihss_set = this.stay?.nihss_set || []
            const ext_set = map(nihss_set, nihss => ({ desc: nihssDescAndScore(nihss), ...nihss }))
            return sortBy(ext_set, nihss => (nihss.assessed_at || nihss.updated_at))
        },
        labeledNihss(): NIHSS_i_Ext[] {
            const nihss_set = this.sortedNihss
            let labeled: NIHSS_i_Ext[] = []
            each([NIHSS_ADM_DISPLAY].concat(map(this.nihssLabels, info => info.title)), label => {
                const labelDesc = nihssTitleToDisplay(label)
                const nihss = find(nihss_set, nihss => startsWith(nihss.desc, labelDesc))
                if (nihss)
                    labeled.push(nihss)
            })
            return labeled
        },
        unlabeledNihss(): NIHSS_i_Ext[] {
            return reject(this.sortedNihss, nihss => (includes(this.labelIds, nihss.label) || nihss.stage === 'ADMISSION'))
        },
        /** the last/most recent unlabeled NIHSS, and it also needs to be editable. */
        lastEditableNihss(): NIHSS_i_Ext | undefined {
            let lastNihss: NIHSS_i_Ext | undefined
            if (this.unlabeledNihss.length > 0)
                lastNihss = this.unlabeledNihss[this.unlabeledNihss.length - 1]
            return lastNihss?.editable ? lastNihss : undefined
        },
        simpleNihssList(): NIHSS_i_Ext[] {
            return this.lastEditableNihss ? this.labeledNihss.concat(this.lastEditableNihss) : this.labeledNihss
        },
        /** this is derived from the simple NIHSS list */
        lastEditedNihss(): NIHSS_i_Ext | undefined {
            return maxBy(filter(this.simpleNihssList, nihss => nihss.editable && nihss.stage !== 'ADMISSION'), nihss => (nihss.assessed_at || nihss.updated_at))
        },
        /** all NIHSS, sorted by labels, and then assessed_at/updated_at */
        allNihss(): NIHSS_i_Ext[] {
            const sorted = clone(this.sortedNihss)

            let final: NIHSS_i_Ext[] = []
            each([NIHSS_ADM_DISPLAY].concat(map(this.nihssLabels, info => info.title)), label => {
                const labelDesc = nihssTitleToDisplay(label)
                const i = findIndex(sorted, nihss => startsWith(nihss.desc, labelDesc))
                if (i !== -1)
                    final.push(sorted.splice(i, 1)[0])
            })
            final = final.concat(sorted)

            return final
        },
        textNIHSS: {
            get(): number {
                return this.$store.direct.state.session.textNIHSS
            },
            set(id: number) {
                this.$store.direct.commit.session.setTextNIHSS(id)
            }
        },
    },
    watch: {
        lastEditedNihss: {
            handler: function(nihss: NIHSS_i_Ext | undefined) {
                if (nihss)
                    this.textNIHSS = nihss.id
            },
            immediate: true
        },
    },
    beforeDestroy() {
        this.$store.direct.commit.session.clearTextNIHSS()
    },
})
