var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-dd-item",
        {
          directives: [
            {
              name: "b-modal",
              rawName: "v-b-modal",
              value: _vm.htmlId,
              expression: "htmlId"
            }
          ]
        },
        [_vm._v("Beta Features")]
      ),
      _vm._v(" "),
      _c(
        "b-modal",
        {
          attrs: {
            size: "lg",
            title: "Beta Features",
            id: _vm.htmlId,
            "ok-only": ""
          }
        },
        [
          _c("p", [_vm._v("List of features currently in beta:")]),
          _vm._v(" "),
          _c("ul", [
            _c("li", [
              _vm._v("\n                Added "),
              _c("em", [_vm._v("Free Text v2")]),
              _vm._v(
                " to toggle continuous save on/off for free text boxes.\n            "
              )
            ])
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }