import * as shortID from "shortid"
import { cloneDeep } from 'lodash-es'
import { DisplayInfo, LayoutGroupBase } from './layout'
import { Prop, PropType } from './schema'


/** Augments a Prop with additional info, including path and display hints */
export interface PropExtInfo extends Prop, DisplayInfo {
    uid: string
    /** the field name, or the object id if `is_arrayed` is true */
    name: string
    /** the full field path */
    path: string
    /** Not used anywhere?? hints at whether this is points to an object within a list */
    is_arrayed?: boolean
    /** declares whether this prop has a linked notes path */
    has_notes?: boolean
    /** the linked notes path, if has_notes is true */
    notes_path?: string

    obj_id?: number
    nested_path?: string
}

export interface LayoutGroupExt extends Partial<LayoutGroupBase> {
    /** a unique id to cater for v-for */
    uid: string | number
    fields: PropExtInfo[]
}

const PropInfoDummy: PropExtInfo = {
    uid: "",
    field: "?",
    name: "?",
    path: "?",
    type: PropType.UNKNOWN,
    required: false,
    read_only: true,
    label: "?"
}

export function getPropInfoDummy(name: string, path: string, label: string) {
    const dummy = cloneDeep(PropInfoDummy)
    dummy.uid = `R4_${shortID.generate()}`
    dummy.name = name
    dummy.path = path
    dummy.label = label
    return dummy
}
