

























































import Vue from "vue"
import stays from "@store/stays"
import { isNil } from "lodash-es"
import { LayoutGroupExt, PropExtInfo } from "models"
import RInput from "../inputs/Input.vue"

export default Vue.extend({
    components: {
        RInput
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        fields: {
            type: Array as () => Array<PropExtInfo>,
            default: () => []
        },
        groups: {
            type: Array as () => Array<LayoutGroupExt>
        },
        addGroupDivider: {
            type: Boolean,
            default: false
        },
        overrideMsg: {
            type: String,
            required: false
        },
        labelClass: {
            type: String,
            required: false,
        },
    },
    methods: {
        inlined(alignment?: string): boolean {
            return alignment === "inline"
        },
        stacked(alignment?: string): boolean {
            return alignment === "stacked"
        },
        groupClasses(group: LayoutGroupExt) {
            const addSpacing = group.group_top_spacing === undefined ? true : group.group_top_spacing
            let classes = addSpacing ? 'mt-2' : ''
            if (group.group_class) classes += ` ${group.group_class}`
            return classes
        },
        fieldClasses(group: LayoutGroupExt) {
            const addSpacing = group.field_top_spacing === undefined ? false : group.field_top_spacing
            return this.stacked(group.alignment) && addSpacing ? 'mt-2' : ''
        },
        showField(field: PropExtInfo): boolean {
            return !field.hidden
        }
    },
    computed: {
        isGrouped(): boolean {
            return this.groups !== undefined && this.groups.length !== 0
        }
    },
})
