var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-2" },
    [
      _c("follow-up-details", {
        staticClass: "mt-4",
        attrs: { stay_id: _vm.stay_id }
      }),
      _vm._v(" "),
      _c("admission-summary", { attrs: { stay_id: _vm.stay_id } }),
      _vm._v(" "),
      _c("text-area-enhanced", {
        attrs: {
          stay_id: _vm.stay_id,
          path: "discharge.meds_changed",
          label: "Medications changed"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }