import { string as dstring, number as dnumber, positiveInteger, boolean as dboolean, oneOf, nullable, array, exact, guard } from 'decoders'
import { QuestionCategory, QuestionCategory_values, QuestionType, QuestionType_values } from './enums'
import { AsapPathway } from './asap'
import { Diagnosis } from './diagnosis'
import { Issue } from './issue'
import { Speciality } from './speciality'
import { Syndrome } from './syndrome'

export type QuestionGroupContainer = Speciality | Syndrome | Diagnosis | Issue | AsapPathway

export { QuestionCategory, QuestionType }

export interface QuestionChoice {
    id: number
    title: string
    label: string
    order: number
    abf: boolean
    hide: boolean
}

export interface Question {
    id: number
    category: QuestionCategory
    title: string
    label: string | null
    type: QuestionType
    choices: QuestionChoice[]
    /** choices can be explicitly set to stacked / inline. Defaults to user choice on UX. */
    stacked: boolean | null

    /** QuestionCategory.CUSTOM questions use help_text as a unique identifier */
    help_text: string | null
    link: string | null
    allow_notes: boolean

    /** only show yes in the UX */
    show_yes_only: boolean
    no_text_output: boolean
    show_title_in_text_output: boolean
    /** if this is `true`, boolean-like answers will be presented in the format *<title>* or *No <title>* */
    smart_format: boolean
    /** replaces 'No' for boolean-like answers if *smart_format* is `true` */
    false_prefix: string

    yes_text: string | null
    no_text: string | null

    hide: boolean
    abf: boolean
}

export interface OrderedQuestion {
    question: Question
    show_if: number | null
    show_if_value: boolean
}

export interface QuestionGroup {
    id: number
    title: string | null
    questions: OrderedQuestion[]
    category: QuestionCategory
    link: string | null
}

const qChoice = exact({
    id: dnumber,
    title: dstring,
    label: dstring,
    order: positiveInteger,
    abf: dboolean,
    hide: dboolean,
})

export const question = exact({
    id: dnumber,
    category: oneOf(QuestionCategory_values),
    title: dstring,
    label: nullable(dstring),
    type: oneOf(QuestionType_values),
    stacked: nullable(dboolean),
    help_text: nullable(dstring),
    choices: array(qChoice),
    link: nullable(dstring),
    no_text_output: dboolean,
    show_title_in_text_output: dboolean,
    hide: dboolean,
    allow_notes: dboolean,
    abf: dboolean,
    show_yes_only: dboolean,
    smart_format: dboolean,
    false_prefix: dstring,
    yes_text: nullable(dstring),
    no_text: nullable(dstring),
})

const orderedQuestion = exact({
    question,
    show_if: nullable(dnumber),
    show_if_value: dboolean,
})

export const questionGroup = exact({
    id: dnumber,
    title: nullable(dstring),
    questions: array(orderedQuestion),
    category: oneOf(QuestionCategory_values),
    link: nullable(dstring),
})

export const questionDecoder = guard(question, { style: 'simple' })
export const questionsDecoder = guard(array(question), { style: 'simple' })
export const qChoiceDecoder = guard(qChoice, { style: 'simple' })
export const qChoicesDecoder = guard(array(qChoice), { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _orderedQuestion: OrderedQuestion = guard(orderedQuestion)({}) } catch(e) {}
try { const _questionGroup: QuestionGroup = guard(questionGroup)({}) } catch(e) {}
try { const _question: Question = questionDecoder({}) } catch(e) {}
try { const _qChoice: QuestionChoice = qChoiceDecoder({}) } catch(e) {}
try { const _qChoices: QuestionChoice[] = qChoicesDecoder({}) } catch(e) {}
