var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.patient
    ? _c("div", [
        _c("p", { staticClass: "mb-0" }, [
          _vm._v(
            _vm._s(_vm.patient.last_name) +
              ", " +
              _vm._s(_vm.patient.first_name)
          )
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-1 text-secondary" }, [
          _vm._v(_vm._s(_vm.patient.age))
        ]),
        _vm._v(" "),
        _c("p", { staticClass: "mb-0 text-secondary text-nowrap" }, [
          _vm._v("\n        MRN: " + _vm._s(_vm.patient.mrn)),
          _c("br"),
          _vm._v(" "),
          _vm.stay.site_only
            ? _c("em", [_vm._v("Non-TSS")])
            : _vm.stay.doctor
            ? _c("span", [
                _vm._v(
                  _vm._s(_vm.stay.doctor.last_name) +
                    ", " +
                    _vm._s(_vm.stay.doctor.first_name)
                )
              ])
            : _vm._e()
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }