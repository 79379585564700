var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c("dropdown", {
        staticClass: "col-sm-6",
        attrs: {
          fullWidth: "",
          noFlip: "",
          wrapText: "",
          items: _vm.followUpWithOptions,
          label: "Follow up with",
          "text-field": "title",
          "value-field": "id"
        },
        model: {
          value: _vm.followUpWith,
          callback: function($$v) {
            _vm.followUpWith = $$v
          },
          expression: "followUpWith"
        }
      }),
      _vm._v(" "),
      _c("dropdown", {
        staticClass: "col-lg-3 col-sm-4",
        attrs: {
          fullWidth: "",
          items: _vm.followUpInChoices,
          label: "In",
          "text-field": "title",
          "value-field": "id"
        },
        model: {
          value: _vm.followUpIn,
          callback: function($$v) {
            _vm.followUpIn = $$v
          },
          expression: "followUpIn"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }