








import Vue from 'vue'

import AdmissionSummary from './AdmissionSummary.vue'
import DischargeText from '@text/Discharge.vue'
import FollowUpDetails from './discharge/FollowUpDetails.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'


export default Vue.extend({
    components: {
        AdmissionSummary,
        DischargeText,
        FollowUpDetails,
        TextAreaEnhanced,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
})
