import { OrderedItem } from "../base"
import { QuestionGroup, questionGroup } from './question'
import {
    string as dstring,
    number as dnumber, 
    boolean as dboolean,
    nullable, array, exact, guard
} from 'decoders'

export interface PlanStep extends OrderedItem {
    link: string | null
    help_text: string | null
    hide: boolean
}

export interface PlanSection {
    /** the section title */
    title: string
    order: number
    link: string | null
    help_text: string | null
    steps: PlanStep[]
}

export type MgmtPlan = PlanSection[]

export interface Diagnosis extends OrderedItem {
    alt_title: string
    speciality: number
    syndrome: number
    management_plan: MgmtPlan
    is_thrombolysis: boolean
    link: string | null
    help_text: string | null
    hide: boolean
    question_groups: QuestionGroup[]
    issues: number[]
}

const planStep = exact({
    id: dnumber,
    title: dstring,
    order: dnumber,
    link: nullable(dstring),
    help_text: nullable(dstring),
    hide: dboolean,
})

const planSection = exact({
    title: dstring,
    order: dnumber,
    link: nullable(dstring),
    help_text: nullable(dstring),
    steps: array(planStep)
})

const mgmtPlan = array(planSection)

const diagnosis = exact({
    id: dnumber,
    title: dstring,
    alt_title: dstring,
    order: dnumber,
    speciality: dnumber,
    syndrome: dnumber,
    management_plan: mgmtPlan,
    is_thrombolysis: dboolean,
    link: nullable(dstring),
    help_text: nullable(dstring),
    hide: dboolean,
    question_groups: array(questionGroup),
    issues: array(dnumber),
})

export const diagnoses = array(diagnosis)

// sanity checks that decoders/guards are not missing properties
try { const _planStep: PlanStep = guard(planStep)({}) } catch(e) {}
try { const _planSection: PlanSection = guard(planSection)({}) } catch(e) {}
try { const _mgmtPlan: MgmtPlan = guard(mgmtPlan)({}) } catch(e) {}
try { const _diagnosis: Diagnosis = guard(diagnosis)({}) } catch(e) {}
