import { concat, filter, find, forEach, map } from 'lodash-es'
import * as moment from 'moment'
import utils from 'utils'
import { LBR } from './constants'
import { doPlainIndent, freeTextSubHtmlBreaks, outputWithTitle } from './helpers'
import { formatQuestionAnswerAndNotes, genericQuestionsText } from './question'
import { TextDetails, TextDetailsExt, TextModifiers, TextResult } from './interfaces'
import { Stay } from 'models/data/stay'
import { Question, QuestionCategory } from "models/med_templates/question"
import store from 'store'
import stays from '@store/stays'


export function _presentingComplaint(stay: Stay, plain: boolean = false): string {
    let lines: string[] = []

    if (stay.presentation && stay.presentation.last_seen_well) {
        const lsw = stay.presentation.last_seen_well
        let lswText = null

        let lswTime = ""
        if (stay.presentation.lsw_time) {
            let dateStr = stay.presentation.lsw_time
            const momentised = moment(dateStr)
            if (momentised.isValid())
                dateStr = momentised.format(" [at] h:mma [on] Do MMM YYYY")
            else
                dateStr = ` at ${dateStr}`
            lswTime = dateStr
        }

        if (lsw === "LT_24_HOURS") {
            lswText = `Last seen well${lswTime || " < 24 hours ago"}.`
        } else if (lsw === "WAKE_UP") {
            lswText = `Woke up with symptoms. Last seen well${lswTime || " [unknown]"}.`
        } else if (lsw === "GT_24_HOURS") {
            lswText = `Greater than 24 hours since last seen well${lswTime}.`
        }

        if (lswText) lines.push(lswText)
    }

    // Add Presenting Complaint free text summary
    if (stay.presentation && stay.presentation.summary) {
        lines.push(freeTextSubHtmlBreaks(stay.presentation.summary))
    }

    return lines.length ? lines.join(LBR) : ''
}


export function _positivesAndNegatives(stay: Stay): string {
    const text = genericQuestionsText({
        stay,
        category: QuestionCategory.POSITIVES_NEGATIVES,
        getQuestionGroupsFrom: stays.getSyndrome(stay.id),
    })
    return text || ''
}


/**
 * this combines presenting complaint with the positives/negatives output, since those are
 * within the same section to the end user.
 */
export function presentingComplaintFull({ stay, title }: TextDetails, plain: boolean = false): string {
    if (!stay) return ''

    const presentingText = _presentingComplaint(stay, plain)
    const posNegsText = _positivesAndNegatives(stay)
    let text = filter([presentingText, posNegsText]).join(LBR)

    if (!text) return ''

    title = title || "Presenting complaint"
    title = plain ? `${title}:` : `**${title}:**`
    text = `${title}${LBR}${text}`

    return plain ? doPlainIndent(text) : text
}

export function background_(
    { stay }: TextDetails,
    { updatedAfter = '' }: Partial<TextModifiers> = {},
): TextResult {
    if (!stay) return { text: '' }

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])

    let questions: Question[] = []

    if (syndrome)
        forEach(syndrome.question_groups, group => {
            if (group.category === QuestionCategory.BACKGROUND)
                questions = concat(questions, map(group.questions, 'question'))
        })

    const updatedAfter_m = moment(updatedAfter)
    const checkDate = updatedAfter_m.isValid()

    let recent = false
    let negatives: string[] = []
    let negativesWithNotes: string[] = []
    let positives: string[] = []

    forEach(questions, question => {
        const res = formatQuestionAnswerAndNotes(
            stay.id, question,
            undefined, undefined,
            true
        )

        if (!res)
            return

        if (checkDate) {
            const qUpdated_m = moment(res.updated_at)
            if (qUpdated_m.isValid()) {
                if (qUpdated_m.isSameOrAfter(updatedAfter_m))
                    recent = true
            }
            else {
                utils.messageSentry(`Stay ${stay.id} bg question.updated_at is invalid. (q_id: ${question.id}, value: ${qUpdated_m.creationData().input})`)
            }
        }

        if (res.booleanLike && !res.positive) {
            if (res.hasNotes)
                negativesWithNotes.push(res.text)
            else
                negatives.push(res.text)
        }
        else
            positives.push(res.text)
    })

    if (checkDate) {
        const bgUpdated_m = moment(stay.background?.updated_at)
        if (bgUpdated_m.isValid()) {
            if (bgUpdated_m.isSameOrAfter(updatedAfter_m))
                recent = true
        }
        else {
            utils.messageSentry(`Stay ${stay.id} background.updated_at is invalid. (value: ${bgUpdated_m.creationData().input})`)
        }
    }

    if (checkDate && !recent)
        return { text: '', noRecent: true }

    // compile lines
    let lines: string[] = []

    if (positives.length) {
        const positiveText = positives.join("  \n")
        lines.push(positiveText)
    }

    if (stay.background && stay.background.notes) {
        lines.push(freeTextSubHtmlBreaks(stay.background.notes))
    }

    if (lines.length && (negativesWithNotes.length || negatives.length)) {
        // Push empty string to add a line break before negatives
        lines.push("")
    }

    if (negativesWithNotes.length) {
        const noHistoryText = map(negativesWithNotes, line => `No history of ${line}`)
        lines.push(noHistoryText.join("  \n"))
    }

    if (negatives.length) {
        const negativesText = `No history of:  \n${negatives.join(" / ")}`
        lines.push(negativesText)
    }

    const text = lines.length ? lines.join("  \n") : ''
    return { text }
}

export function background(
    { stay, hideTitle, plain = false, noInfo = false }: TextDetailsExt,
    { updatedAfter }: { updatedAfter?: string } = {},
): string {
    const { text, noRecent } = background_({ stay, hideTitle }, { updatedAfter })
    if (noRecent) return ''
    const title = 'Past Medical/Surgical History'
    return outputWithTitle(text, title, plain, noInfo, hideTitle)
}

export function updatedBackground(textDetails: TextDetailsExt): string {
    const stay = textDetails.stay
    if (!(stay && stay.ward_round)) return ""
    const wardRoundCreated = stay.ward_round.created_at
    return background(textDetails, { updatedAfter: wardRoundCreated })
}

export function socialHistory_(
    { stay }: TextDetails,
    { topLineOnly = false, updatedAfter = '' }: Partial<TextModifiers> = {},
): TextResult {
    if (!stay || !stay.social_history) return { text: '' }

    const socialHistory = stay.social_history

    const updatedAfter_m = moment(updatedAfter)
    const checkDate = updatedAfter_m.isValid()
    let recent = false

    if (checkDate) {
        const lastUpdated_m = moment(socialHistory.updated_at)
        if (lastUpdated_m.isValid()) {
            if (lastUpdated_m.isSameOrAfter(updatedAfter_m))
                recent = true
        }
        else {
            utils.messageSentry(`Stay ${stay.id} social_history.updated_at is invalid. (value: ${lastUpdated_m.creationData().input})`)
        }
    }

    let lines = []

    const syndrome = find(store.direct.getters.templates.allSyndromes, ['id', stay.syndrome])

    let questions: Question[] = []

    if (syndrome)
        forEach(syndrome.question_groups, group => {
            if (group.category === QuestionCategory.SOCIAL_HISTORY)
                questions = concat(questions, map(group.questions, 'question'))
        })

    forEach(questions, question => {
        const res = formatQuestionAnswerAndNotes(
            stay.id, question,
            undefined, undefined,
            true
        )

        if (!res)
            return

        if (checkDate) {
            const qUpdated_m = moment(res.updated_at)
            if (qUpdated_m.isValid()) {
                if (qUpdated_m.isSameOrAfter(updatedAfter_m))
                    recent = true
            }
            else {
                utils.messageSentry(`Stay ${stay.id} social history question.updated_at is invalid. (q_id: ${question.id}, value: ${qUpdated_m.creationData().input})`)
            }
        }

        lines.push(res.text)
    })

    if (checkDate && !recent)
        return { text: '', noRecent: true }

    // Notes
    if (socialHistory.notes) {
        lines.push(freeTextSubHtmlBreaks(socialHistory.notes))
    }

    if (!lines.length)
        return { text: '' }

    /*
    if (topLineOnly) {
        let topLine = lines[0] */
        //const res = topLine.match(/^(?:(?!<br>).)*/)
        /*
        if (res && res.length > 0)
            return { text: res[0] }
        return { text: topLine }
    }
    */

    if (topLineOnly) { // hack, just to get top 2 lines
        if (lines.length > 1) return { text: lines[0] + LBR + lines[1] }
        else return { text: lines[0] }
    }

    const text = lines.length ? lines.join("  \n") : ''
    return { text }
}

export function socialHistory(
    { stay, hideTitle, plain = false, noInfo = false }: TextDetailsExt,
    modifiers?: Partial<TextModifiers>,
): string {
    const { text, noRecent } = socialHistory_({ stay, hideTitle }, modifiers)
    if (noRecent) return ''
    if (modifiers?.topLineOnly) return text
    const title = 'Social history'
    return outputWithTitle(text, title, plain, noInfo, hideTitle)
}
