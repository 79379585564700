var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "row mb-3" }, [
    _c(
      "div",
      { staticClass: "col scrwl-h100 scrwl-overflow-y" },
      [
        _c("editor", {
          attrs: {
            height: _vm.finalHeight,
            markup: _vm.markup,
            customHtml: _vm.RN_recentNote,
            stay_id: _vm.stay_id,
            readOnly: _vm.readOnly,
            useSignature: _vm.useSignature
          },
          on: { "text-copied": _vm.confirmComplete }
        }),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.confirmHtmlId,
              centered: "",
              "hide-header": "",
              "cancel-title": "No",
              "ok-title": _vm.modalOkTitle,
              "ok-variant": "success"
            },
            on: { ok: _vm.markComplete }
          },
          [_vm._t("modalContent")],
          2
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }