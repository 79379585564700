










































import mixins from "vue-typed-mixins"
import InputMixin from "@mixins/Input.vue"
import { find, isFinite } from "lodash-es"

import stays from "@store/stays"
import { NIHSS } from "models/data/nihss"
import { Stay } from "models/data/stay"

import text, { PBR, SECTION_START } from "utils/text"
import {
    diagnosisWithSummaries,
    diagnosisWithSummariesPlain
} from "utils/text/diagnosis"
import { updatedBackground, socialHistory } from "utils/text/clinic_history"
import {
    newInvestigationResults,
    outstandingInvestigations,
} from 'utils/text/investigation'
import {
    activeIssuesWithSummaryText,
    resolvedIssuesTitles,
} from "utils/text/issue"
import { updatedHistoricalMedications } from "utils/text/meds"
import { nihss, wardRoundNihss } from "utils/text/nihss"
import { doctorWardPlan, nurseWardPlan } from "utils/text/plan"
import * as ward from "utils/text/ward"
import { attending } from "utils/text/team"

import Attending from "@shared/stay-inputs/Attending.vue"
import TextBase from "../TextBase.vue"
import TextNihssSelect from "components/text/NIHSSSelect.vue"

export default mixins(InputMixin).extend({
    components: {
        Attending,
        TextBase,
        TextNihssSelect
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showExtras: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        isNurseUser(): boolean {
            return this.$store.direct.getters.user.isNurseUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        showSurgery(): boolean {
            if (!this.stay) return false
            const syndrome = find(
                this.$store.direct.getters.templates.allSyndromes,
                ["id", this.stay.syndrome]
            )
            if (!syndrome) return false
            const specialityId = syndrome.speciality
            if (!specialityId) return false
            const speciality = find(
                this.$store.direct.state.templates.specialities,
                { id: specialityId }
            )
            if (!speciality) return false
            return speciality.has_surgery || false
        },
        nihssV1(): boolean {
            return this.$store.direct.state.session.nihss_version === "v1"
        },
        nihssV2(): boolean {
            return this.$store.direct.state.session.nihss_version === "v2"
        },
        textNIHSS(): number {
            return this.$store.direct.state.session.textNIHSS
        },
        textNIHSSObj(): NIHSS | undefined {
            return find(this.stay?.nihss_set, { id: this.textNIHSS })
        },
        /** v1 == grouped issues */
        issuesGrouped: {
            get(): boolean {
                return this.$store.direct.getters.user.textWardV1
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.setTextOutputWardV1(val)
            }
        },
        usePlain: {
            get(): boolean {
                return this.$store.direct.getters.user.textOutputPlain
            },
            set(val: boolean) {
                this.$store.direct.dispatch.user.setTextOutputPlain(val)
            }
        },
        markup(): string {
            if (!(this.stay && this.stay.ward_round)) return ""

            const plain = this.usePlain
            const isNurse = this.$store.direct.getters.user.isNurseUser
            const stay = this.stay
            const textDetails = {
                stay,
                anonymise: this.$store.direct.state.user.anonymise,
                plain,
                noInfo: true,
            }

            let markup = SECTION_START
            markup += "Ward Round  \n"
            markup += attending(textDetails, "ward", plain)
            markup += PBR

            markup += `${text.patientDetails(textDetails)}  \n`

            let day = isFinite(stay.day) ? stay.day : stay.day_from_create
            markup += `Day ${day}`
            if (this.showSurgery)
                markup +=
                    "  \n" + text.podText({ stay, hideTitle: false }) + "  \n"

            markup += PBR

            markup += SECTION_START
            markup += plain
                ? diagnosisWithSummariesPlain(textDetails)
                : diagnosisWithSummaries(textDetails, undefined, true)
            markup += PBR

            if (!this.isNurseUser && this.issuesGrouped) {
                markup += activeIssuesWithSummaryText(textDetails, plain)
                markup += PBR

                markup += resolvedIssuesTitles(textDetails, true, plain)
                markup += PBR
            }

            markup += SECTION_START
            markup += ward.wardProgress(textDetails, plain)
            markup += PBR

            markup += ward.wardExam(textDetails, plain)
            markup += PBR

            if (this.nihssV1) markup += wardRoundNihss(textDetails)
            else
                markup += nihss(textDetails, {
                    nihssID: this.textNIHSS,
                    advancedDesc: true,
                    heading: "",
                    plain
                })
            markup += PBR

            markup += ward.discussed(textDetails, plain)
            markup += PBR

            markup += newInvestigationResults(textDetails, plain)
            markup += PBR

            markup += outstandingInvestigations(textDetails)
            markup += PBR

            markup += isNurse
                ? nurseWardPlan(stay, plain)
                : doctorWardPlan(stay, plain)
            markup += PBR

            const userState = this.$store.direct.state.user
            if (userState.appendSig) {
                markup += userState.signature
                markup += PBR
            }

            markup += SECTION_START
            markup += "Additional information"
            markup += PBR

            if (!this.isNurseUser && !this.issuesGrouped) {
                markup += "Issues:  \n"
                markup += activeIssuesWithSummaryText(textDetails, plain)
                markup += PBR

                markup += resolvedIssuesTitles(textDetails, true, plain)
                markup += PBR
            }

            markup += updatedBackground(textDetails)
            markup += PBR

            markup += updatedHistoricalMedications(textDetails)
            markup += PBR

            markup += socialHistory(
                textDetails,
                { updatedAfter: this.stay.ward_round.created_at },
            )
            markup += PBR

            return markup
        }
    },
    methods: {
        markWardRoundComplete() {
            // stays.extActSetPathValue(this.stay_id, "stage", "WARD")
            stays.extActSetPathValue(this.stay_id, "ward_round.complete", true)
            // stays.extActSetPathValue(this.stay_id, 'ward_round.emr_notes', this.notes)
            if (!this.nihssV1) {
                if (this.textNIHSSObj)
                    this.setArrayedObjVal(this.stay_id, "nihss_set", this.textNIHSS, "editable", false)
            }
        }
    }
})
