



















































import mixins from "vue-typed-mixins"
import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Eyes from "./Eyes.vue"

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Eyes,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        nihss_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            gazeEnums: {
                normal: 'NORMAL',
                unable: ['UNABLE_TO_LOOK_RIGHT', 'UNABLE_TO_LOOK_LEFT'],
                forced: ['FORCED_DEVIATION_TO_RIGHT', 'FORCED_DEVIATION_TO_LEFT']
            }
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        gazeDebugText(): string {
            return `gaze: ${this.gaze}`
        },
        gaze: {
            get(): string | null | undefined {
                return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, 'gaze') || null
            },
            set(val: string | null) {
                this.setArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, 'gaze', val)
            }
        }
    },
})
