
































import Vue from 'vue'
import { clone, filter, includes, isEqual, isFinite, map, xor } from 'lodash-es'

interface Option {
    id: number
    title: string
}

export default Vue.extend({
    props: {
        label: {
            type: String,
            required: true,
        },
        title: {
            type: String,
            required: false,
        },
        options: {
            type: Array as () => Option[],
            default: () => [],
            required: true,
        },
        value: {
            type: Array as () => number[],
            required: false,
        },
        /** if provided, this overrides the locally-computed selectedText */
        customText: {
            type: String,
            required: false
        },
        /** if true, will restrict the displayed text to maxChars characters */
        shortenText: {
            type: Boolean,
            default: false
        },
        maxChars: {
            type: Number,
            default: 30
        },
        /** if true, clicking on [All] clears the entire selection */
        allClears: {
            type: Boolean,
            default: true
        },
        /** only useful if allClears is false. Sets the selection to this if [All] is clicked. */
        allDefaultIDs: {
            type: Array as () => number[],
            default: () => [],
        },
        closeOnSelect: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            /** used to store the list of selected IDs while the dropdown is open */
            localSelected: [] as number[],
            dropdownVisible: false,
            selecting: false,
        }
    },
    computed: {
        selected: {
            get(): number[] {
                if (Array.isArray(this.value)) return this.value
                return isFinite(this.value) ? [this.value] : []
            },
            set(vals: number[]) {
                this.$emit('input', vals)
            }
        },
        selectedIDs(): number[] {
            return this.dropdownVisible ? this.localSelected : this.selected
        },
        selectedText(): string {
            if (this.customText !== undefined)
                return this.customText

            const selected = filter(this.options, d => includes(this.selectedIDs, d.id))
            const labels = map(selected, opt => opt.title)
            return labels.join(", ") || "[All]"
        },
        selectedTextShort(): string {
            if (this.selectedText.length < this.maxChars)
                return this.selectedText
            return `${this.selectedText.slice(0, this.maxChars)}...`
        },
    },
    watch: {
        selectedIDs: {
            handler: function(val: number[]) {
                // this is emitted every time the selection is changed to allow
                // parent components to compute the selected text if desired
                this.$emit('selectedIDs', val)
            },
            immediate: true
        }
    },
    methods: {
        inSelected(id: number) {
            return includes(this.localSelected, id)
        },
        allSelected() {
            this.localSelected = this.allClears ? [] : clone(this.allDefaultIDs)
        },
        toggleSelect(id: number) {
            this.selecting = true
            this.localSelected = xor(this.localSelected, [id])
        },
        hideHandler(event: any) {
            if (!this.closeOnSelect && this.selecting) {
                // Don't close the dropdown if we're currently selecting items
                event.preventDefault()
            } else {
                // Update `selected` when closing the menu if there's been a change
                if (!isEqual(this.selected, this.localSelected))
                    this.selected = this.localSelected
                this.dropdownVisible = false
            }
            this.selecting = false
        },
        showHandler(event: any) {
            this.localSelected = this.selected
            this.dropdownVisible = true
        },
    },
})
