
import Vue from 'vue'
import { assign, each, find, first, get, keys, map, set } from "lodash-es"
import utils from 'utils'

import { LayoutGroupExt, PropExtInfo, getPropInfoDummy } from "models"
import { DisplayInfo, DisplayList, LayoutGroup, LayoutAlignment } from "models/layout"
import { AnyPropLookup, NestedProp, HasChildProp, AnyProp, PropChoice_Ext, nestedPropDecoder, hasChildPropDecoder } from "models/schema"
import { FullSchema } from 'models/schema/stay'
import { safeDecoding } from 'utils/store'

export default Vue.extend({
    methods: {
        isDisplayInfo(obj: string | DisplayInfo): obj is DisplayInfo {
            return (<DisplayInfo>obj).field !== undefined
        },

        getSectionSchema(section: keyof FullSchema): NestedProp | undefined {
            const fullSchema = this.$store.direct.state.stays_v2.fullSchema
            if (!fullSchema) return
            const sectionSchema: NestedProp = safeDecoding(fullSchema[section], nestedPropDecoder, 'mixins/Display.getSectionSchema')
            return sectionSchema
        },

        getChildSchema(section: keyof FullSchema): AnyPropLookup | undefined {
            const fullSchema = this.$store.direct.state.stays_v2.fullSchema
            if (!fullSchema) return
            const sectionSchema: HasChildProp = safeDecoding(fullSchema[section], hasChildPropDecoder, 'mixins/Display.getChildSchema')
            return sectionSchema.child.children
        },

        generatePropExtInfo(
            pathBase: string,
            propDict: AnyPropLookup,
            fields: DisplayList,
            force_choices_alignment?: LayoutAlignment
        ): PropExtInfo[] {
            const self = this

            return map(fields, function(_info) {
                const info = self.isDisplayInfo(_info) ? _info : { field: _info }
                const f = info.field

                if (force_choices_alignment)
                    info.choices_alignment = force_choices_alignment

                const path = pathBase ? `${pathBase}.${f}` : f
                if (f in propDict)
                    return assign({ uid: utils.getUID(), name: f, path }, propDict[f], info)

                return getPropInfoDummy(f, path, `[FIELD ?]: ${f}`)
            })
        },

        generatePropExtInfoFromSchema(
            pathBase: string,
            propDict: FullSchema | null,
            fields: DisplayList,
            force_choices_alignment?: LayoutAlignment
        ): PropExtInfo[] | null {
            if (!propDict) return null
            let dict: unknown = propDict
            return this.generatePropExtInfo(pathBase, dict as AnyPropLookup, fields, force_choices_alignment)
        },

        generateGroups(
            pathBase: string,
            propList: PropExtInfo[],
            layout?: LayoutGroup[]
        ): LayoutGroupExt[] | undefined {
            if (layout === undefined)
                return

            const extGroups: LayoutGroupExt[] = []
            const uid = utils.getUID()

            each(layout, (group, index) => {

                const extGroup: LayoutGroupExt = {
                    uid: `${uid}___${index}`,
                    fields: []
                }

                for (let k of keys(group)) {
                    if (k === "fields")
                        continue
                    set(extGroup, k, get(group, k))
                }

                for (let name of group.fields) {
                    const propInfo = find(propList, ["name", name])
                    if (propInfo)
                        extGroup.fields.push(propInfo)
                    else
                        extGroup.fields.push(
                            getPropInfoDummy(name, pathBase ? `${pathBase}.${name}` : name, `[GROUPFIELD ?]: ${name}`))
                }

                extGroups.push(extGroup)
            })

            // console.log("generateGroups", pathBase, propList, layout, extGroups)
            return extGroups
        },

        makePropExtInfo_NIHSS(propDict: AnyPropLookup, fields: DisplayList, nihss_id: number): PropExtInfo[] {
            let props: PropExtInfo[] = []

            props = map(fields, info_or_str => {
                const info = this.isDisplayInfo(info_or_str) ? info_or_str : { field: info_or_str }
                const field = info.field

                let prop: PropExtInfo

                if (field in propDict) {
                    prop = assign(
                        { uid: utils.getUID(), path: 'nihss_set', name: field, obj_id: nihss_id, nested_path: field },
                        propDict[field],
                        info
                    )
                }
                else {
                    prop = getPropInfoDummy(field, 'nihss_set', `[FIELD ?]: ${field}`)
                    prop.obj_id = nihss_id
                    prop.nested_path = field
                }

                return prop
            })

            return props
        },

        makeGroups_NIHSS(propList: PropExtInfo[], layout: LayoutGroup[], nihss_id: number): LayoutGroupExt[] {

            let groups: LayoutGroupExt[] = []

            each(layout, (group, index) => {
                const groupExt: LayoutGroupExt = {
                    uid: `${utils.getUID()}___${index}`,
                    fields: [],
                }

                for (let k of keys(group)) {
                    const k_ = k as keyof LayoutGroup
                    if (k_ === 'fields')
                        continue
                    const v: any = group[k_]
                    groupExt[k_] = v
                }

                for (let name of group.fields) {
                    let prop = find(propList, { name })
                    if (!prop) {
                        prop = getPropInfoDummy(name, 'nihss_set', `[GROUPFIELD ?]: ${name}`)
                        prop.obj_id = nihss_id
                        prop.nested_path = name
                    }
                    groupExt.fields.push(prop)
                }

                groups.push(groupExt)
            })

            return groups
        },

        getPropAndFirstChoice(allFields: PropExtInfo[], fieldName: string): [PropExtInfo, PropChoice_Ext | undefined] {
            const propInfo = find(allFields, { name: fieldName })
            let choice: PropChoice_Ext | undefined
            if (Array.isArray(propInfo?.choices))
                choice = first(propInfo?.choices)
            else
                choice = { display_name: '', value: false }
            return [propInfo!, choice]
        },
    }
})

