var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-nav-item-dd",
    { attrs: { text: _vm.userDisplayName, right: "" } },
    [
      _c("b-dd-item", { on: { click: _vm.logout } }, [_vm._v("Log Out")]),
      _vm._v(" "),
      !_vm.isTempUser
        ? [
            _c("b-dd-divider"),
            _vm._v(" "),
            _c("change-password"),
            _vm._v(" "),
            _c("b-dd-form", [
              _c("div", { staticClass: "pretty p-switch p-fill" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.anonymise,
                      expression: "anonymise"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.anonymise)
                      ? _vm._i(_vm.anonymise, null) > -1
                      : _vm.anonymise
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.anonymise,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.anonymise = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.anonymise = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.anonymise = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "state p-warning" }, [
                  _c("label", [_vm._v("Anonymise")])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("user-signature")
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.canChangeHome
        ? [
            _c("b-dd-divider"),
            _vm._v(" "),
            _c(
              "b-dd-form",
              [
                _c("triage-mode-control", { staticClass: "dropdown-item px-0" })
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.canSuspendPrivileges
        ? [
            _c("b-dd-divider"),
            _vm._v(" "),
            _c("change-password"),
            _vm._v(" "),
            _c("b-dd-form", [
              _c("div", { staticClass: "pretty p-switch p-fill" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.suspendPrivileges,
                      expression: "suspendPrivileges"
                    }
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.suspendPrivileges)
                      ? _vm._i(_vm.suspendPrivileges, null) > -1
                      : _vm.suspendPrivileges
                  },
                  on: {
                    change: function($event) {
                      var $$a = _vm.suspendPrivileges,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.suspendPrivileges = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.suspendPrivileges = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.suspendPrivileges = $$c
                      }
                    }
                  }
                }),
                _vm._v(" "),
                _c("div", { staticClass: "state p-warning" }, [
                  _c("label", [_vm._v("Suspend Privileges")])
                ])
              ])
            ])
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isPowerUser
        ? [
            _c("b-dd-divider"),
            _vm._v(" "),
            _c(
              "b-dd-form",
              [
                _c("telestroke-mode-control", {
                  staticClass: "dropdown-item px-0"
                }),
                _vm._v(" "),
                _c("debug-control", { staticClass: "dropdown-item px-0" }),
                _vm._v(" "),
                _c("type-check-control", { staticClass: "dropdown-item px-0" }),
                _vm._v(" "),
                _c("div", { staticClass: "pretty p-switch p-fill" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.textAreaContSave,
                        expression: "textAreaContSave"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.textAreaContSave)
                        ? _vm._i(_vm.textAreaContSave, null) > -1
                        : _vm.textAreaContSave
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.textAreaContSave,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              (_vm.textAreaContSave = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.textAreaContSave = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.textAreaContSave = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "state p-success" }, [
                    _c("label", [_vm._v("Free Text v2")])
                  ])
                ])
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.isDeveloper
        ? [
            _c("b-dd-divider"),
            _vm._v(" "),
            _c("extras-control", { staticClass: "dropdown-item" }),
            _vm._v(" "),
            _c(
              "b-dd-item",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.triggerBrowserException.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Trig. Err (local)")]
            ),
            _vm._v(" "),
            _c(
              "b-dd-item",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.triggerServerException.apply(null, arguments)
                  }
                }
              },
              [_vm._v("Trig. Err (server)")]
            ),
            _vm._v(" "),
            _c(
              "b-dd-item",
              {
                on: {
                  click: function($event) {
                    $event.stopPropagation()
                    return _vm.triggerServerException(false)
                  }
                }
              },
              [_vm._v("Trig. Err (all)")]
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _vm.betaEnabled
        ? [_c("b-dd-divider"), _vm._v(" "), _c("beta-features")]
        : _vm._e(),
      _vm._v(" "),
      _c("b-dd-divider"),
      _vm._v(" "),
      _vm.helpModalId
        ? _c(
            "b-dd-item",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.helpModalId,
                  expression: "helpModalId"
                }
              ]
            },
            [_vm._v("Help")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("changelog")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }