










































import mixins from 'vue-typed-mixins'
import { throttle } from 'lodash-es'
import { registerHeightObserver, unregisterHeightObserver } from 'element-height-observer'

import utils from 'utils'
import stays from '@store/stays'
import { ASMT_WARD } from 'routers/constants'
import { Stay } from 'models/data/stay'
import { ViewMode } from 'models/meta'
import { FullSchema } from 'models/schema/stay'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import Sidebar from "./SideBar.vue"
import StaySummary from "./StaySummary.vue"

export default mixins(DelayRouteLeaveMixin).extend({
    components: {
        Sidebar,
        StaySummary
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        viewMode: {
            required: false,
            default: ViewMode.normal,
        },
    },
    data() {
        return {
            htmlID: `${utils.getUID()}___dataentry`,
            /** flag indicating if scroll is being monitored */
            monitorScroll: false,
            canScrollDown: false,
            loadWaiter: 0,
            loaded: false,
            notesDocked: false,
        }
    },
    computed: {
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        fullSchema(): FullSchema | null {
            return this.$store.direct.state.stays_v2.fullSchema
        },
        sidebarBtnText(): string {
            return this.sidebarVisible ? 'Hide sidebar' : 'Show sidebar'
        },
        ready(): boolean {
            return this.stay !== undefined
        },
        syncMonitorInterval(): number {
            return this.$store.direct.state.session.syncMonitorInterval
        },
        savePending(): boolean {
            return stays.state.stayChanged
        },
        sidebarVisible(): boolean {
            return this.$store.direct.state.user.asmtSidebarDocked
        },
    },
    watch: {
        stay_id: {
            handler: function(newVal, oldVal) {
                if (oldVal)
                    stays.mutClearStay(oldVal)
                this.loadStay()
            },
            immediate: true
        },
        ready(val) {
            if (val) {
                if (!this.monitorScroll) {
                    this.$nextTick(() => {
                        const appElem = document.getElementById(this.htmlID)
                        if (appElem) {
                            this.calculateScroll()
                            registerHeightObserver(appElem, () => {
                                this.calculateScroll()
                            })
                            appElem.onscroll = throttle(() => {this.calculateScroll()}, 250)
                            this.monitorScroll = true
                        }

                    })
                }
            }
        },
        stay() {
            this.progressStayStageToWard()
        },
        $route() {
            this.progressStayStageToWard()
        },
    },
    beforeCreate() {
        stays.mutClearAll()
    },
    mounted() {
        window.scrollTo(0, 0)
        document.documentElement.className += ' overflow-hidden'
    },
    beforeDestroy() {
        this.stopLoadWaiter()
        if (this.monitorScroll) {
            const appElem = document.getElementById(this.htmlID)
            if (appElem) {
                unregisterHeightObserver(appElem)
                appElem.onscroll = null
            }
        }
        this.monitorScroll = false
        document.documentElement.className = document.documentElement.className.replace(' overflow-hidden', '')
        stays.mutClearStay(this.stay_id)
    },
    methods: {
        $_loadStay() {
            this.loaded = false
            stays.actPullStay(this.stay_id)
            .then(res => {
                this.loaded = true
            })
        },
        loadStay() {
                if (this.fullSchema)  // so that loading happens immediately if schema is ready
                    this.$_loadStay()
                else {
                    this.loadWaiter = window.setInterval(() => {
                        if (this.fullSchema) {
                            this.stopLoadWaiter()
                            this.$_loadStay()
                        }
                    }, 250)
                }
        },
        stopLoadWaiter() {
            if (this.loadWaiter) {
                clearInterval(this.loadWaiter)
                this.loadWaiter = 0
            }
        },
        calculateScroll() {
            const appElem = document.getElementById(this.htmlID)
            if (appElem) {
                const scrolledY = appElem.scrollHeight - appElem.scrollTop
                const diff = Math.abs(scrolledY - appElem.clientHeight)
                this.canScrollDown = diff > 5
            }
        },
        progressStayStageToWard(): void {
            if (this.$route.name === ASMT_WARD) {
                if (this.stay && this.stay.stage !== 'WARD') {
                    stays.extActSetPathValue(this.stay_id, "stage", "WARD")
                    .then(res => {
                        setTimeout(() => {
                            const timer = setInterval(() => {
                                if (this.savePending)
                                    return
                                clearInterval(timer)
                                stays.mutTriggerLoadStays()  // to refresh sidebar
                                // this.$router.push({ name, params: { stay_id: `${this.stay_id}` } })
                            })
                        }, this.syncMonitorInterval * 1.5)
                    })
                    stays.mutUpdateAsap()
                }
            }
        },
        toggleSidebar(): void {
            this.$store.direct.dispatch.user.setAsmtSidebarDocked(!this.$store.direct.state.user.asmtSidebarDocked)
        },
    }
})
