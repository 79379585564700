var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "d-flex align-items-end" },
        [
          _c(
            "div",
            { staticClass: "d-inline-block" },
            [_c("stay-filters", { on: { update: _vm.pull } })],
            1
          ),
          _vm._v(" "),
          _c("print", {
            staticClass: "ml-auto",
            attrs: { stay_ids: _vm.stay_ids }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("overview-table", {
        staticClass: "mt-2",
        attrs: { stays: _vm.advOrderedStays, showSurgery: _vm.showSurgery }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }