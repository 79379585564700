import { map } from 'lodash-es'
import { FilterParam } from 'models/store/user'
import { enumKeys } from 'models/_helpers'

export enum TSScope {
    Telestroke = 'ts',
    Hospitals = 'site',
    All = 'any'
}
export const TSScope_keys = enumKeys(TSScope)
export const TSScope_values = map(TSScope_keys, k => TSScope[k])
export const TSScope_items = map(TSScope_keys, k => ({ value: TSScope[k], text: k }))

export interface FiltersParams {
    /** show discharged */
    sd: boolean
    /** period. only relevant if sd is true */
    pd: string
    /** doctors */
    d: FilterParam
    /** diagnoses */
    dg: FilterParam
    /** specialities */
    sp: FilterParam
    /** spaces (wards) */
    spc: FilterParam
    /** care classifications */
    c: FilterParam
    /** hospitals (sites) */
    h: FilterParam
    /** telestroke scope */
    tsc: TSScope
}
