import { find, forEach, get, map } from 'lodash-es'

import { LBR, PBR } from './constants'
import { doPlainIndent, freeTextSubHtmlBreaks } from './helpers'
import { diagnosisWithSummaries } from './diagnosis'
import { genericQuestionsText } from './question'

import { Issue, OtherIssue } from 'models/data/issue'
import { Stay } from 'models/data/stay'
import { QuestionCategory } from 'models/med_templates/question'
import { TextDetails, TextDetailsExt } from './interfaces'

import stays from '@store/stays'
import store from 'store'

interface issueFullTextParams {
    stay: Stay
    issue: Issue | OtherIssue
    ignoreStacked?: boolean
}
export function issueFullText({ stay, issue, ignoreStacked }: issueFullTextParams): string {
    let lines: string[] = []

    const dbIssue = find(store.direct.getters.templates.allIssues, ["id", issue.issue])

    // Get title of all selected results (if any)
    if (dbIssue) {
        const text = genericQuestionsText({
            stay,
            category: QuestionCategory.ISSUE_RESULT,
            getQuestionGroupsFrom: dbIssue,
            answers: issue.answers,
            ignoreStacked,
        })
        if (text.length) lines.push(text)
    }

    // Add free text summary
    if (issue.summary)
        lines.push(freeTextSubHtmlBreaks(issue.summary))

    return lines.join("<br>")
}

/**
 * Takes a list of issues and generates the appropriate summary text
 * incuding checked IssueResults and any notes
 * @param issues 
 */
function _issuesWithSummary({ stay, issues }: { stay: Stay, issues: (Issue | OtherIssue)[] }): { title: string, summary: string }[] {
    return map(issues, issue => ({
        title: issue.title,
        summary: issueFullText({ stay, issue })
    }))
}

/**
 * Returns a list of `{ title, summary }` objects for all active issues for a stay
 * @param param0 
 */
function activeIssuesWithSummary({ stay }: TextDetails): { title: string, summary: string }[] {
    if (!stay) return []

    const activeIssues = stays.getActiveIssues(stay.id)
    return _issuesWithSummary({ stay, issues: activeIssues })
}

/**
 * Returns a list of `{ title, summary }` objects for all resolved issues for a stay
 * @param param0 
 */
export function resolvedIssuesWithSummary({ stay }: TextDetails): { title: string, summary: string }[] {
    if (!stay) return []

    const resolvedIssues = stays.getResolvedIssues(stay.id)
    return _issuesWithSummary({ stay, issues: resolvedIssues })
}


/**
 * Outputs the titles of resolved issues
 * @param param0 
 * @param inline 
 */
export function resolvedIssuesTitles({ stay }: TextDetails, inline: boolean, plain: boolean = false): string {
    if (!stay) return ''

    const resolvedIssues = stays.getResolvedIssues(stay.id)
    if (!resolvedIssues.length) return ''

    let heading = 'Resolved Issues'
    heading = plain ? `${heading}:` : `**${heading}:**`

    const headingSep = inline ? ' ' : LBR
    const titleSep = inline ? ' / ' : LBR

    const lines = [heading]
    const titles = map(resolvedIssues, issue => issue.title)
    const titlesLine = titles.join(titleSep)
    lines.push(titlesLine)

    let text = lines.join(headingSep)
    text = plain ? doPlainIndent(text) : text
    return text
}

function resolvedIssuesWithDetailPlain(
    resolvedIssues: { title: string, summary: string }[],
    { hideTitle, title }: TextDetailsExt
): string {
    if (!resolvedIssues.length) return ""

    const lines: string[] = []

    if (!hideTitle) {
        title = title || 'Resolved Issues'
        lines.push(`${title}:`)
    }

    forEach(resolvedIssues, entry => {
        lines.push(doPlainIndent(`${entry.title}${LBR}${entry.summary}`))
    })

    return lines.join(PBR)
}

export function resolvedIssuesWithDetail(textDetails: TextDetailsExt): string {
    let { stay, hideTitle, title, plain } = textDetails
    if (!stay) return ""

    const resolvedIssues = resolvedIssuesWithSummary({ stay })

    if (plain)
        return resolvedIssuesWithDetailPlain(resolvedIssues, textDetails)

    if (!resolvedIssues.length) return ""

    let lines: string[] = []

    if (!hideTitle) {
        title = title || "Resolved Issues"
        lines.push(`**${title}**  \n`)
    }

    forEach(resolvedIssues, (entry, index) => {
        lines.push(
            `${entry.title}  \n${entry.summary}\n`
        )
    })

    return lines.join("\n") + PBR
}

export function otherIssues({ stay }: TextDetails): string {
    const text = get(stay, "other_issues_text")
    if (!text) return ""

    return "**Other issues**<br>" + text + PBR
}

export function wardIssues(textDetails: TextDetails): string {
    if (!textDetails.stay) return ""

    let lines: string[] = ["**Issues**\n\n"]

    const diagnosis = diagnosisWithSummaries(textDetails)
    if (diagnosis)
        lines.push(`1\\. ${diagnosis}\n`)

    // Get all other issues
    const issueEntries = activeIssuesWithSummary(textDetails)

    if (!diagnosis && !issueEntries.length)
        return ""

    forEach(issueEntries, entry => {
        lines.push(
            `${lines.length}\\. ${entry.title}  \n${entry.summary}\n`
        )
    })

    const resolvedIssues = resolvedIssuesTitles(textDetails, true)
    if (resolvedIssues.length)
        lines.push(`\n\n${resolvedIssues}`)

    return lines.join("\n") + "\n\n"
}

/**
 * Generates text listing all active issues with summary text
 * @param param0 
 * @param plain if true, there is no bold, nor numbering (numbered items are replaced by hashes)
 * @param html if true, will *NOT* escape characters that causes Markdown formatting issues
 */
export function activeIssuesWithSummaryText(
    { stay, title, hideTitle }: TextDetails,
    plain: boolean = false,
    html: boolean = false
): string {
    if (!stay) return ''

    const issueEntries = activeIssuesWithSummary({ stay })
    if (!issueEntries.length) return ''

    const escaper = html ? '' : '\\'
    const lines: string[] = map(issueEntries, (entry, i) => {
        const prefix = plain ? `${escaper}#` : `${i + 1}${escaper}.`
        const title = `${prefix} ${entry.title}`
        if (!entry.summary)
            return `${title}`
        const issueTxt = `${title}${LBR}${entry.summary}`
        return plain ? doPlainIndent(issueTxt) : issueTxt
    })

    if (title && !hideTitle) {
        title = title || "Issues"
        title = plain ? `${title}:` : `**${title}:**`
        lines.unshift(title)
    }

    return lines.join(PBR)
}
