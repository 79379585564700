var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("textarea", { attrs: { id: _vm.cHtmlID } }),
      _vm._v(" "),
      _vm.editorLoaded ? _c("copy-btn-highlighter") : _vm._e(),
      _vm._v(" "),
      _c("print-help", {
        on: {
          "html-id": function($event) {
            _vm.printHelpHtmlID = $event
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }