



























































































import mixins from 'vue-typed-mixins'
import StaysMixin from '../mixins/Stays.vue'
import { isFinite } from 'lodash-es'

import utils from 'utils'
import { Stay } from 'models/data/stay'
import { TSScope } from 'models/filters'

import Actions from './Actions.vue'
import NeuroAcuteCareSummary from 'components/data-entry/views/NeuroAcuteCareSummaryModal.vue'
import StayDoctor from './StayDoctor.vue'
import StaySummary from '../StaySummary.vue'

export default mixins(StaysMixin).extend({
    components: {
        Actions,
        NeuroAcuteCareSummary,
        StayDoctor,
        StaySummary,
    },
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        showExtId: {
            type: Boolean,
            default: false
        },
        tsScope: {
            type: String as () => TSScope,
            default: TSScope.Telestroke
        },
        showSite: {
            type: Boolean,
            default: false,
        },
        showLocation: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        const uid = utils.getUID()
        const baseId = `s${this.stay.id}`
        return {
            collapseId: `${uid}___${baseId}_details`,
            actionsVisible: false,
            opened: false,
            showDoctors: false,
        }
    },
    computed: {
        triageMode(): boolean {
            return this.$store.direct.state.user.triage_mode
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        showSpeciality(): boolean {
            return this.$store.direct.state.session.homeShowSpeciality
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        canChangeDoctor(): boolean {
            return !this.$store.direct.state.session.hasPasFeed && this.$store.direct.getters.user.canCreateStays
        },
        canAccessSummary(): boolean {
            return !this.telestrokeMode || this.stay.site_only
        },
        showSiteAndLocation(): boolean {
            return this.showSite && this.showLocation
        },
        identifierText_c(): string {
            return this.identifierText(this.stay)
        },
        patientText(): string {
            return this.patientNameAndAge(this.stay)
        },
        hospitalText(): string {
            return this.hospitalName(this.stay)
        },
        locationText(): string {
            return this.locationDesc(this.stay)
        },
        fullLocationText(): string {
            const hospital = this.hospitalName(this.stay)
            const location = this.locationDesc(this.stay)
            const tokens: string[] = []
            if (this.stay.site_only || this.showSiteAndLocation) { 
                if (!!hospital)
                    tokens.push(hospital)
                if (!!location)
                    tokens.push(location)
                return tokens.join(' / ') || '-'
            }
            if (this.showSite)
                return hospital || '-'
            return location || '-'
        },
        timeText(): string {
            const useCreatedAt = this.telestrokeMode && this.tsScope === TSScope.Telestroke
            return useCreatedAt ? this.createdAt(this.stay) : `${this.stay.length_of_stay || '-'}`
        },
        doctorText(): string {
            if (isFinite(this.stay.doctor)) {
                const doctorId: number = this.stay.doctor as number
                const doctor = this.$store.direct.getters.templates.doctor(doctorId)
                if (doctor)
                    return doctor.last_name
            }
            return '-'
        },
        specialityText(): string {
            return this.stay.sp_title
        },
        active(): boolean {
            return this.stay.active
        },
    },
    watch: {
        actionsVisible(val: boolean) {
            this.$emit('actions-visible', {  stay_id: this.stay.id, visible: val })
        },
    },
})
