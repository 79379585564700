





































































import Vue from "vue"
// import { saveAs } from 'file-saver'
// import * as JSZip from 'jszip'
import stays from '@store/stays'

import AdminMenu from './TopBarAdminMenu.vue'
import ChangesIndicator from '@misc/ChangesIndicator.vue'
import Changelog from '@misc/Changelog.vue'
import ChooseSpeciality from "@debug/ChooseSpeciality.vue"
import HelpModal from '@misc/HelpModal.vue'
import SearchStays from '@home/SearchStays.vue'
import StoreControl from "@debug/StoreControl.vue"
import SyncControl from "@debug/SyncStaysControl.vue"
import UserMenu from './TopBarUserMenu.vue'

export default Vue.extend({
    components: {
        AdminMenu,
        Changelog,
        ChangesIndicator,
        ChooseSpeciality,
        HelpModal,
        SearchStays,
        StoreControl,
        SyncControl,
        UserMenu,
    },
    data() {
        return {
            rootURL: window.scrawl.baseURL + '/',
            auxURL: window.scrawl.auxURL + '/',
            altURL: window.scrawl.altURL ? window.scrawl.altURL + '/' : '',
            documentTitle: document.title,
            version: window.scrawl.sentryRelease,
            helpModalId: '?',
            navOpacity: 1,
            navZIndex: 1030,
        }
    },
    computed: {
        hasUnsavedChanges(): boolean  {
            return stays.state.stayChanged
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        betaEnabled(): boolean {
            return this.$store.direct.getters.user.betaEnabled
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isPowerUser() {
            return this.$store.direct.getters.user.isPowerUser
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        hasSeniorPrivileges(): boolean {
            return this.isPowerUser || this.$store.direct.state.user.is_senior
        },
        canUseAuxSite(): boolean {
            return !this.telestrokeMode && this.$store.direct.getters.user.canUseAuxSite
        },
        sandboxFrom(): string {
            return this.$store.direct.state.session.ux_sandbox_from
        },
        sandboxTo(): string {
            return this.$store.direct.state.session.ux_sandbox_to
        },
        hasSandboxFrom(): boolean {
            return this.isPowerUser && this.sandboxFrom !== ''
        },
        hasSandboxTo(): boolean {
            return this.isPowerUser && this.sandboxTo !== ''
        },
        hasSyncErrors(): boolean {
            return this.$store.direct.state.session.syncErrors.length > 0
        },
        neurologySelected(): boolean {
            return this.$store.direct.getters.user.neuroSelected
        },
        orthoSelected(): boolean {
            return this.$store.direct.getters.user.orthoSelected
        },
        canExportData(): boolean {
            return this.$store.direct.getters.user.canExportData
        },
        loggedIn() {
            return this.$store.direct.getters.session.loggedIn
        },
    },
    methods: {
        getRouterPath: function(path: string) {
            return { path: path }
        },
        hideNav() {
            this.navOpacity = 0
            this.navZIndex = -1
            this.$toasted.info("The navbar will reappear after 10 seconds", { position: 'top-right' })
            window.setTimeout(() => {
                this.navOpacity = 1
                this.navZIndex = 1030
            }, 10000)
        },
        toggleSyncErrors(): void {
            this.$store.direct.commit.session.toggleSyncErrorsVisible()
        },
        /*
        savePage() {
            const now = moment()
            const fileBase = now.format('YYYYMMDD-HHmmss-SSS')
            const zip = new JSZip()
            zip.file(`${fileBase}.html`, document.documentElement.innerHTML)
            zip.generateAsync({ type: 'blob', compression: 'DEFLATE' })
            .then(content => {
                // saveAs(content, `${fileBase}.zip`)
                return utils.request.postFile('/file_upload/', content, `${fileBase}.zip`)
            })
            .then(res => {
                this.$toasted.info('Uploaded this page to backend.')
            })
        },
        */
    }
})
