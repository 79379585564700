var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex align-items-start" },
    [
      _c("dropdown", {
        staticClass: "mb-0",
        attrs: {
          label: "NIHSS (text output)",
          size: _vm.size,
          alignRight: "",
          items: _vm.simpleNihssList,
          textField: "desc",
          chooseText: "[ None ]",
          value: _vm.textNIHSS
        },
        on: {
          input: function($event) {
            _vm.textNIHSS = $event
          }
        }
      }),
      _vm._v(" "),
      _vm.debugMode && _vm.isPowerUser
        ? _c("dropdown", {
            staticClass: "mb-0 ml-2",
            attrs: {
              label: "NIHSS (text output) - All",
              ddToggleClasses: "text-white progress-bar-striped",
              variant: "dummy",
              size: _vm.size,
              alignRight: "",
              items: _vm.allNihss,
              textField: "desc",
              chooseText: "[ None ]",
              value: _vm.textNIHSS
            },
            on: {
              input: function($event) {
                _vm.textNIHSS = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }