























































































import Vue from 'vue'
import * as moment from 'moment'
import utils from 'utils'

import AsapSitesFilter from '@standalone/SitesFilter.vue'
import AsapUsage from '@standalone/UsageChart_v2.vue'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import FilterHospitals from '@shared/FilterHospitals.vue'
import HyperacuteReview from '../HyperacuteReview.vue'
import StrokeCharts from './StrokeCharts.vue'
import StayEditModal from './StayEditModal.vue'


const DATE_FORMAT = 'YYYY-MM'

function oneYearAgo() {
    return moment().startOf('month').subtract(1, 'year').format(DATE_FORMAT)
}

function lastMonth() {
    return moment().startOf('month').subtract(1, 'month').format(DATE_FORMAT)
}

export default Vue.extend({
    components: {
        AsapSitesFilter,
        AsapUsage,
        DateTimePicker,
        FilterHospitals,
        HyperacuteReview,
        StrokeCharts,
        StayEditModal,
    },
    props: {
        start: {
            type: String,
            required: false,
            default: oneYearAgo,
        },
        end: {
            type: String,
            required: false,
            default: lastMonth,
        },
    },
    data() {
        const now = moment()

        let year = moment('2019')
        const years: { title: string, value: string }[] = []

        while (year.year() <= now.year()) {
            const val = year.year()
            years.push({ title: `${val}`, value: `${val}` })
            year.add(1, 'year')
        }
        years.reverse()
        years.push({ title: 'Past 12 months', value: 'rolling' })
        years.push({ title: 'Year To Date', value: 'ytd' })

        const months: any = []
        let month = moment().subtract(1, 'year')
        while (month < now) {
            months.push({ title: month.format("MMM 'YY"), value: month.format(DATE_FORMAT) })
            month.add(1, 'month')
        }
        months.reverse()

        return {
            activeTab: 0,
            lastTabVisible: false,
            years,
            months,
            dateFormat: DATE_FORMAT,
            loading: false,
        }
    },
    computed: {
        showAsapUsage(): boolean {
            return this.$store.direct.state.session.ux_asap_viz
        },
        asapTabVisible(): boolean {
            return this.showAsapUsage && this.lastTabVisible
        },
        startDate: {
            get() {
                return this.start
            },
            set(val: string | null) {
                if (val)
                    this.navigateTo(val, this.end)
            }
        },
        endDate: {
            get() {
                return this.end
            },
            set(val: string | null) {
                if (val)
                    this.navigateTo(this.start, val)
            }
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
    },
    watch: {
        activeTab: {
            handler: function(index: number) {
                this.lastTabVisible = (this.$refs['tabs'] && ((this.$refs['tabs'] as any).tabs.length -1) === index) || false
            },
            immediate: true
        }
    },
    created() {
        const params = this.$route.params
        if (!params.start || !params.end)
            this.navigateTo(this.start, this.end)
    },
    methods: {
        navigateTo(start: string, end: string) {
            this.$router
            .push({ name: this.$route.name, params: { start, end }})
            .catch(err => {
                if (err.name !== 'NavigationDuplicated')
                    utils.handleRequestError(err)
            })
        },
        setYearRange(val: string) {
            if (!val)
                return

            switch (val) {
                case 'rolling':
                    this.navigateTo(oneYearAgo(), lastMonth())
                    break
                case 'ytd':
                    this.navigateTo(moment().startOf('year').format(DATE_FORMAT), lastMonth())
                    break
                default:  // assume it's a YYYY string
                    const year = moment(val).startOf('year')
                    const followingYear = year.clone().add(11, 'months')
                    this.navigateTo(year.format(DATE_FORMAT), followingYear.format(DATE_FORMAT))
            }
        },
        setMonthRange(val: string) {
            // assume it's a YYYY-MM string
            const month = moment(val).startOf('month')
            this.navigateTo(month.format(DATE_FORMAT), month.format(DATE_FORMAT))
        },
        shiftByMonths(numMonths: number) {
            const start = moment(this.start)
            const end = moment(this.end)
            start.add(numMonths, 'months')
            end.add(numMonths, 'months')
            this.navigateTo(start.format(DATE_FORMAT), end.format(DATE_FORMAT))
        },
    },
})
