import VueRouter, { RouteConfig } from "vue-router"
import { startsWith } from "lodash-es"
import * as urlparse from 'url-parse'

import utils from "utils"
import store from 'store'
import { ViewMode } from 'models/meta'
import { ASMT_ADM, ASMT_MDT, ASMT_NURSE, ASMT_NURSE_SPECIALITY, ASMT_NURSE_SYNDROME, ASMT_SPECIALITY, ASMT_SYNDROME, ASMT_WARD } from 'routers/constants'

import Reload from 'components/apps/Reload.vue'
import NotFound from '@misc/NotFound.vue'
import FontAwesome from '@misc/FontAwesome.vue'

import Login from "@misc/AccountLogin.vue"
import Home from 'components/home/v2/Home.vue'
import DataExport from "@misc/DataExport.vue"
import DataEntry from "components/data-entry/DataEntry.vue"
import EditStay from "components/data-entry/EditStay.vue"
import Admission from "components/data-entry/views/Admission.vue"
import MDT from "components/data-entry/views/MDT.vue"
import Ward from "components/data-entry/views/Ward.vue"
import Speciality from "components/data-entry/views/Speciality.vue"
import Syndrome from "components/data-entry/views/Syndrome.vue"
import RapidAssessment from "components/rapid-assessment/RapidAssessment.vue"

import DataVizShell from 'components/data-viz/Shell.vue'
import Charts from 'components/data-viz/v2/Charts.vue'
import Operations from 'components/data-viz/v2/Operations.vue'
import Hyperacutes from 'components/breakdowns/Hyperacutes.vue'
/*
const DataVizShell = () => import('components/data-viz/Shell.vue')
const Charts = () => import ('components/data-viz/v2/Charts.vue')
const Operations = () => import ('components/data-viz/v2/Operations.vue')
*/

import DashboardCurrent from "components/dashboards/ViewCurrent.vue"
import DashboardStroke from "components/dashboards/ViewStroke.vue"
import DashboardOrtho from 'components/dashboards/ViewOrtho.vue'

import PaddockSearch_v2 from '@misc/PaddockSearch_v2.vue'
import PaddockSearch_v3 from '@misc/PaddockSearch_v3.vue'

import AuditTrail from 'components/history/AuditTrail.vue'
import DiagnosisHistory from 'components/history/DiagnosisHistory.vue'

import Admin from '@admin/Admin.vue'

import AsapChart from 'components/metrics/ASAPUsageChart.vue'
import UsageActivity from 'components/metrics/UsageActivity.vue'
// const AsapChart = () => import('components/metrics/ASAPUsageChart.vue')
// const UsageActivity = () => import('components/metrics/UsageActivity.vue')

import adminRoutes from './admin'

import StayAuditMockup from 'components/mockups/StayAudit.vue'
import UserAuditMockup from 'components/mockups/UserAudit.vue'

const routes: RouteConfig[] = [
    {
        path: '/standalone',
        component: Reload
    },
    {
        path: '/standalone*',
        component: Reload
    },
    {
        name: "login",
        path: "/login",
        component: Login
    },
    {
        path: '/home',
        alias: "/",
        component: Home,
        name: 'home'
    },
    {
        path: "/dashboards/current",
        name: "overview-dashboard",
        component: DashboardCurrent
    },
    {
        path: "/dashboards/stroke",
        name: "stroke-dashboard",
        component: DashboardStroke,
    },
    {
        path: "/dashboards/ortho",
        name: "ortho-dashboard",
        component: DashboardOrtho,
    },
    {
        // This route is separated from the other DataEntry to
        // allow access to the `rapidAssessment` prop
        path: "/data-entry/:stay_id/rapid-asmt",
        component: DataEntry,
        children: [
            {
                path: "",
                component: RapidAssessment,
                name: ASMT_NURSE
            },
            {
                path: "sp",
                component: Speciality,
                name: ASMT_NURSE_SPECIALITY,
            },
            {
                path: "sp/:speciality_id/syn",
                component: Syndrome,
                props: (route) => {
                    return {
                        speciality_id: +route.params.speciality_id
                    }
                },
                name: ASMT_NURSE_SYNDROME
            },
        ],
        props: (route) => ({
            stay_id: +route.params.stay_id,
            viewMode: ViewMode.rapid_asmt,
        })
    },
    {
        path: "/data-entry/:stay_id",
        component: DataEntry,
        children: [
            {
                path: "sp",
                component: Speciality,
                name: ASMT_SPECIALITY
            },
            {
                path: "sp/:speciality_id/syn",
                component: Syndrome,
                name: ASMT_SYNDROME,
                props: (route) => {
                    return { speciality_id: +route.params.speciality_id }
                }
            },
            {
                path: "adm/:tab?",
                component: Admission,
                props: (route) => {
                    return { tab: route.params.tab || "" }
                },
                name: ASMT_ADM
            },
            {
                path: "ward/:tab?",
                component: Ward,
                props: (route: any) => {
                    return { tab: route.params.tab || "" }
                },
                name: ASMT_WARD
            },
            {
                path: "mdt",
                component: MDT,
                name: ASMT_MDT
            }
        ],
        props: (route: any) => {
            return { stay_id: +route.params.stay_id }
        }
    },
    {
        path: "/edit-stay/:stay_id",
        component: EditStay,
        name: "edit-stay",
        props: (route: any) => {
            return { stay_id: +route.params.stay_id }
        }
    },
    {
        path: "/data",
        name: "data",
        component: DataExport
    },
    {
        path: '/viz',
        component: DataVizShell,
        children: [
            {
                path: 'charts/:start?/:end?',
                name: 'viz-charts',
                component: Charts,
                props: true
            },
            {
                path: 'ops/:start?/:end?',
                name: 'viz-ops',
                component: Operations,
                props: true
            },
        ]
    },
    {
        path: '/breakdown/hyperacutes',
        name: 'breakdown-hyperacutes',
        component: Hyperacutes,
    },
    {
        path: '/search-v2',
        name: 'paddock-search-v2',
        component: PaddockSearch_v2
    },
    {
        path: '/search-v3',
        name: 'paddock-search-v3',
        component: PaddockSearch_v3
    },
    {
        path: '/audit-trail/:stay_id',
        name: 'stay-audit-trail',
        component: AuditTrail,
        props: (route: any) => {
            return {
                stay_id: +route.params.stay_id
            }
        }
    },
    {
        path: '/diagnosis-history/:stay_id',
        name: 'stay-diagnosis-history',
        component: DiagnosisHistory,
        props: (route: any) => {
            return {
                stay_id: +route.params.stay_id
            }
        }
    },
    {
        path: '/admin',
        name: 'admin',
        component: Admin,
        children: adminRoutes
    },
    {
        path: '/metrics/usage/users-activity',
        name: 'metrics-users-usage-activity',
        component: UsageActivity,
    },
    {
        path: '/metrics/asap-usage',
        name: 'metrics-asap-usage',
        component: AsapChart,
    },
    {
        path: '/fnt-a-icons',
        name: 'fnt-a-icons',
        component: FontAwesome,
    },
    {
        path: '*',
        name: 'not-found',
        component: NotFound
    },
    {
        path:'/mockups/audit/stay',
        name: 'mockups-audit-stay',
        component: StayAuditMockup
    },
    {
        path:'/mockups/audit/user',
        name: 'mockups-audit-user',
        component: UserAuditMockup
    },
]

/** NOTE If still having issues with router in IE, look at:
 * - https://github.com/vuejs/vue-router/issues/1849
 * - https://codepen.io/gapcode/pen/vEJNZN
 */
const router = new VueRouter({ routes })

router.beforeEach((to, from, next) => {
    const dispatch = store.direct.dispatch
    const getters = store.direct.getters
    const parser = new urlparse(document.URL, true)
    const query = parser.query

    /** Derives the proper param string to remove from the URL */
    function paramToReplace(prop: string): string {
        if (query[prop]) {
            const onlyOne = Object.keys(query).length === 1
            const prefix = onlyOne ? '?' : ''
            const suffix = onlyOne ? '' : '&'
            return `${prefix}${prop}=${query[prop]}${suffix}`
        }
        return ''
    }

    if (query.token) {
        dispatch.session.tokenLogin(query.token)
        .then(res => {
            history.replaceState(null, document.title, location.href.replace(paramToReplace('token'), ''))
            next({ name: 'home' })
        })
        .catch(err => {
            utils.handleRequestError(err)
            next()
        })
    }
    else if (query.next) {
        history.replaceState(null, document.title, location.href.replace(paramToReplace('next'), ''))
        next(query.next)
    }
    else {
        if (startsWith(to.path, '/standalone')) {
            next()
            return
        }

        const p = getters.session.loggedIn ? Promise.resolve() : dispatch.session.knock()
        p.then(res => {
            if (getters.session.loggedIn) {
                if (to.name === 'login') {
                    next({ name: 'home' })
                    return
                }
            }
            else {
                if (to.name !== 'login') {
                    next(`/login?next=${to.fullPath}`)
                    return
                }
            }
            next()
        })
        .catch(err => {
            utils.handleRequestError(err)
            next()
        })
    }
})

export default router
