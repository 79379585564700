



















































































































































import mixins from 'vue-typed-mixins'
import DisplayMixin from '@mixins/Display.vue'
import { clone, filter, includes, isNil } from 'lodash-es'

import utils from 'utils'
import stays from '@store/stays'
import { OrderedQuestion, Question, QuestionChoice, QuestionType } from 'models/med_templates/question'
import { Stay } from 'models/data/stay'
import { freeTextSubHtmlBreaks } from 'utils/text/helpers'
import DateTimePicker from '@shared/inputs/DateTimePicker.vue'
import TextArea from '@shared/inputs/TextArea.vue'

// TODO $emit('blur') for all question types
// TODO set autofocus setting for all question types
export default mixins(DisplayMixin).extend({
    components: {
        DateTimePicker,
        TextArea,
    },
    props: {
        showLabel: {
            type: Boolean,
            default: true,
        },
        autofocus: {
            type: Boolean,
            default: false,
        },
        indentSub: {
            type: Boolean,
            default: false
        },
        /** the OrderedQuestion parent */
        oq: {
            type: Object as () => OrderedQuestion,
            required: false
        },
        question: {
            type: Object as () => Question,
            required: true
        },
        stay_id: {
            type: Number,
            required: true
        },
        altHelpText: {
            type: String,
            default: '',
            required: false,
        },
        inline: {
            type: Boolean,
            default: true,
        },
        getAnswer: {
            type: Function,
            required: false,
        },
        setAnswer: {
            type: Function,
            required: false,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            htmlIds: {
                notes: `${uid}___q_${this.question.id}_notes`,
            },
            QuestionType,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        show(): boolean {
            return !this.question.hide
                || (this.answerIsArray && this.answer.length > 0)
                || (!this.answerIsArray && !isNil(this.answer))
        },
        inputHtmlId(): string {
            return `${this.uid}___s_${this.stay_id}_q_${this.question.id}`
        },
        doIndent(): boolean {
            return this.indentSub && (this.oq && !!this.oq.show_if)
        },
        booleanChoices(): { title: string, value: boolean }[] {
            return this.question.show_yes_only ?
            [ { title: 'Yes', value: true } ]
            : [
                { title: 'No', value: false },
                { title: 'Yes', value: true },
            ]
        },
        answer: {
            get(): string | boolean | number | number[] | null | undefined {
                let answer
                if (this.getAnswer) {
                    answer = this.getAnswer(this.stay_id, this.question.id)
                    if (answer)
                        answer = answer.answer
                } else {
                    answer = stays.getAnswer(this.stay_id, this.question.id)
                }

                if (!answer && this.question.type === QuestionType.LIST) return []

                return answer
            },
            set(answer: any) {
                // Set empty strings to `null` to avoid issue where clearing a number set it equal to ''
                if (answer === '' || (Array.isArray(answer) && !answer.length)) answer = null
                if (this.setAnswer) {
                    this.setAnswer(this.stay_id, this.question.id, { answer })
                } else {
                    stays.setAnswer(this.stay_id, this.question.id, answer)
                }
                this.$emit('update')
            },
        },
        answerIsArray(): boolean {
            return Array.isArray(this.answer)
        },
        notes: {
            get(): string | null {
                if (this.getAnswer) {
                    const answer = this.getAnswer(this.stay_id, this.question.id)
                    return answer ? answer.notes : null
                } else {
                    return stays.getAnswerNotes(this.stay_id, this.question.id)
                }
            },
            set(text: string) {
                if (this.setAnswer) {
                    this.setAnswer(this.stay_id, this.question.id, { notes: text })
                } else {
                    stays.setAnswerNotes(this.stay_id, this.question.id, text)
                }
            }
        },
        notesHTML(): string {
            return this.notes ? freeTextSubHtmlBreaks(this.notes) : ''
        },
        helpText(): string | null {
            return this.altHelpText || this.question.help_text
        },
        stackedChoices(): boolean {
            if (this.question.stacked === null)
                return this.$store.direct.state.session.choicesDisplay === 'per_line'
            return this.question.stacked
        },
        showNotes(): boolean {
            return this.question.allow_notes
        },
        /** text output is visible on dashboard */
        onDashboard(): boolean {
            return this.stay ? !includes(this.stay.dash_hide_qs, this.question.id) : true
        },
        dIconClasses(): string {
            return this.onDashboard ? 'text-info' : ''
        },
        dIconHelpText(): string {
            return this.onDashboard ?
                'Showing output on dashboard. Click to hide.'
                : 'Hiding output on dashboard. Click to show.'
        },
    },
    inject: {
        showDashboardToggle: {
            default: false,
        },
    },
    methods: {
        selectInputNumber(): void {
            const el: any = document.getElementById(this.inputHtmlId)
            if (el) {
                el.select()
                // el.setSelectionRange(0, el.value.length)
            }
        },
        toggleDashboard(): void {
            if (!this.stay)
                return

            if (includes(this.stay.dash_hide_qs, this.question.id)) {
                const qs = filter(this.stay.dash_hide_qs, q => q != this.question.id)
                stays.extActSetPathValue(this.stay_id, 'dash_hide_qs', qs)
            }
            else {
                const qs = clone(this.stay.dash_hide_qs)
                qs.push(this.question.id)
                stays.extActSetPathValue(this.stay_id, 'dash_hide_qs', qs)
            }
        },
        showChoice(choice: QuestionChoice): boolean {
            // We show a choice if:
            //   - `choice.hide === false` or
            //   - the choice has previously been selected
            if (!choice.hide) return true

            const answer = this.answer
            let choiceSelected = false
            if (this.answerIsArray) {
                choiceSelected = includes(answer, choice.id)
            } else {
                choiceSelected = answer === choice.id
            }
            return choiceSelected
        },
    },
})
