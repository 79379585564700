








import Vue from 'vue'
import { Stay } from "models/data/stay"
import stays from '@store/stays'
import text, { PBR, SECTION_START } from "utils/text"
import { background, socialHistory } from 'utils/text/clinic_history'
import {
    diagnosisWithSummaries,
    diagnosisWithSummariesPlain
} from "utils/text/diagnosis"
import {
    baselineInvestigations,
    completedInvestigationsSummary,
    outstandingInvestigations
} from 'utils/text/investigation'
import {
    activeIssuesWithSummaryText,
    resolvedIssuesWithDetail,
} from "utils/text/issue"
import { dischargePlanFinal, medsChanged } from 'utils/text/plan'
// import { dischargeMarkup } from 'utils/text/discharge'
import TextBase from "../TextBase.vue"


export default Vue.extend({
    components: {
        TextBase,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        usePlain(): boolean {
            return this.$store.direct.getters.user.textOutputPlain
        },
        /*
        markup_old(): string {
            return dischargeMarkup(this.stay, this.$store.direct.state.user.anonymise)
        },
        */
        markup(): string {
            if (!this.stay) return ''

            const stay = this.stay
            const plain = this.usePlain
            const textDetails = {
                stay,
                anonymise: this.$store.direct.state.user.anonymise,
                plain,
                noInfo: true,
            }

            let markup = SECTION_START
            markup += "Discharge Summary  \n"
            markup += `${text.patientDetails(textDetails)}`
            markup += PBR

            const admissionSummary = text.fieldText(textDetails, 'admission_summary')
            if (admissionSummary.length)
                markup += admissionSummary + PBR

            // markup += text.primaryDiagnosisWithKeyPoints(textDetails)
            markup += SECTION_START
            markup += plain
                ? diagnosisWithSummariesPlain(textDetails)
                : diagnosisWithSummaries(textDetails, undefined, true)
            markup += PBR

            markup += activeIssuesWithSummaryText(textDetails, plain)
            markup += PBR

            const resolvedIssuesText = resolvedIssuesWithDetail(textDetails)
            if (resolvedIssuesText)
                markup += resolvedIssuesText + PBR

            markup += SECTION_START
            markup += background(textDetails) + PBR
            markup += socialHistory(textDetails) + PBR

            markup += SECTION_START
            markup += baselineInvestigations({
                stay,
                title: "Summary baseline investigations (Formal reports below)",
            }, plain)
            markup += PBR
            markup += completedInvestigationsSummary({
                stay,
                title: "Summary inpatient investigations (Formal reports below)",
                plain
            })
            markup += PBR
            markup += outstandingInvestigations(textDetails)
            markup += PBR

            markup += SECTION_START
            markup += medsChanged(textDetails)
            markup += PBR

            markup += SECTION_START
            markup += dischargePlanFinal(textDetails)
            markup += PBR

            return markup
        },
    },
})
