








































import mixins from 'vue-typed-mixins'
import { orderBy } from 'lodash-es'

import utils from 'utils'
import { safeDecoding } from 'utils/store'
import stays from '@store/stays'
import { SidebarStay, sidebarStaysDecoder } from 'models/data/sidebar'
import { ViewMode } from 'models/meta'

import StaysFilterMixin from '@shared/StaysFilterMixin.vue'

import Loading from '@home/Loading.vue'
import Stays from "./SidebarStays.vue"
import ShowDischarged from '@shared/ShowDischarged.vue'
import StayFilters from "@shared/StayFilters.vue"

export default mixins(StaysFilterMixin).extend({
    components: {
        Loading,
        Stays,
        ShowDischarged,
        StayFilters,
    },
    props: {
        viewMode: {
            required: false,
            default: ViewMode.normal,
        },
    },
    data() {
        return {
            collapseId: `${utils.getUID()}___sb-filters`,
            stays: [] as SidebarStay[],
            filtersVisible: false,
        }
    },
    mounted() {
        // to eliminate "No Stays" flashing on load due to debounced pull
        stays.mutMarkStaysLoading()
        stays.mutTriggerLoadStays()
    },
    computed: {
        fullMode(): boolean {
            return this.$store.direct.getters.user.fullMode
        },
    },
    methods: {
        $_pull(): void {
            stays.mutMarkStaysLoading()
            stays.actPullStays({ url: '/stay/sidebar/', params: this.params, useStore: false })
            .then(response => {
                
                if (response) {
                    const stays = safeDecoding(response.body, sidebarStaysDecoder, 'SideBar.$_pull')
                    this.stays = orderBy(stays, ['location.ward', 'location.bed'])
                }
                stays.mutMarkStaysLoaded()
                return Promise.resolve('ok')
            })
        },
    },
})
