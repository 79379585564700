import { each, find, filter, includes, map } from 'lodash-es'
import { Attendees } from 'models/data/team'
import { TeamDoctor, TeamMember } from 'models/med_templates/team'
import { TextDetails } from './interfaces'
import store from 'store'

/**
 * 
 * @param param0 
 * @param section 
 * @param prefix 
 * @param hideEmpty Return an empty string if there are no attendees
 */
export function attending(
    { stay }: TextDetails,
    section: 'adm' | 'ward',
    plain: boolean = false,
): string {
    if (!stay) return ''

    let attendees: Attendees | undefined
    switch (section) {
        case 'adm':
            attendees = stay.adm_present
            break
        case 'ward':
            attendees = stay.ward_round
            break
        default:
            break
    }

    if (!attendees) return ''

    const everyone: string[] = []

    const teamID = attendees.team
    const doctorIDs = attendees.doctors_present
    const memberIDs = attendees.members_present
    const peopleIDs = attendees.people_present

    const speciality = store.direct.getters.templates.speciality(stay.speciality)
    const team = find(store.direct.state.templates.teams, ['id', teamID])

    const finalDoctors: TeamDoctor[] = []
    const finalMembers: TeamMember[] = []
    let finalDoctorIDs: number[] = [], finalMemberIDs: number[] = []

    // if a valid team is selected, list doctors/members in the same order as they were presented
    if (speciality && team && team.speciality === speciality.id) {
        const doctorsFromTeam = filter(team.doctors, doctor => includes(doctorIDs, doctor.id))
        finalDoctors.push(...doctorsFromTeam)
        finalDoctorIDs = map(finalDoctors, 'id')

        const membersFromTeam = filter(team.members, member => includes(memberIDs, member.id))
        finalMembers.push(...membersFromTeam)
        finalMemberIDs = map(finalMembers, 'id')
    }

    // add any remaining doctors/members that have been selected but is not part of the team above
    each(doctorIDs, doctorID => {
        if (includes(finalDoctorIDs, doctorID))
            return
        const teamDoctor = store.direct.getters.templates.allTeamDoctors[doctorID]
        if (teamDoctor)
            finalDoctors.push(teamDoctor)
    })

    each(memberIDs, memberID => {
        if (includes(finalMemberIDs, memberID))
            return
        const teamMember = store.direct.getters.templates.allTeamMembers[memberID]
        if (teamMember)
            finalMembers.push(teamMember)
    })

    each(finalDoctors, doctor => { everyone.push(doctor.title) })
    each(finalMembers, member => { everyone.push(member.title) })

    if (speciality) {
        const allPeople = speciality.people_present
        const people = allPeople.filter(person => includes(peopleIDs, person.id))
        const peopleTitles = people.map(person => person.title)
        everyone.push(...peopleTitles)
    }

    if (everyone.length === 0) {
        return plain ? '[No attendees]' : '*No attendees*'
    }

    return everyone.join(', ')
}
