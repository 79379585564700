import text, { PBR } from 'utils/text'
import { background, socialHistory } from 'utils/text/clinic_history'
import { diagnosisSummary } from 'utils/text/diagnosis'
import { baselineInvestigations, completedInvestigationsSummary, outstandingInvestigations } from 'utils/text/investigation'
import { activeIssuesWithSummaryText, resolvedIssuesWithDetail } from 'utils/text/issue'
import { dischargePlanFinal } from 'utils/text/plan'
import { Stay } from 'models/data/stay'

export function dischargeMarkup(stay: Stay | undefined, anonymise: boolean) {
    if (!stay) return ''

    let markup = ''
    const textDetails = { stay, anonymise }

    markup += 'Patient details: ' + text.patientDetails(textDetails) + PBR

    const admissionSummary = text.fieldText(textDetails, 'admission_summary')
    if (admissionSummary.length) markup += admissionSummary + PBR

    markup += text.primaryDiagnosisWithKeyPoints(textDetails)

    markup += diagnosisSummary(textDetails) + PBR
    markup += activeIssuesWithSummaryText({ stay, title: "Issues during admission" }) + PBR
    markup += resolvedIssuesWithDetail(textDetails)
    markup += background(textDetails) + PBR
    markup += socialHistory(textDetails) + PBR

    markup += baselineInvestigations({
        stay,
        title: "Summary baseline investigations (Formal reports below)"
    }) + PBR
    markup += completedInvestigationsSummary({
        stay,
        title: "Summary inpatient investigations (Formal reports below)"
    }) + PBR

    markup += outstandingInvestigations(textDetails)

    if (stay.discharge) {
        const meds_changed: string = stay.discharge.meds_changed
        if (meds_changed && meds_changed.length > 0)
            markup += `**Medications changed**  \n${text.freeTextSubHtmlBreaks(meds_changed)}\n\n`
    }
    markup += dischargePlanFinal(textDetails)

    return markup
}
