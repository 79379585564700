










































































































import Vue from "vue"
import { Location } from "vue-router"

import utils from 'utils'
import text from 'utils/text'
import { ASMT_ADM, ASMT_MDT, ASMT_NURSE_SPECIALITY, ASMT_SPECIALITY, ASMT_WARD } from 'routers/constants'
import { getABFScore } from 'utils/abf'
import { find, findIndex, includes, lowerCase, upperFirst } from "lodash-es"
import stays from "@store/stays"

import { Stay } from 'models/data/stay'
import { Patient, isPatient, anonymisedPatient, dummyPatient } from "models/data/patient"
import { Speciality } from 'models/med_templates/speciality'
import { Syndrome } from "models/med_templates/syndrome"
import { ViewMode } from 'models/meta'

import Attending from "@shared/stay-inputs/Attending.vue"
import Dropdown from '@shared/inputs/Dropdown.vue'
import NeuroAcuteCareSummary from 'components/data-entry/views/NeuroAcuteCareSummaryModal.vue'
import PatientModal from '@stayinputs/PatientModal.vue'
import SurgeryTime from '@stayinputs/SurgeryTime.vue'
import TextNihssSelect from "components/text/NIHSSSelect.vue"


export default Vue.extend({
    components: {
        Attending,
        Dropdown,
        NeuroAcuteCareSummary,
        PatientModal,
        SurgeryTime,
        TextNihssSelect,
    },
    props: {
        viewMode: {
            required: false,
            default: ViewMode.normal,
        },
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()
        return {
            cycleLayoutId: `${uid}___cycle_layout`,
            surgeryModalId: `${uid}___surgery_${this.stay_id}`,
            patientModalId: '',
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isDeveloper(): boolean {
            return this.$store.direct.getters.user.isDeveloper
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        anonymise(): boolean {
            return this.$store.direct.state.user.anonymise
        },
        hasPasFeed(): boolean {
            return this.$store.direct.state.session.hasPasFeed
        },
        normalMode(): boolean {
            return this.viewMode === ViewMode.normal
        },
        rapidAsmtMode(): boolean {
             return this.viewMode === ViewMode.rapid_asmt
        },
        changeSyndromeLink(): Location {
            const stay_id = String(this.stay_id)

            const name = this.normalMode ? ASMT_SPECIALITY : ASMT_NURSE_SPECIALITY
            return { name, params: { stay_id } }
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        isConsult(): boolean {
            return !!this.stay && !!this.stay.primary
        },
        stage(): string {
            return this.stay ? upperFirst(lowerCase(this.stay.stage)) : "?"
        },
        syndrome(): Syndrome | undefined {
            if (!this.stay)
                return
            if (this.rapidAsmtMode)
                return find(this.$store.direct.getters.templates.rapidAsmtSyndromes, ['id', this.stay.rapid_assessment_syndrome])
            else
                return find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome])
        },
        syndromeText(): string {
            if (!this.syndrome) return '?'
            return this.syndrome.title || '?'
        },
        syndromeLink(): string | null {
            if (!this.syndrome) return null
            return this.syndrome.link
        },
        syndromeLinkText(): string | null {
            if (!this.syndrome) return null
            return this.syndrome.link_text || 'Link'
        },
        speciality(): Speciality | undefined {
            const specialityId = this.syndrome ? this.syndrome.speciality : undefined
            if (specialityId)
                return find(this.$store.direct.state.templates.specialities, { id: specialityId })
            return
        },
        showSurgery(): boolean {
            return this.speciality ? this.speciality.has_surgery : false
        },
        surgeryTime: {
            get(): string | null {
                return this.stay ? this.stay.surgery_time : null
            },
            set(val: string) {
                stays.actSetPathValue({ id: this.stay_id, path: 'surgery_time', val })
            }
        },
        postOpDay(): number | string {
            if (!this.stay) return '?'
            return text.podText({ stay: this.stay, hideTitle: true })
        },
        patient(): Patient {
            if (this.stay && isPatient(this.stay.patient))
                return this.anonymise ? anonymisedPatient(this.stay.patient) : this.stay.patient
            return dummyPatient()
        },
        choicesDisplayWrap(): boolean {
            return this.$store.direct.state.session.choicesDisplay === 'wrap'
        },
        abfScore(): number {
            return getABFScore(this.stay_id)
        },
        altViews(): { label: string, route: string, visible: boolean, disabled: boolean }[] {
            const routeName = this.$route.name
            const views = [
                { label: 'Admission', route: ASMT_ADM, visible: true, disabled: routeName === ASMT_ADM,  },
                { label: 'Review', route: ASMT_WARD, visible: true, disabled: routeName === ASMT_WARD, },
                { label: 'MDT', route: ASMT_MDT, visible: !this.telestrokeMode, disabled: routeName === ASMT_MDT, },
                // { label: 'Nurse Review', route: ASMT_NURSE, disabled: routeName === ASMT_NURSE, },
            ]
            return views // filter(views, info => info.route !== this.$route.name)
        },
        syncMonitorInterval(): number {
            return this.$store.direct.state.session.syncMonitorInterval
        },
        savePending(): boolean {
            return stays.state.stayChanged
        },
        sidebarDocked(): boolean {
            return this.$store.direct.state.user.asmtSidebarDocked
        },
        textDocked(): boolean {
            return this.$store.direct.state.user.asmtTextDocked
        },
        asmtVisible(): boolean {
            return this.$store.direct.state.session.asmtVisible
        },
        nihssV2(): boolean {
            return this.$store.direct.state.session.nihss_version === "v2"
        },
        showNihssSelect(): boolean {
            return this.nihssV2 && this.$route.name === ASMT_WARD
        },
        showAttending(): boolean {
            return includes([ASMT_ADM, ASMT_WARD], this.$route.name)
        },
        attendingSection(): string {
            switch (this.$route.name) {
                case ASMT_ADM:
                    return 'adm_present'
                case ASMT_WARD:
                    return 'ward_round'
                default:
                    return ''
            }
        },
        RN_recentNotes() {
            return this.$store.direct.state.session.RN_recentNotes
        },
        RN_selected: {
            get(): string {
                return this.$store.direct.state.session.RN_selected
            },
            set(id: string) {
                this.$store.direct.commit.session.RN_selected(id)
            }
        },
    },
    watch: {
        isTempUser: {
            handler: function(val: boolean) {
                if (val)
                    this.setNotesOnly()
            },
            immediate: true
        },
    },
    methods: {
        refreshData() {
            stays.actPullStay(this.stay_id)
        },
        cycleChoicesDisplay() {
            this.$store.direct.commit.session.cycleChoicesDisplay()
        },
        setDockedStates(sidebar: boolean, asmt: boolean, text: boolean): void {
            this.$store.direct.commit.session.setAsmtVisible(asmt)
            this.$store.direct.dispatch.user.updatePush({ asmtSidebarDocked: sidebar, asmtTextDocked: text })
        },
        cycleDockedStates(): void {
            // docked: [sidebar, asmt, text]
            const allStates = [
                // [true, false],
                // [false, false],
                [false, true, true],
                [true, true, true],
                [false, false, true],
            ]
            const i = findIndex(allStates, state => {
                return (state[0] === this.sidebarDocked)
                    && (state[1] === this.asmtVisible)
                    && (state[2] === this.textDocked)
            })
            const nextI = (i+1) % allStates.length
            this.setDockedStates(allStates[nextI][0], allStates[nextI][1], allStates[nextI][2])
        },
        setNotesOnly(): void {
            this.setDockedStates(false, false, true)
        },
        goToRoute(name: string) {
            this.$router.push({ name, params: { stay_id: `${this.stay_id}` } })
        }
    },
})
