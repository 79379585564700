














































































import Vue from 'vue'
import { includes, map } from 'lodash-es'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'

import Background from '@sections/Background.vue'
import CardLapsible from '@shared/CardLapsible.vue'
import CardLapsibleV2 from '@shared/CardLapsible_v2.vue'
import IssuesOverview from '@sections/issues/IssuesOverview.vue'
import MedicalHistory from '@admission/MedicalHistory.vue'
import NeuroSummary from 'components/dashboards/columns/NeuroSummary.vue'
import PatientDoctorDiscussion from './PatientDoctorDiscussion.vue'
import PrimaryIssue from './PrimaryIssue.vue'
import RapidAsmtDiagnosis from '@inputs/RapidAsmtDiagnosis.vue'
import Team from '@sections/Team.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'
import WardExam from './WardExam.vue'

export default Vue.extend({
    components: {
        Background,
        CardLapsible,
        CardLapsibleV2,
        IssuesOverview,
        MedicalHistory,
        NeuroSummary,
        PatientDoctorDiscussion,
        PrimaryIssue,
        RapidAsmtDiagnosis,
        Team,
        TextAreaEnhanced,
        WardExam
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            issuesOpened: false,
        }
    },
    computed: {
        isNurseUser(): boolean {
            return this.$store.direct.getters.user.isNurseUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        hasStrokeDiagnosis(): boolean {
            return includes(this.$store.direct.state.templates.strokeDiagnoses, this.stay?.diagnosis_title)
        },
        issuesList(): string [] {
            const activeIssues = stays.getActiveIssues(this.stay_id)
            return map(activeIssues, 'title')
        },
        planLabel(): string {
            return this.isNurseUser ? 'Plan - Doctor' : 'Plan'
        },
    },
})
