







import mixins from "vue-typed-mixins"
import { each, every, get } from 'lodash-es'
import { LayoutGroupExt, PropExtInfo } from "models"
import { DisplayLookup, LayoutLookup } from 'models/layout'
import { AnyPropLookup } from "models/schema"
import { FullSchema } from 'models/schema/stay'

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Generic from "@sections/Generic.vue"

const displayInfo: DisplayLookup = {
    sensations: [
        {
            field: "sensory_loss_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_loss_left",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_left",
            dropdown: true,
            full_width: true
        }
    ]
}

const layout: LayoutLookup = {
    sensations: [
        { label: "Sensory Exam (Pinprick)", fields: ["sensory_loss_right", "sensory_loss_left"], alignment: "inline" },
        { label: "Sensory Inattention", fields: ["sensory_inattention_right", "sensory_inattention_left"], alignment: "inline" }
    ]
}

const fieldNames = ['sensory_loss_right', 'sensory_loss_left', 'sensory_inattention_right', 'sensory_inattention_left']

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Generic
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        nihss_id: {
            type: Number,
            required: true,
        },
    },
    data() {
        const section: keyof FullSchema = 'nihss_set'
        return {
            section,
            fieldNames,
        }
    },
    computed: {
        schema(): AnyPropLookup | undefined {
            return this.getChildSchema(this.section)
        },
        sensationFields(): PropExtInfo[] {
            return this.schema
                ? this.makePropExtInfo_NIHSS(this.schema, displayInfo.sensations, this.nihss_id)
                : []
        },
        sensationGroups(): LayoutGroupExt[] | undefined {
            return this.makeGroups_NIHSS(this.sensationFields, layout.sensations, this.nihss_id)
        },
    },
    beforeCreate() {
        const self = this

        each(fieldNames, target => {
            const computed: any = {
                get() {
                    return this.getArrayedObjVal(this.stay_id, 'nihss_set', this.nihss_id, target)
                }
            }
            self.$options.computed![target] = computed
        })

        self.$options.computed!['allNormal'] = {
            get(): boolean {
                const sensationFields = get(self, 'sensationFields')
                const stay_id = get(self, 'stay_id')
                const nihss_id = get(self, 'nihss_id')
                return every(fieldNames, target => {
                    const res = self.getPropAndFirstChoice(sensationFields, target)
                    const choice = res[1]
                    const val = self.getArrayedObjVal(stay_id, 'nihss_set', nihss_id, target)
                    const sameChoice = choice ? choice.value === val : false
                    return sameChoice
                })
            },
            set(val: boolean) {
                if (val) {
                    const sensationFields = get(self, 'sensationFields')
                    const stay_id = get(self, 'stay_id')
                    const nihss_id = get(self, 'nihss_id')
                    each(fieldNames, target => {
                        const res = self.getPropAndFirstChoice(sensationFields, target)
                        const choice = res[1]
                        const val = self.getArrayedObjVal(stay_id, 'nihss_set', nihss_id, target)
                        if (val !== choice?.value)
                            self.setArrayedObjVal(stay_id, 'nihss_set', nihss_id, target, choice?.value)
                    })
                }
            }
        }
    },
})
