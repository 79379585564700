var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-form-group",
    {
      staticClass: "scrwl-dd scrollable",
      attrs: { "label-cols-sm": _vm.labelCols, "label-class": "pb-0" },
      scopedSlots: _vm._u(
        [
          !_vm.hide_label
            ? {
                key: "label",
                fn: function() {
                  return [_c("span", [_vm._v(_vm._s(_vm.label))])]
                },
                proxy: true
              }
            : null
        ],
        null,
        true
      )
    },
    [
      _vm._v(" "),
      _c(
        "b-dd",
        {
          class: _vm.extraClasses,
          attrs: {
            variant: "primary",
            "toggle-class": _vm.toggleClasses,
            text: _vm.selectedText
          }
        },
        [
          _vm._l(_vm.specialities, function(speciality) {
            return [
              _c("b-dd-text", { key: "sp_" + speciality.id }, [
                _c("em", [_vm._v(_vm._s(speciality.title))])
              ]),
              _vm._v(" "),
              _vm._l(speciality.syndromes, function(syndrome) {
                return syndrome.diagnoses.length
                  ? [
                      _c(
                        "b-dd-text",
                        { key: "sy_" + syndrome.id, staticClass: "ml-1" },
                        [_c("em", [_vm._v(_vm._s(syndrome.title))])]
                      ),
                      _vm._v(" "),
                      _vm._l(syndrome.diagnoses, function(diagnosis) {
                        return _c(
                          "b-dd-item-btn",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value:
                                  !diagnosis.hide ||
                                  _vm.isSelected(diagnosis.id),
                                expression:
                                  "!diagnosis.hide || isSelected(diagnosis.id)"
                              }
                            ],
                            key: "dg_" + diagnosis.id,
                            staticClass: "ml-3",
                            class: { active: _vm.isSelected(diagnosis.id) },
                            on: {
                              click: function($event) {
                                return _vm.toggleSelect(diagnosis.id)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(diagnosis.alt_title || diagnosis.title) +
                                "\n                    "
                            ),
                            _vm.isSelected(diagnosis.id)
                              ? _c("fnt-a-icon", {
                                  attrs: { icon: ["fal", "check"] }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      })
                    ]
                  : _vm._e()
              }),
              _vm._v(" "),
              _c("b-dd-divider")
            ]
          }),
          _vm._v(" "),
          _c(
            "b-dd-item-btn",
            {
              class: { active: _vm.isSelected(_vm.otherDiagnosis.id) },
              on: {
                click: function($event) {
                  return _vm.toggleSelect(_vm.otherDiagnosis.id)
                }
              }
            },
            [
              _vm._v(
                "\n            " +
                  _vm._s(_vm.otherDiagnosis.title) +
                  "\n            "
              ),
              _vm.isSelected(_vm.otherDiagnosis.id)
                ? _c("fnt-a-icon", { attrs: { icon: ["fal", "check"] } })
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }