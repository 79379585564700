var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isAllowed
    ? _c(
        "div",
        [
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "b-modal",
                  rawName: "v-b-modal",
                  value: _vm.htmlIDs.createModal,
                  expression: "htmlIDs.createModal"
                }
              ],
              attrs: { variant: "success" }
            },
            [_vm._v("\n        Add Stay\n    ")]
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.htmlIDs.createModal,
                title: "New Stay",
                "ok-title": "Create Stay",
                "ok-variant": "success"
              },
              on: {
                ok: _vm.createStay,
                shown: _vm.focusFirstInput,
                hidden: _vm.resetForm
              }
            },
            [
              _c(
                "b-form",
                {
                  attrs: { id: _vm.htmlIDs.main },
                  on: {
                    submit: function($event) {
                      $event.preventDefault()
                      return _vm.createStay.apply(null, arguments)
                    }
                  }
                },
                [
                  _vm.isDeveloper
                    ? _c(
                        "b-form-group",
                        {
                          attrs: {
                            label: "Visit # (optional)",
                            "label-for": _vm.htmlIDs.visit
                          }
                        },
                        [
                          _c("b-form-input", {
                            attrs: { id: _vm.htmlIDs.visit, trim: "" },
                            model: {
                              value: _vm.data.ext_id,
                              callback: function($$v) {
                                _vm.$set(_vm.data, "ext_id", $$v)
                              },
                              expression: "data.ext_id"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    { attrs: { label: "MRN", "label-for": _vm.htmlIDs.mrn } },
                    [
                      _c("b-form-input", {
                        class: { "is-invalid": _vm.formError("mrn") },
                        attrs: { id: _vm.htmlIDs.mrn, trim: "" },
                        model: {
                          value: _vm.data.patient.mrn,
                          callback: function($$v) {
                            _vm.$set(_vm.data.patient, "mrn", $$v)
                          },
                          expression: "data.patient.mrn"
                        }
                      }),
                      _vm._v(" "),
                      _c("b-form-invalid-feedback", [
                        _vm._v(
                          "\n                    Enter a valid number for MRN\n                "
                        )
                      ])
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "First name",
                        "label-for": _vm.htmlIDs.fname
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: _vm.htmlIDs.fname, trim: "" },
                        model: {
                          value: _vm.data.patient.first_name,
                          callback: function($$v) {
                            _vm.$set(_vm.data.patient, "first_name", $$v)
                          },
                          expression: "data.patient.first_name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Last name",
                        "label-for": _vm.htmlIDs.lname
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: { id: _vm.htmlIDs.lname, trim: "" },
                        model: {
                          value: _vm.data.patient.last_name,
                          callback: function($$v) {
                            _vm.$set(_vm.data.patient, "last_name", $$v)
                          },
                          expression: "data.patient.last_name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  false
                    ? _c(
                        "b-form-group",
                        {
                          attrs: { label: "Age", "label-for": _vm.htmlIDs.age }
                        },
                        [
                          _c("b-form-input", {
                            class: {
                              "is-invalid": _vm.formError("approx_age")
                            },
                            attrs: { id: _vm.htmlIDs.age, number: "" },
                            model: {
                              value: _vm.data.patient.approx_age,
                              callback: function($$v) {
                                _vm.$set(_vm.data.patient, "approx_age", $$v)
                              },
                              expression: "data.patient.approx_age"
                            }
                          }),
                          _vm._v(" "),
                          _c("b-form-invalid-feedback", [
                            _vm._v(
                              "\n                    Enter a valid number for age\n                "
                            )
                          ])
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-form-group",
                    {
                      attrs: {
                        label: "Date of Birth",
                        "label-for": _vm.htmlIDs.dob
                      }
                    },
                    [
                      _c("date-time-picker", {
                        staticClass: "w-100",
                        attrs: {
                          placeholder: "Enter Date of Birth",
                          config: {
                            altFormat: "d M Y",
                            dateFormat: "Y-m-d",
                            static: true
                          }
                        },
                        model: {
                          value: _vm.data.patient.dob,
                          callback: function($$v) {
                            _vm.$set(_vm.data.patient, "dob", $$v)
                          },
                          expression: "data.patient.dob"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "b-form-invalid-feedback",
                        { class: { "d-block": _vm.formError("dob") } },
                        [
                          _vm._v(
                            "\n                    Enter a date of birth\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.isTSSiteUser
                    ? _c(
                        "b-form-group",
                        [
                          _c(
                            "b-check",
                            {
                              attrs: { readonly: _vm.isTSSiteUser },
                              model: {
                                value: _vm.data.site_only,
                                callback: function($$v) {
                                  _vm.$set(_vm.data, "site_only", $$v)
                                },
                                expression: "data.site_only"
                              }
                            },
                            [_vm._v("For local hospital (non-TSS)")]
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "b-collapse",
                    {
                      attrs: {
                        id: _vm.htmlIDs.doctorCollapse,
                        visible: !_vm.data.site_only
                      }
                    },
                    [
                      _c("dropdown", {
                        class: {
                          "is-invalid": _vm.formError("doctor"),
                          "mb-0": _vm.formError("doctor")
                        },
                        attrs: {
                          label: _vm.doctorDDLabel,
                          items: _vm.allDoctors,
                          textField: "last_name"
                        },
                        model: {
                          value: _vm.data.doctor,
                          callback: function($$v) {
                            _vm.$set(_vm.data, "doctor", $$v)
                          },
                          expression: "data.doctor"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "invalid-feedback",
                          class: { "d-block": _vm.formError("doctor") }
                        },
                        [
                          _vm._v(
                            "\n                    Select a doctor\n                "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("dropdown", {
                    class: {
                      "is-invalid": _vm.formError("hospital"),
                      "mb-0": _vm.formError("hospital")
                    },
                    attrs: {
                      label: _vm.hospitalDDLabel,
                      items: _vm.hospitals,
                      textField: "title"
                    },
                    model: {
                      value: _vm.data.hospital,
                      callback: function($$v) {
                        _vm.$set(_vm.data, "hospital", $$v)
                      },
                      expression: "data.hospital"
                    }
                  }),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "invalid-feedback",
                      class: { "d-block": _vm.formError("hospital") }
                    },
                    [
                      _vm._v(
                        "\n                Select a hospital\n            "
                      )
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "b-btn",
                    {
                      staticClass: "d-none",
                      attrs: { type: "submit", disabled: _vm.submitting }
                    },
                    [_vm._v("Create")]
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.debugMode
                ? _c("div", { staticClass: "col-12" }, [
                    _c("span", { staticClass: "text-monospace" }, [
                      _vm._v(" " + _vm._s(_vm.formJSON))
                    ])
                  ])
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-modal",
            {
              attrs: {
                id: _vm.htmlIDs.multipleModal,
                title: "Active Stay(s) Exist",
                size: "lg",
                "ok-title": "Continue",
                "ok-variant": "success",
                "cancel-variant": "danger"
              },
              on: {
                ok: function($event) {
                  return _vm.setConfirmMultiple(true)
                },
                cancel: function($event) {
                  return _vm.setConfirmMultiple(false)
                }
              }
            },
            [
              _c("p", [
                _vm._v(
                  "One or more active stays already exist for MRN " +
                    _vm._s(_vm.data.patient.mrn) +
                    "."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Press "),
                _c("em", [_vm._v("Cancel")]),
                _vm._v(
                  " to cancel stay creation, and use existing active stay(s)."
                )
              ]),
              _vm._v(" "),
              _c("p", [
                _vm._v("Press "),
                _c("em", [_vm._v("OK")]),
                _vm._v(
                  " to continue creating stay. There will be multiple active stays associated with this patient."
                )
              ])
            ]
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }