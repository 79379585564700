











import mixins from 'vue-typed-mixins'
import { find } from 'lodash-es'

import stays from '@store/stays'

import { PropExtInfo } from 'models'
import { Stay } from 'models/data/stay'
import { NestedProp } from 'models/schema'
import { DisplayInfo, DisplayList } from 'models/layout'
import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'

import DisplayMixin from '@mixins/Display.vue'
import Generic from '@sections/Generic.vue'
import Questions from '@stayinputs/Questions.vue'


const notesDisplayInfo: { [k: string]: DisplayList } = {
    social_history: ['notes']
}

const displayInfo: { [k: string]: DisplayList } = {
    social_history: [
        {
            field: 'living_situation',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'lives_with',
            hidden: false,
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'mobility_status',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'mobility_requires_assistance',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'wash_dress_toilet',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'cook_finance_shop',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'driving',
            dropdown: true,
            horizontal: true,
        },
        {
            field: 'employment',
            dropdown: true,
            horizontal: true,
        },
    ]
}

export default mixins(DisplayMixin).extend({
    components: {
        Generic,
        Questions,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            displayInfo,
            notesDisplayInfo,
            QuestionCategory,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        syndrome(): Syndrome | undefined {
            return stays.getSyndrome(this.stay_id)
        },
        historySchema(): NestedProp | undefined {
            return this.getSectionSchema('social_history')
        },
        historyFields(): PropExtInfo[] {
            if (this.historySchema)
                return this.generatePropExtInfo('social_history',
                    this.historySchema.children, this.displayInfo.social_history)
            return []
        },
        historyMsg(): string {
            return this.historySchema ? '' : 'Schema loading...'
        },
        socialHistoryNotesField(): PropExtInfo[] {
            if (this.historySchema) {
                return this.generatePropExtInfo(
                    'social_history',
                    this.historySchema.children,
                    this.notesDisplayInfo.social_history
                )
            }
            return []
        },
    },
    watch: {
        'stay.social_history.living_situation': {
            immediate: true,
            handler: function(val: any) {
                const info: DisplayInfo | undefined = find(this.displayInfo.social_history, { field: 'lives_with' }) as DisplayInfo
                if (info) {
                    if (val === 'HOME')
                        info.hidden = false
                    else
                        info.hidden = true
                }
            }
        }
    },
})
