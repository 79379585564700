var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "scrawl-stacked" },
    [
      _c(
        "card-lapsible",
        { attrs: { title: "Attending", visible: "" } },
        [
          _c("team", {
            staticClass: "pb-0",
            attrs: { stay_id: _vm.stay_id, section: "adm_present" }
          }),
          _vm._v(" "),
          _c("hospital", {
            staticClass: "pt-0",
            attrs: { stay_id: _vm.stay_id }
          })
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "card-lapsible",
        { attrs: { title: "Presenting Complaint", visible: "" } },
        [
          _c("presentation", { attrs: { stay_id: _vm.stay_id } }),
          _vm._v(" "),
          _c("pos-negs", { attrs: { stay_id: _vm.stay_id } })
        ],
        1
      ),
      _vm._v(" "),
      _c("background", { attrs: { stay_id: _vm.stay_id } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }