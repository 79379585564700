/**
 * A module for storing settings for the current session.
 */

import { defineModule } from "direct-vuex"
import { moduleGetterContext as rootModuleGetterContext, moduleActionContext as rootModuleActionContext } from 'store'
import Vue from "vue"
import * as Sentry from '@sentry/vue'
import * as moment from 'moment'
import * as commonmark from "commonmark"
import { each, find, get, has } from 'lodash-es'
import utils from 'utils'
import { Settings, FeedLogic } from 'models/meta'
import { SESH_changesMaxAge, SESH_syncMonitorInterval } from '../constants'
import { Credentials, EditMode, LoginState, RecentNote, SessionState } from 'models/store/session'

const cmParser = new commonmark.Parser()
const cmWriter = new commonmark.HtmlRenderer()

const initState: SessionState = {
    // stay sync
    changesMaxAge: SESH_changesMaxAge,
    syncMonitorInterval: SESH_syncMonitorInterval,

    // site-wide variables
    browser: {},
    ready: false,
    knocking: false,
    loginStatus: "unknown",
    syncErrors: [],
    syncErrorsVisible: false,
    errorMsg: '',

    adminSpeciality: null,
    asmtVisible: true,
    choicesDisplay: 'wrap',
    dataVizStayID: -1,
    dataVizEditMode: {},
    homeShowSpeciality: window.scrawl ? window.scrawl.defaultShowSpeciality === 'true' : false,
    suspendPrivileges: false,
    copyBtnLit: false,

    DRL_leaving: false,
    RN_tag: 'def',
    RN_recentNotes: [],
    RN_selected: '',

    //dashboard
    onDashboard: false,
    pauseOverview: false,
    mdtPresent: [],

    openInvID: -1,

    // assessment view
    textNIHSS: -1,

    // server configuration settings
    feedLogic: 'NO_FEED',
    hasPasFeed: false,

    // server stored settings
    invs_version: 'v2',
    nihss_version: 'v1',
    ux_signature: true,
    ux_abf: false,
    ux_asap_viz: false,
    ux_asap_export: false,
    ux_consult: false,
    ux_consults_simple: true,
    ux_home_all_links: false,
    ux_login_pg_msg: '',
    ux_location_alt_ward: false,
    ux_location_prune_bed: false,
    ux_nihss_bold: true,
    ux_nurse_review: false,
    ux_sandbox_from: '',
    ux_sandbox_to: '',
    ux_ts_mgmt_issues: false,
}

const sessionModule = defineModule({
    namespaced: true,
    state: (): SessionState => {
        return initState
    },
    getters: {
        loggedIn(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.loginStatus == "loggedin"
        },
        errorHTML(...args): string {
            const { state } = moduleGetterContext(args)
            return cmWriter.render(cmParser.parse(`\`\`\`\n${state.syncErrors.join('\n')}\n\`\`\``))
        },
        hasSyncErrors(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.syncErrors.length > 0
        },
        feedOn(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.feedLogic !== 'NO_FEED'
        },
        RN_recentNote(...args): string {
            const { state } = moduleGetterContext(args)
            return find(state.RN_recentNotes, { id: state.RN_selected, })?.notes || ''
        },
    },
    mutations: {
        changesMaxAge(state, age: number) {
            state.changesMaxAge = age
        },
        syncMonitorInterval(state, interval: number) {
            state.syncMonitorInterval = interval
        },

        setBrowser(state, val: any) {
            state.browser = val
        },
        setReady(state, val: boolean) {
            state.ready = true
        },
        setKnocking(state, val: boolean) {
            state.knocking = val
        },
        loginStatus(state, loginStatus: LoginState) {
            state.loginStatus = loginStatus
        },
        setLoginPageMsg(state, val: string) {
            state.ux_login_pg_msg = val
        },
        addSyncError(state, error: string) {
            state.syncErrors.unshift(error)
        },
        clearSyncErrorVars(state) {
            state.syncErrors = []
            state.syncErrorsVisible = false
        },
        setSyncErrorsVisible(state, val: boolean) {
            state.syncErrorsVisible = val
        },
        toggleSyncErrorsVisible(state) {
            state.syncErrorsVisible = !state.syncErrorsVisible
        },
        setErrorMsg(state, errorMsg: string) {
            state.errorMsg = errorMsg
        },
        clearErrorMsg(state) {
            state.errorMsg = ''
        },

        setAdminSpeciality(state, val: number | null) {
            state.adminSpeciality = val
        },
        setAsmtVisible(state, val: boolean) {
            state.asmtVisible = val
        },
        cycleChoicesDisplay(state) {
            switch (state.choicesDisplay) {
                case 'wrap':
                    state.choicesDisplay = 'per_line'
                    break
                case 'per_line':
                    state.choicesDisplay = 'wrap'
                    break
            }
        },
        setDataVizStayID(state, id: number) {
            state.dataVizStayID = id
        },
        clearDataVizStayID(state) {
            state.dataVizStayID = -1
        },
        setDataVizEditMode(state, info: { id: number, mode: EditMode }) {
            Vue.set(state.dataVizEditMode, info.id, info.mode)
        },
        toggleHomeShowSpeciality(state) {
            state.homeShowSpeciality = !state.homeShowSpeciality
        },
        suspendPrivileges(state, mode: boolean) {
            state.suspendPrivileges = mode
        },
        copyBtnLit(state) {
            state.copyBtnLit = true
        },

        DRL_leaving(state, leaving: boolean) {
            state.DRL_leaving = leaving
        },
        RN_tag(state, tag: string) {
            state.RN_tag = tag
        },
        RN_selected(state, id: string) {
            state.RN_selected = id
        },
        RN_addRecentNote(state, { stay_id, notes }: { stay_id: number, notes: string }): void {
            if (!notes)
                return
            let index = 0
            while (window.sessionStorage.getItem(`s_${stay_id}_${state.RN_tag}_text_${index}_notes`) !== null)
                index++
            const created = moment()
            const created_s = created.toISOString(true)
            const tagBase = `s_${stay_id}_${state.RN_tag}_text_${index}`
            window.sessionStorage.setItem(`${tagBase}_notes`, notes)
            window.sessionStorage.setItem(`${tagBase}_created`, created_s)
            state.RN_recentNotes.push({
                id: tagBase,
                created: utils.niceDateShort(created_s),
                notes,
            })
        },
        RN_refreshRecentNotes(state, stay_id: number): void {
            if (state.RN_recentNotes.length)
                state.RN_recentNotes.splice(0, state.RN_recentNotes.length)
            let index = 0
            let notes: string | null = null
            let created: string | null = null
            while (true) {
                const tagBase = `s_${stay_id}_${state.RN_tag}_text_${index}`
                notes = window.sessionStorage.getItem(`${tagBase}_notes`)
                created = window.sessionStorage.getItem(`${tagBase}_created`)
                if (notes === null)
                    break
                state.RN_recentNotes.push({
                    id: tagBase,
                    created: utils.niceDateShort(created!),
                    notes,
                })
                index++
            }
        },

        setOnDashboard(state, val: boolean) {
            state.onDashboard = val
        },
        setPauseOverview(state, val: boolean) {
            state.pauseOverview = val
        },
        addMDTPresent(state, id: number) {
            state.mdtPresent.push(id)
        },
        removeMDTPresent(state, id: number) {
            const i = state.mdtPresent.indexOf(id)
            if (i !== -1)
                state.mdtPresent.splice(i, 1)
        },
        setMDTPresent(state, ids: number[]) {
            state.mdtPresent = ids
        },
        setOpenInvID(state, id: number) {
            state.openInvID = id
        },
        clearOpenInvID(state) {
            state.openInvID = -1
        },
        setTextNIHSS(state, id: number) {
            state.textNIHSS = id
        },
        clearTextNIHSS(state) {
            state.textNIHSS = -1
        },

        setFeedLogic(state, logic: FeedLogic) {
            state.feedLogic = logic
        },
        setHasPasFeed(state, hasFeed: boolean) {
            state.hasPasFeed = hasFeed
        },

        updateSettings(state, payload: Settings) {
            each(payload, (val, key) => {
                if (has(state, key) && !utils.isEqual(get(state, key), val))
                    Vue.set(state, key, val)
            })
        },
    },
    actions: {
        knock(context) {
            const { commit } = moduleActionContext(context)
            console.log("knock: knocking...")
            commit.setKnocking(true)

            return utils.request
            .get("/account/knockknock/")
            .then(res => {
                commit.loginStatus("loggedin")
                console.log("knock: logged in")
                Vue.toasted.success("Logged in.")
            })
            .catch(err => {
                if (err.status === 403 || err.status === 401) {
                    // Vue.toasted.error("You're not logged in :(")
                    console.log("knock: logged out")
                    commit.loginStatus("loggedout")
                }
                else {
                    commit.loginStatus('no_service')
                    utils.handleRequestError(err)
                }
                /* RC commented out 2020-10-23 - check may not be necessary
                else if (err.status === 502
                    || (err.status === undefined && startsWith(err.message, 'Request has been terminated')))
                    commit.loginStatus('no_service')
                */
            })
            .then(res => {
                commit.setKnocking(false)
            })
        },

        /** "snoozes" the logout, ie, extends the session */
        snooze(context) {
            const { rootState } = moduleActionContext(context)
            return utils.request.post('/account/snooze/')
            .then(res => {
                if (rootState.user.debug_mode)
                    Vue.toasted.info('session extended')
            })
            .catch(err => {
                utils.handleRequestError(err)
            })
},

        login(context, credentials: Credentials) {
            const { commit } = moduleActionContext(context)
            console.log("logging in...")
            return utils.request
            .post("/account/login/", false, credentials)
            .then(res => {
                Sentry.setUser({ username: credentials.username })
                commit.loginStatus("loggedin")
                console.log("login successful")
                Vue.toasted.success("Logged in.")
            })
            .catch(err => {
                console.log("login failed")
                console.log(err)
                if (err.status === 401) {
                    const msg = err.response.body?.detail || err.response.body || 'Unauthorized.'
                    Vue.toasted.error(msg, { position: 'top-center', duration: 5000 })
                }
                else
                    Vue.toasted.error(`Error logging in (code: ${err.status})`, { position: 'top-center', duration: 5000 })
            })
        },

        logout(context) {
            const { commit } = moduleActionContext(context)
            console.log("logging out...")
            return utils.request
            .get('/account/logout/')
            .then(res => {
                console.log("logout successful")
                Vue.toasted.info("Logged out.")
            })
            .catch(err => {
                if (err.status !== 403) {
                    console.log("logout failed")
                    console.log(err)
                    Vue.toasted.error(`Error logging out (code: ${err.status})`)    
                }
            })
            .then(res => {
                Sentry.setUser({ username: '?' })
                commit.loginStatus("loggedout")
                commit.setReady(false)
                window.sessionStorage.clear()
                // ! currently handled by Session.vue. Not sure if it should be returned here.
                // ! user.mutResetProfile()
            })
        },

        tokenLogin(context, token: string) {
            const { commit } = moduleActionContext(context)
            console.log("logging in by token...")
            return utils.request
            .post("/account/token_login/", false, { token })
            .then(res => {
                commit.loginStatus("loggedin")
                console.log("login successful")
                Vue.toasted.success("Logged in.")
            })
            .catch(err => {
                if (err.status === 401)
                    Vue.toasted.error("Credentials expired - links are active for only 30 minutes. Pleae generate a new one.", { position: 'top-center', duration: 10000 })
                else
                    Vue.toasted.error(`Error logging in (code: ${err.status})`)
            })
        },
    }
})

export default sessionModule

const moduleGetterContext = (args: [any, any, any, any]) => rootModuleGetterContext(args, sessionModule)
const moduleActionContext = (context: any) => rootModuleActionContext(context, sessionModule)
