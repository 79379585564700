var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("table-base", {
    staticClass: "mt-2",
    class: { "showing-details": _vm.expandedStayID },
    attrs: {
      items: _vm.stayProxies,
      fields: _vm.fields,
      "primary-key": "id",
      "sort-by": _vm.sortBy,
      "sort-null-last": ""
    },
    on: {
      "html-id": function($event) {
        _vm.tableHTMLID = $event
      }
    },
    scopedSlots: _vm._u([
      {
        key: "head(length_of_stay)",
        fn: function(data) {
          return [
            _c(
              "span",
              [
                _c("span", { attrs: { title: "Length of Stay" } }, [
                  _vm._v("LoS")
                ]),
                _vm._v(" "),
                _vm.showABF
                  ? [
                      _c("br"),
                      _vm._v(" "),
                      _c("span", { staticClass: "text-nowrap" }, [
                        _vm._v("\n                    (ABF: "),
                        _c(
                          "span",
                          {
                            attrs: {
                              title: "This is the ABF sum for all stays below."
                            }
                          },
                          [_vm._v(_vm._s(_vm.abfTotal))]
                        ),
                        _vm._v(" "),
                        _vm.abfEfficiency
                          ? _c(
                              "span",
                              { attrs: { title: _vm.abfEfficiencyHelp } },
                              [_vm._v("| " + _vm._s(_vm.abfEfficiency))]
                            )
                          : _vm._e(),
                        _vm._v(")\n                ")
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        }
      },
      {
        key: "cell(location)",
        fn: function(data) {
          return [_c("location", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(patient)",
        fn: function(data) {
          return [
            _c("patient", { attrs: { stay_id: data.item.id } }),
            _vm._v(" "),
            _vm.canMDT
              ? _c(
                  "b-btn",
                  {
                    staticClass: "mt-1",
                    attrs: { size: "sm", variant: "outline-info" },
                    on: {
                      click: function($event) {
                        return _vm.toggleMDT(data)
                      }
                    }
                  },
                  [
                    _vm._v(
                      "\n            " +
                        _vm._s(data.detailsShowing ? "Hide" : "Show") +
                        " MDT\n        "
                    )
                  ]
                )
              : _vm._e()
          ]
        }
      },
      {
        key: "row-details",
        fn: function(data) {
          return [
            _c("mdt-present", { attrs: { stay_id: data.item.id } }),
            _vm._v(" "),
            _c("mdt-questions", { attrs: { stay_id: data.item.id } }),
            _vm._v(" "),
            _c("mdt-text", {
              staticClass: "mt-2",
              attrs: { stay_id: data.item.id, height: 1 }
            })
          ]
        }
      },
      {
        key: "cell(diagnosis)",
        fn: function(data) {
          return [
            _c("diagnosis-and-summary", { attrs: { stay_id: data.item.id } })
          ]
        }
      },
      {
        key: "cell(surgery)",
        fn: function(data) {
          return [_c("surgery-time", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(neuro_summary)",
        fn: function(data) {
          return [_c("neuro-summary", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(issues)",
        fn: function(data) {
          return [_c("issues", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(social)",
        fn: function(data) {
          return [_c("social-history", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(investigations_outstanding)",
        fn: function(data) {
          return [_c("investigations", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(next_steps)",
        fn: function(data) {
          return [_c("next-steps", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(required_for_discharge)",
        fn: function(data) {
          return [_c("ready-when", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(aiming_for)",
        fn: function(data) {
          return [_c("aiming-for", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(follow_up_plan)",
        fn: function(data) {
          return [_c("follow-up", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(care_classification)",
        fn: function(data) {
          return [_c("acute-care-status", { attrs: { stay_id: data.item.id } })]
        }
      },
      {
        key: "cell(length_of_stay)",
        fn: function(data) {
          return [
            _c(
              "span",
              [
                _c(
                  "span",
                  { attrs: { title: _vm.acuteDaysDesc(data.item.id) } },
                  [_vm._v(_vm._s(data.item.length_of_stay))]
                ),
                _vm._v(" "),
                _vm.showABF
                  ? [
                      _c("br"),
                      _vm._v(
                        "\n                (" +
                          _vm._s(_vm.abfScore(data.item.id)) +
                          ")\n            "
                      )
                    ]
                  : _vm._e()
              ],
              2
            )
          ]
        }
      },
      {
        key: "cell(print_notes)",
        fn: function(data) {
          return [_c("print-notes", { attrs: { stay_id: data.item.id } })]
        }
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }