





































import Vue from 'vue'
import { Location } from 'vue-router'
import { find } from 'lodash-es'

import { ASMT_ADM, ASMT_NURSE, ASMT_NURSE_SPECIALITY, ASMT_SPECIALITY, ASMT_SYNDROME, ASMT_WARD } from 'routers/constants'
import { Stay } from 'models/data/stay'

import DeleteAction from './ActionDelete.vue'
import DischargeAction from './ActionDischarge.vue'
import DiagnosisHistoryModal from 'components/history/DiagnosisHistoryModal.vue'
import EndConsultAction from './ActionEndConsult.vue'
import SandboxBtn from '@misc/SandboxBtn.vue'
import TempUserAction from './ActionTempUser.vue'

export default Vue.extend({
    components: {
        DeleteAction,
        DischargeAction,
        DiagnosisHistoryModal,
        EndConsultAction,
        SandboxBtn,
        TempUserAction,
    },
    props: {
        stay: {
            type: Object as () => Stay,
            required: true
        },
        deleteId: {
            type: String,
            default: ''
        }
    },
    data() {
        const observer = null as MutationObserver | null
        return {
            ASMT_ADM,
            actionsVisible: false,
            observer,
        }
    },
    computed: {
        triageMode(): boolean {
            return this.$store.direct.state.user.triage_mode
        },
        isPowerUser(): boolean {
            return this.$store.direct.getters.user.isPowerUser
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        canDoAdvTSActions(): boolean {
            return this.$store.direct.getters.user.canDoAdvTSActions
        },
        unknownStage(): boolean {
            return this.stay.stage === 'UNKNOWN'
        },
        admissionStage(): boolean {
            return this.stay.stage === 'ADMISSION'
        },
        wardStage(): boolean {
            return this.stay.stage === 'WARD'
        },
        showNurseReviewBtn(): boolean {
            return this.triageMode
        },
        nurseReviewRoute(): string {
            return this.stay.rapid_assessment_syndrome ? ASMT_NURSE : ASMT_NURSE_SPECIALITY
        },
        startRouteInfo(): Location {
            let location: Location = {
                name: ASMT_SPECIALITY,
                params: { stay_id: `${this.stay.id}` }
            }

            if (this.telestrokeMode) {
                const neurology = find(this.$store.direct.state.templates.specialities, { title: 'Neurology' })
                if (neurology)
                    location = {
                        name: ASMT_SYNDROME,
                        params: {
                            speciality_id: `${neurology.id}`,
                            stay_id: `${this.stay.id}`,
                        }
                    }
            }

            return location
        },
        mainRouteInfo(): Location | undefined {
            const stay_id = `${this.stay.id}`
            if (this.unknownStage)
                return this.startRouteInfo

            const admRoute = { name: ASMT_ADM, params: { stay_id }}
            const revRoute = { name: ASMT_WARD, params: { stay_id }}

            if (this.telestrokeMode)
                return this.isTSSiteUser ? revRoute : admRoute  // normal TS users only use admission            

            if (this.admissionStage)
                return admRoute
            else if (this.wardStage)
                return revRoute

            return
        },
        mainBtnLabel(): string {
            if (this.unknownStage)
                return 'Start'
            if (this.telestrokeMode || this.wardStage)
                return 'Review'
            if (this.admissionStage)
                return 'Admission'
            return '?'
        },
        isConsult(): boolean {
            return this.stay && !!this.stay.primary
        },
    },
    mounted() {
        this.setActionsVisible(this.$el.childElementCount > 0)

        const self = this
        const observerCB = function(mutations: MutationRecord[], observer: MutationObserver) {
            self.setActionsVisible(self.$el.childElementCount > 0)
        }
        this.observer = new MutationObserver(observerCB)
        this.observer.observe(this.$el, { attributes: true, childList: true, subtree: true })
    },
    beforeDestroy() {
        if (this.observer)
            this.observer.disconnect()
    },
    methods: {
        gotoAuditTrail() {
            this.$router.push({ name: 'stay-audit-trail', params: { stay_id: `${this.stay.id}` } })
        },
        setActionsVisible(val: boolean) {
            this.actionsVisible = val
            this.$emit('actions-visible', val)
        },
    }
})
