import { filter, find, forEach, get, includes, isNil, map, isBoolean } from 'lodash-es'
import * as moment from 'moment'
import { LBR, PBR } from './constants'
import { freeTextSubHtmlBreaks, textWithNotes, doPlainIndent } from './helpers'
import { Stay } from 'models/data/stay'
import { AnswerType, CustomAnswer, choiceListAnsDecoder, datetimeAnsDecoder, strAnsDecoder } from 'models/data/answer'
import { Question, QuestionCategory, QuestionGroupContainer, QuestionType, QuestionGroup } from "models/med_templates/question"
import { safeDecoding } from 'utils/store'

import stays from '@store/stays'
import store from 'store'


/**
 * Get the HTML-ready text for the selected choices given an answer that's a choice ID or an array of choice IDs
 * **Note:** Implementation is the same as in question.ts
 * 
 * @param question 
 * @param answer 
 * @param ignoreStacked if `true`, multiple answers are always presented as comma-separated
 */
 export function getChoiceText(question: Question, answer: number | number[], ignoreStacked?: boolean): string {
    if (Array.isArray(answer)) {
        const selectedChoices = filter(question.choices, choice => includes(answer, choice.id))

        let stacked = store.direct.state.session.choicesDisplay === 'per_line'
        if (question.stacked !== null)
            stacked = question.stacked
        if (ignoreStacked)
            stacked = false

        const delimiter = stacked ? '<br>' : ', '

        return map(selectedChoices, choice => choice.title).join(delimiter)
    }

    const choice = find(question.choices, choice => choice.id === answer)
    return choice ? choice.title : ''
}


export interface AnswerInfo {
    answer: AnswerType
    notes: string | null
    updated_at: string
}

/**
 * Safely retrieves a Question's answer, notes and updated_at info
 * @param stay_id 
 * @param question 
 * @param answers 
 */
export function rawAnswerAndNotes(stay_id: number, question: Question, answers?: { [k: string]: CustomAnswer }): AnswerInfo {
    if (!answers) {
        const stay: Stay | undefined = stays.state.stays[stay_id]
        answers = stay?._answers || {}
    }

    let answer: AnswerType = null
    let notes: string | null = null
    let updated_at: string = ''

    const answerObj = get(answers, `q_${question.id}`)
    if (answerObj) {
        answer = answerObj.answer
        updated_at = answerObj.updated_at
        if (question.allow_notes)
            notes = answerObj.notes
    }

    return { answer, notes, updated_at }
}


/**
 * Does basic formatting/combining of answer and notes. Does not use Question's customisation flags.
 * 
 * @param answer 
 * @param question 
 * @param ignoreStacked 
 */
function formatAnswer(answer: AnswerType, question: Question, ignoreStacked?: boolean): string | null {
    if (isNil(answer)
        || (question.type === QuestionType.BOOLEAN && !isBoolean(answer)))
        return null

    let answerText: string = ''

    switch (question.type) {
        case QuestionType.BOOLEAN:
            if (answer) {
                answerText = question.yes_text || 'Yes'
            } else {
                answerText = question.no_text || 'No'
            }
            break

        case QuestionType.CHOICE:
        case QuestionType.LIST:
            const choiceListAns: number | number[] = safeDecoding(answer, choiceListAnsDecoder, 'v2/formatAnswer/list')
            answerText = getChoiceText(question, choiceListAns, ignoreStacked)
            break

        case QuestionType.TIMESTAMP:
            const time = moment(safeDecoding(answer, datetimeAnsDecoder))
            if (time.isValid())
                answerText = time.format("h:mma [on] Do MMM YYYY")
            break

        case QuestionType.DATE:
            const date = moment(safeDecoding(answer, datetimeAnsDecoder))
            if (date.isValid())
                answerText = date.format("Do MMM YYYY")
            break

        case QuestionType.LONG_TEXT:
        case QuestionType.TEXT:
            answerText = freeTextSubHtmlBreaks(safeDecoding(answer, strAnsDecoder).trim())
            if (answerText.indexOf('\n') > 0)
                answerText = '<br>' + answerText
            break

        case QuestionType.NUMBER:
        default:
            answerText = `${answer}`
            break
    }

    return answerText || null
}


interface FormattedResult {
    raw: AnswerType
    updated_at: string
    answer: string
    hasNotes: boolean
    /** the fully formatted question title/answer/notes string */
    text: string
    booleanLike: boolean
    positive: boolean
}

/**
 * Returns *null* if a string cannot be derived for the answer - answer could be null, or the answer type is incompatible with the question type.
 * Otherwise, formats the answer and notes based on the Question's customisation flags.
 *  * 
 * @param stayID 
 * @param question 
 * @param answers an optional pool of answers to retrieve the provided question's response from
 * @param ignoreStacked if `true`, multiple answers are always presented on one-line and comma-separated
 * @param titleOnly if `true`, boolean-like answers will only use the question title regardless of answer. Used where answers are grouped into positives/negatives. defaults to `false`
 */
export function formatQuestionAnswerAndNotes(
    stayID: number,
    question: Question,
    answers?: {[k: string]: CustomAnswer},
    ignoreStacked?: boolean,
    titleOnly?: boolean,
    answerInfo?: AnswerInfo,
): FormattedResult | null {
    if (question.no_text_output)
        return null

    if (titleOnly === undefined)
        titleOnly = false

    const { answer, notes, updated_at } = answerInfo || rawAnswerAndNotes(stayID, question, answers)
    const answerOnly = formatAnswer(answer, question, ignoreStacked)
    const hasNotes = !!notes

    if (!answerOnly && hasNotes) {
        const res: FormattedResult = {
            raw: answer,
            updated_at,
            answer: '',
            hasNotes,
            booleanLike: false,
            positive: false,
            text: '',
        }
        if (question.show_title_in_text_output) {
            // <title>: "something" if show_title_in_text_output is true
            res.text = `${question.title}: ${notes}`
        }
        else {
            // "something" if show_title_in_text_output is false
            res.text = notes!
        }
        return res
    }

    if (!answerOnly)
        return null

    const answerLC = answerOnly.trim().toLowerCase()
    const positive = answerLC === 'yes'
    const negative = answerLC === 'no'
    const booleanLike = positive || negative

    const res: FormattedResult = {
        raw: answer,
        updated_at,
        answer: answerOnly,
        hasNotes,
        booleanLike,
        positive,
        text: '',
    }

    if (booleanLike && (question.smart_format || titleOnly)) {
        if (titleOnly) {
            // <title> for both positive/negative
            res.text = textWithNotes(question.title, notes)
        }
        else {  // if (question.smart_format)
            // <title> for positive, No <title> for negative 
            if (positive)
                res.text = textWithNotes(question.title, notes)
            else {
                const noText = question.false_prefix || 'No'
                const answerStr = `${noText} ${question.title}`
                res.text = textWithNotes(answerStr, notes)
            }
        }
    }
    else {
        const answerAndNotes = textWithNotes(answerOnly, notes)
        if (question.show_title_in_text_output) {
            // <title>: "something" if show_title_in_text_output is true
            res.text = `${question.title}: ${answerAndNotes}`
        }
        else {
            // "something" if show_title_in_text_output is false
            res.text = answerAndNotes
        }
    }

    return res
}


interface genericQuestionsTextParams {
    stay: Stay | undefined
    category: QuestionCategory
    getQuestionGroupsFrom: QuestionGroupContainer | undefined
    validQuestionGroupIDs?: number[]
    answers?: {[k: string]: CustomAnswer}
    ignoreStacked?: boolean
    /** if true, use validQuestionGroupIDs for question groups ordering */
    orderByValid?: boolean
    doIndent?: boolean
}

/**
 * 
 * @param param0 
 * @param optionalHide if true, check stay's *dash_hide_qs* for each question to see if text should be displayed
 */
export function genericQuestionsText(
    {
        stay, category,
        getQuestionGroupsFrom, validQuestionGroupIDs, answers,
        ignoreStacked, orderByValid, doIndent,
    }: genericQuestionsTextParams,
    optionalHide?: boolean
): string {
    if (!stay || !getQuestionGroupsFrom) return ""
    if (optionalHide === undefined) optionalHide = false

    let questionGroups = stays.getQuestionGroups({
        stay_id: stay.id,
        category,
        parentObject: getQuestionGroupsFrom,
    })

    if (validQuestionGroupIDs && validQuestionGroupIDs.length) {
        // Filter out question groups not present in validQuestionGroupIDs
        if (orderByValid) {
            const orderedGroups: QuestionGroup[] = []
            forEach(validQuestionGroupIDs, validID => {
                const group = find(questionGroups, group => group.id === validID)
                if (group)
                    orderedGroups.push(group)
            })
            questionGroups = orderedGroups
        }
        else
            questionGroups = filter(questionGroups, group => includes(validQuestionGroupIDs, group.id))
    }

    const sections: string[] = []

    forEach(questionGroups, group => {
        const questions = filter(map(group.questions, q => q.question))

        const sectionLines: string[] = []

        forEach(questions, question => {
            if (optionalHide && stay.dash_hide_qs && includes(stay.dash_hide_qs, question.id))
                return
            const resText = formatQuestionAnswerAndNotes(stay.id, question, answers, ignoreStacked)
            if (resText)
                sectionLines.push(resText.text)
        })

        let sectionText = sectionLines.join(LBR)

        if (sectionText) {
            if (group.title) {
                sectionText = `${group.title}${LBR}${sectionText}`
                if (doIndent)
                    sectionText = doPlainIndent(sectionText)
            }
            sections.push(sectionText)
        }
    })

    if (!sections.length) return ''

    return sections.join(`${LBR}${LBR}`)
}
