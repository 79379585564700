










import Vue from 'vue'
import { filter, includes, map } from 'lodash-es'
import utils from 'utils'
import { Hospital } from 'models/med_templates/hospital'
import FilterBase from './FilterBase.vue'

/**
 * This filter operates differently depending on isTSSiteUser.
 * For TSSiteUser:
 * - the list of hospitals is restricted to what is assigned to the user.
 * - Selecting [All] will not clear the selection (which does NO filtering), it instead sets only the user's hospital IDs
 * For other users:
 * - default filtering applies.
 */
export default Vue.extend({
    components: {
        FilterBase,
    },
    data() {
        return {
            selectedIDs: [] as number[]
        }
    },
    computed: {
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        allHospitals(): Hospital[] {
            return this.$store.direct.state.templates.hospitals
        },
        userHospitalIDs(): number[] {
            return this.$store.direct.state.user.hospitals
        },
        allowedHospitals(): readonly Hospital[] {
            return this.isTSSiteUser ? this.$store.direct.getters.user.hospitals : this.allHospitals
        },
        hospitals: {
            get(): number[] {
                return this.$store.direct.state.user.filters.hospitals
            },
            set(ids: number[]) {
                if (!utils.isEqual(this.hospitals, ids)) {
                    this.$store.direct.dispatch.user.updateFilters({ hospitals: ids })
                    this.$emit('update')
                }
            }
        },
        selectedText(): string | undefined {
            const selected = filter(this.allHospitals, d => includes(this.selectedIDs, d.id))
            const labels = map(selected, opt => opt.title)
            return labels.join(", ") || undefined
        },
    },
})
