import { TextDetails } from './interfaces'
import { anonymisedPatient } from "models/data/patient"


function hospital({stay}: TextDetails): string {
    if (!stay) return ""
    return stay.hospital ? stay.hospital.title : ''
}

export function patientDetails({stay, anonymise}: TextDetails): string {
    if (!stay) return ""

    let patient = anonymise ? anonymisedPatient(stay.patient) : stay.patient
    // return `${patient.last_name}, ${patient.first_name} - ${patient.age} - MRN: ${patient.mrn}`
    return `${patient.first_name} ${patient.last_name} (${patient.age}) MRN ${patient.mrn}`
}

export function patientDetailsWithHospital(textDetails: TextDetails): string {
    if (!textDetails.stay) return ""

    const details = patientDetails(textDetails)
    const hospitalText = hospital(textDetails)
    const lines = [details]
    if (hospitalText)
        lines.push(hospitalText)

    return lines.join("  \n")
}
