









































import Vue from "vue"
import { filter, find, includes } from "lodash-es"

import utils from 'utils'

import { ASMT_ADM, ASMT_MDT, ASMT_NURSE, ASMT_NURSE_SPECIALITY, ASMT_SPECIALITY, ASMT_WARD } from 'routers/constants'
import { Stay } from 'models/data/stay'
import { SidebarStay } from 'models/data/sidebar'
import { ViewMode } from 'models/meta'

export default Vue.extend({
    props: {
        viewMode: {
            required: false,
            default: ViewMode.normal,
        },
        stays: {
            type: Array as () => SidebarStay[],
            default: () => [],
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        isTempUser(): boolean {
            return this.$store.direct.state.user.is_temp
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        hideBtnText(): string {
            return this.shouldHide ? 'Show hidden' : 'Hide stays'
        },
        shouldHide(): boolean {
            return this.$store.direct.state.user.hide_stays
        },
        hiddenIds(): number[] {
            return this.$store.direct.state.user.hidden_stays
        },
        validStays(): SidebarStay[] {
            const specialityIds = this.$store.direct.getters.templates.specialityIds
            return filter(this.stays, stay => stay.speciality === null || includes(specialityIds, stay.speciality))
        },
        filteredStays(): SidebarStay[] {
            return this.shouldHide ?
                filter(this.validStays, stay => !includes(this.hiddenIds, stay.id)) :
                this.validStays
        },
        routeName(): string | undefined {
            return this.$route.name
        },
    },
    methods: {
        toggleHideStays() {
            this.$store.direct.dispatch.user.toggleHideStays()
        },
        inHiddenIds(id: number): boolean {
            return includes(this.hiddenIds, id)
        },
        toggleHide(id: number) {
            this.$store.direct.dispatch.user.toggleStayHide(id)
        },
        selectedStay(id: number) {
            return this.$route.params.stay_id === id.toString()
        },
        patientName(stay: Stay) {
            return utils.patientName(stay, this.$store.direct.state.user.anonymise)
        },
        loadStay(stay_id: number) {
            const stay = find(this.stays, ['id', stay_id])

            if (!stay) {
                Vue.toasted.error(`Could not load stay ${stay_id}`)
                return
            }

            let name: string
            let params: {[k: string]: string} = { stay_id: String(stay_id) }

            if (this.$route.params.tab) {
                params.tab = this.$route.params.tab
            }

            if (this.routeName === ASMT_MDT) {
                // If on the MDT page, go straight to the MDT for the stay
                name = ASMT_MDT
            }
            else {
                switch (this.viewMode) {
                    case ViewMode.rapid_asmt:
                        name = stay.rapid_assessment_syndrome ? ASMT_NURSE : ASMT_NURSE_SPECIALITY
                        break
                    default:
                        if (this.telestrokeMode && this.isTSSiteUser) {
                            name = ASMT_WARD
                            break
                        }
                        switch (stay.stage) {
                            case 'UNKNOWN':
                                name = ASMT_SPECIALITY
                                break
                            case 'ADMISSION':
                                name = ASMT_ADM
                                break
                            case 'WARD':
                                name = this.telestrokeMode ? ASMT_ADM : ASMT_WARD
                                break
                            default:
                                // If the stay has been admitted keep the section (ward or discharge) the same
                                name = this.$route.name || ASMT_WARD
                                break
                        }
                        break
                }
            }

            this.$router.push({ name, params })
            .catch(err => {
                if (err.name !== 'NavigationDuplicated')
                    utils.handleRequestError(err)
            })
        }
    }
})
