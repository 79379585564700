































import mixins from "vue-typed-mixins"
import { get } from 'lodash-es'
import utils from 'utils'

import stays from '@store/stays'
import { LayoutGroupExt, PropExtInfo } from "models"
import { NIHSS } from 'models/data/nihss'
import { NestedProp } from "models/schema"
import { FullSchema } from 'models/schema/stay'
import { DisplayList, LayoutLookup } from "models/layout"

import DisplayMixin from "@mixins/Display.vue"
import InputMixin from "@mixins/Input.vue"
import Vision from "./Vision.vue"
import Motor from "./Motor.vue"
import Generic from "@sections/Generic.vue"
import RInput from "@inputs/Input.vue"

const displayInfo: { [k: string]: DisplayList } = {
    nihss_highercenters: [
        {
            field: "consciousness",
            dropdown: true
        },
        {
            field: "age",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "month",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "close_open_eyes",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "make_a_fist",
            booleanOptions: {
                false_label: "Correct",
                true_label: "Incorrect"
            }
        },
        {
            field: "language",
            dropdown: true,
            full_width: true
        },
        {
            field: "dysarthria",
            dropdown: true,
            full_width: true
        }
    ],
    nihss_sensations: [
        {
            field: "sensory_loss_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_loss_left",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_right",
            dropdown: true,
            full_width: true
        },
        {
            field: "sensory_inattention_left",
            dropdown: true,
            full_width: true
        }
    ]
}

const layout: LayoutLookup = {
    nihss_highercenters: [
        { fields: [ "consciousness" ] },
        { label: "Orientation & comprehension", fields: ["age", "month", "close_open_eyes", "make_a_fist"], alignment: "stacked" },
        { fields: ["language", "dysarthria"], alignment: "inline" }
    ],
    nihss_sensations: [
        { label: "Sensory Exam (Pinprick)", fields: ["sensory_loss_right", "sensory_loss_left"], alignment: "inline" },
        { label: "Sensory Inattention", fields: ["sensory_inattention_right", "sensory_inattention_left"], alignment: "inline" }
    ]
}

export default mixins(DisplayMixin, InputMixin).extend({
    components: {
        Vision,
        Motor,
        Generic,
        RInput
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        prefix: {
            type: String,
            required: true
        }
    },
    data() {
        const uid = utils.getUID()

        let section: keyof FullSchema
        switch (this.prefix) {
            case 'admission':
                section = 'admission_nihss'
                break
            case 'rapid_asmt':
                section = 'rapid_asmt_nihss'
                break
            case 'ward_round':
            default: // 'ward_round'
                section = 'ward_round_nihss'
                break
        }

        return {
            uid,
            htmlID: `${uid}___${section}`,
            section,
        }
    },
    computed: {
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        schema(): NestedProp | undefined {
            return this.getSectionSchema(this.section)
        },
        statusMsg(): string {
            return this.schema ? "" : "Schema loading..."
        },
        hcFields(): PropExtInfo[] {
            return this.schema
                ? this.generatePropExtInfo(this.section, this.schema.children, displayInfo.nihss_highercenters)
                : []
        },
        hcGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.hcFields, layout.nihss_highercenters)
        },
        sensationFields(): PropExtInfo[] {
            return this.schema
                ? this.generatePropExtInfo(this.section, this.schema.children, displayInfo.nihss_sensations)
                : []
        },
        sensationGroups(): LayoutGroupExt[] | undefined {
            return this.generateGroups(this.section, this.sensationFields, layout.nihss_sensations)
        },
        notesPropExtInfo(): PropExtInfo {
            return this.generatePropExtInfo(this.section, this.schema ? this.schema.children : {}, ["notes"])[0]
        },
        nihss(): NIHSS | null | undefined {
            return get(stays.state.stays[this.stay_id], this.section)
        },
    },
})
