








import Vue from 'vue'
import { TSScope, TSScope_items } from 'models/filters'
import Dropdown from '@shared/inputs/Dropdown.vue'

export default Vue.extend({
    components: {
        Dropdown
    },
    props: {
        value: {
            type: String as () => TSScope
        }
    },
    data() {
        return {
            TSScope_items,
            tsScope_: this.value,
        }
    },
    computed: {
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
    },
    watch: {
        tsScope_(val: any) {
            this.$emit('input', val)
        },
    },
})
