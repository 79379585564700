



















import Vue from "vue"
import text, { PBR } from "utils/text"
import { diagnosisSummary } from "utils/text/diagnosis"
import {
    activeIssuesWithSummaryText,
    resolvedIssuesTitles
} from "utils/text/issue"
import {
    mdtCYOAText,
    mdtDischargePlan,
    mdtPresent,
    mdtSummary
} from "utils/text/mdt"
import stays from "@store/stays"
import TextBase from "../TextBase.vue"

export default Vue.extend({
    components: {
        TextBase
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        height: {
            type: Number,
            required: false
        }
    },
    computed: {
        markup(): string {
            const stay = stays.state.stays[this.stay_id]
            if (!stay) return ""

            let markup = "**MDT**\n\n"

            const textDetails = {
                stay,
                anonymise: this.$store.direct.state.user.anonymise
            }

            markup +=
                "**Patient details:** " + text.patientDetails(textDetails) + PBR
            markup +=
                "**Care Classification:** " +
                text.careClassification({ ...textDetails, hideTitle: true }) +
                PBR
            markup += mdtPresent(textDetails)
            markup += text.primaryDiagnosisWithKeyPoints(textDetails)
            markup += diagnosisSummary(textDetails) + PBR
            markup += activeIssuesWithSummaryText(textDetails) + PBR
            markup += resolvedIssuesTitles(textDetails, true) + PBR
            markup += mdtCYOAText(textDetails)
            markup += mdtDischargePlan(textDetails)
            markup += mdtSummary(textDetails)

            return markup
        }
    },
    methods: {
        markMDTComplete() {
            stays.extActSetPathValue(this.stay_id, "mdt", { complete: true })
        }
    }
})
