var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-btn",
        {
          staticClass: "mb-1",
          attrs: { size: "sm", variant: "info", to: _vm.mainRouteInfo }
        },
        [_vm._v("\n        " + _vm._s(_vm.mainBtnLabel) + "\n    ")]
      ),
      _vm._v(" "),
      _vm.showNurseReviewBtn
        ? _c(
            "b-btn",
            {
              staticClass: "mb-1",
              attrs: {
                size: "sm",
                variant: "info",
                to: {
                  name: _vm.nurseReviewRoute,
                  params: { stay_id: _vm.stay.id }
                }
              }
            },
            [_vm._v("\n        Nurse review\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.telestrokeMode && _vm.canDoAdvTSActions
        ? _c("temp-user-action", { attrs: { stay: _vm.stay } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isPowerUser
        ? _c(
            "button",
            {
              staticClass:
                "btn btn-sm progress-bar-striped bg-info text-white mb-1",
              on: {
                click: function($event) {
                  $event.stopPropagation()
                  return _vm.gotoAuditTrail.apply(null, arguments)
                }
              }
            },
            [_vm._v("\n        History\n    ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("diagnosis-history-modal", {
        attrs: { add_mb: "", stay_id: _vm.stay.id }
      }),
      _vm._v(" "),
      _c("sandbox-btn", {
        attrs: { add_mb: "", stay_id: _vm.stay.id, stay_type: "stay" }
      }),
      _vm._v(" "),
      !_vm.isConsult && _vm.telestrokeMode && _vm.canDoAdvTSActions
        ? _c("discharge-action", { attrs: { stay: _vm.stay } })
        : _vm._e(),
      _vm._v(" "),
      _vm.isConsult
        ? _c("end-consult-action", { attrs: { stay: _vm.stay } })
        : _vm._e(),
      _vm._v(" "),
      _c("delete-action", { attrs: { stay: _vm.stay } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }