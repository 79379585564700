
import Vue from 'vue'
import stays from '@store/stays'
import { FiltersParams, TSScope } from 'models/filters'

export default Vue.extend({
    data() {
        return {
            discharge: {
                showDischarged: false,
                period: '2w'
            },
            tsScope_: TSScope.All,
        }
    },
    computed: {
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        tsScope(): TSScope {
            return this.isTSSiteUser ? this.tsScope_ : TSScope.Telestroke
        },
        params(): FiltersParams {
            const stayFilters = this.$store.direct.getters.user.stayFilters

            // Filter stays by discharge criteria
            const dischargeFilters: FiltersParams = {
                sd: this.discharge.showDischarged,
                pd: this.discharge.period,
                d: stayFilters.doctors,
                dg: stayFilters.diagnoses,
                sp: stayFilters.specialities,
                spc: stayFilters.spaces,
                c: stayFilters.cares,
                h: stayFilters.hospitals,
                tsc: this.tsScope,
            }

            return dischargeFilters
        },
        staysLoading(): boolean {
            return stays.state.staysLoading
        },
        loadStays(): number {
            return stays.state.loadStays
        },
    },
    watch: {
        loadStays() {
            this.pull()
        },
        tsScope() {
            this.pull()
        },
    },
    methods: {
        pull(): void {
            this.$_pull()
        },
        $_pull() {
            throw new Error('StaysFilterMixin.$_pull needs to be implemented by component.')
        }
    }
})
