




















import Vue from "vue"
import { each, debounce } from "lodash-es"
import * as tinymce from "tinymce4"
import "tinymce4/themes/modern/theme"
import "tinymce4/plugins/textpattern"
import 'tinymce4/plugins/print'
import 'tinymce4/plugins/autoresize'
import { Editor } from "tinymce4"
import * as commonmark from "commonmark"
import * as moment from 'moment'
import utils from 'utils'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import PrintHelp from './PrintHelp.vue'

export default Vue.extend({
    components: {
        PrintHelp,
    },
    props: {
        htmlID: {
            type: String,
            default: "texteditor"
        },
        height: {
            type: Number,
            default: -1  // pixels
        },
        markup: {
            type: String,
            default: ''
        },
        customHtml: {
            type: String,
            default: ''
        },
        stay_id: {
            type: Number,
            required: false
        },
        readOnly: {
            type: Boolean,
            default: false
        },
        useSignature: {
            type: Boolean,
            default: true
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            uid,
            printHelpHtmlID: '',
            editor: null as Editor | null,
            /** editor height (local) */
            editorH: this.height,
            useUnderline: false,
        }
    },
    computed: {
        isDeveloper() {
            return this.$store.direct.getters.user.isDeveloper
        },
        isIE11(): boolean {
            return window.scrawl.isIE11
        },
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        setEditorContent: function(): any {
            return debounce(this.$_setEditorContent, 500)
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        cHtmlID(): string {
            if (!this.htmlID || this.htmlID === "texteditor" || this.htmlID === "")
                return `${this.uid}___texteditor`
            return this.htmlID
        },
        finalMarkup(): string {
            const finalMarkup = (this.$store.direct.state.user.appendSig && this.useSignature)
                ? `${this.markup}\n\n${this.$store.direct.state.user.signature}`
                : this.markup
            return finalMarkup
        },
        useCustomHtml(): boolean {
            return this.customHtml.length > 0
        },
        finalHTML(): string {
            return this.useCustomHtml ? this.customHtml : this.cmWriter.render(this.cmReader.parse(this.finalMarkup))
        },
        /** converts all strong/bold text to underlined text */
        underlinedHTML(): string {
            const doc = (new DOMParser()).parseFromString(this.finalHTML, 'text/html')
            each(doc.querySelectorAll('strong'), node => {
                const span = doc.createElement('span')
                span.innerHTML = node.innerHTML
                span.style.textDecoration = 'underline'
                node.parentNode?.replaceChild(span, node)
            })
            return doc.body.innerHTML
        },
        usePlain(): boolean {
            return this.$store.direct.state.user.textOutputPlain
        },
    },
    watch: {
        editor: {
            handler: function(obj: Editor | null) {
                if (obj) {
                    if (this.useUnderline)
                        this.setEditorContent(this.underlinedHTML)
                    else
                        this.setEditorContent(this.finalHTML)
                }
            },
            immediate: true
        },
        finalHTML: {
            handler: function(val: string) {
                if (!this.useUnderline)
                    this.setEditorContent(val)
            },
        },
        height(val: number) {
            this.editorH = val * 0.92
        },
        editorH: {
            handler: function(val: number) {
                this.refreshEditor()
            },
            immediate: true
        },
        readOnly() {
            this.refreshEditor()
        },
        underlinedHTML: {
            handler: function(val: string) {
                if (this.useUnderline)
                    this.setEditorContent(val)
            },
        },
        useUnderline: {
            handler: function(val: boolean) {
                if (val)
                    this.setEditorContent(this.underlinedHTML)
                else
                    this.setEditorContent(this.finalHTML)
            },
            immediate: true
        },
        usePlain() {
            this.refreshEditor()
        },
    },
    mounted() {
        window.setTimeout(() => {
            if (this.editorH <= 0) {
                this.editorH = 2000
            }
        }, 1000)
        // this.initEditor()
    },
    /**
     * this is available via vue-router - it's not part of the basic Vue lifecycle.
     * using this instead of beforeDestroy since this runs before this component's
     * elements are actually destroyed.
     * editor.remove() runs a save operation, which tries to write data out to the
     * underlying textarea, which then fails if the textrea is already removed.
     */
    beforeRouteLeave() {
        this.removeEditor()
    },
    methods: {
        initEditor() {
            const self = this
            const enableDocX = window.scrawl.editorDocX === 'true'
            const exportButtons = enableDocX ? 'copyall downloaddocx' : 'copyall'
            const toolbar = this.readOnly
                ? `${exportButtons} | customprint printhelp`
                : `undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ${exportButtons} | customprint printhelp` // | useunderlined`

            let options: tinymce.Settings = {
                height: self.editorH,
                min_height: 1,
                target: this.$el.children[0],
                skin_url: `${window.scrawl.baseURL}/static/tinymce4/skins/lightgray`,
                init_instance_callback(editor: Editor) {
                    self.editor = editor
                },
                menubar: false,
                plugins: 'print',
                toolbar,
                
                setup: (editor) => {
                    editor.addButton("copyall", {
                        text: "Copy Text",
                        onclick: () => {
                            editor.selection.select(editor.getBody(), true)
                            editor.execCommand("copy", false)
                            editor.selection.collapse(true)
                            Vue.toasted.info("Copied to clipboard")
                            this.$emit("text-copied", editor.getContent())
                        }
                    })

                    editor.addButton('downloaddocx', {
                        text: 'DocX',
                        title: 'Save as Word document (experimental)',
                        onclick: () => {
                            const content = editor.getContent()
                            utils.request
                            // .post('/md-docx/', false, { md: self.finalMarkup })
                            .post('/html-docx/', false, { html: content })
                            .responseType('blob')
                            .then(res => {
                                const mime = res.header['content-type']
                                const blob = new Blob([res.body], { type: mime })
                                const dlLink = document.createElement('a')
                                dlLink.href = window.URL.createObjectURL(blob)
                                const prefix = this.stay ? `mrn_${this.stay.patient.mrn}_` : ''
                                dlLink.download = `${prefix}dt_${moment().format('YYYYMMDD_HHmm')}_notes.docx`
                                document.body.appendChild(dlLink)
                                dlLink.click()
                                document.body.removeChild(dlLink)
                                this.$emit("text-copied", content)

                                // return utils.request.post('/md-html', false, { md: self.finalMarkup })
                            })
                            .catch(err => {
                                utils.handleRequestError(err)
                            })
                        },
                    })

                    editor.addButton('customprint', {
                        text: 'Print',
                        title: 'Print (experimental): This should work as expected with modern browsers. Support for older browsers cannot be guaranteed.',
                        cmd: 'mcePrint',
                    })

                    editor.addButton('printhelp', {
                        text: 'Print Help',
                        title: 'Print Help',
                        icon: 'help',
                        onclick: () => {
                            this.$bvModal.show(this.printHelpHtmlID)
                        }
                    })

                    editor.addButton('useunderlined', {
                        text: 'Bold > Underline',
                        title: 'Changes bold text to underlined. If a recent note is selected, this has no effect.',
                        onclick: function() {
                            const btn = this as tinymce.ui.Control
                            self.useUnderline = !self.useUnderline
                            btn.active(self.useUnderline)
                        },
                        onpostrender: function() {
                            const btn = this as tinymce.ui.Control
                            editor.on('SetContent', function(e: { content: string, selection: boolean }) {
                                if (self.useCustomHtml)
                                    btn.active(false)
                                else
                                    btn.active(self.useUnderline)
                                btn.disabled(self.useCustomHtml)
                            })
                        },
                    })
                },
            }

            tinymce.init(options)
        },
        removeEditor() {
            if (this.editor)
                this.editor.remove()
        },
        refreshEditor() {
            if (this.editorH > 0) {
                Vue.toasted.show('[technical] Refreshing editor...')
                this.removeEditor()
                this.$nextTick(() => this.initEditor())
            }
        },
        updateValue(val: any) {
            this.$emit('input', val)
        },
        $_setEditorContent(val: string) {
            if (this.editor) {
                if (this.debugMode)
                    this.$toasted.show('editor exists, setting content')
                this.editor.setContent(val)
                this.editor.save({})
            }
            else {
                if (this.debugMode)
                    this.$toasted.show('editor not available')
            }
        }
    },
})
