import { filter, find, get, isEqual } from 'lodash-es'
import { TextDetails } from './interfaces'
import { doPlainIndent, freeTextSubHtmlBreaks } from './helpers'
import { NIHSS, NIHSS_ADM_DISPLAY, NIHSS_24_72_HRS_TITLE, NIHSS_24_72_HRS_DISPLAY } from 'models/data/nihss'
import utils from 'utils'
import store from 'store'

export function visualFields(val: Array<number>): string | undefined {
    if (val === null || isEqual(val, [0,0,0,0,0,0,0,0]))
        return
    if (isEqual(val, [1,0,0,0,1,0,0,0]))
        return "Right superior quadrantanopia."
    if (isEqual(val, [0,1,0,0,0,1,0,0]))
        return "Left superior quadrantanopia."
    if (isEqual(val, [0,0,1,0,0,0,1,0]))
        return "Right inferior quadrantanopia."
    if (isEqual(val, [0,0,0,1,0,0,0,1]))
        return "Left inferior quadrantanopia."
    if (isEqual(val, [1,0,1,0,1,0,1,0]))
        return "Right homonymous hemianopia."
    if (isEqual(val, [0,1,0,1,0,1,0,1]))
        return "Left homonymous hemianopia."
    if (isEqual(val, [1,1,1,1,1,1,1,1]))
        return "Complete blindness."
    if (isEqual(val, [1,0,1,0,0,1,0,1]))
        return "Bi-temporal hemianopia."
    if (isEqual(val, [0,1,0,1,1,0,1,0]))
        return "Bi-nasal hemianopia."
    if (isEqual(val, [1,1,1,1,0,0,0,0]))
        return "Blind right eye."
    if (isEqual(val, [0,0,0,0,1,1,1,1]))
        return "Blind left eye."
    if (isEqual(val, [1,0,0,0,0,1,0,0]))
        return "Bi-temporal superior quadrantanopia."
    if (isEqual(val, [0,1,0,0,1,0,0,0]))
        return "Bi-nasal superior quadrantanopia."
    if (isEqual(val, [0,0,1,0,0,0,0,1]))
        return "Bi-temporal inferior quadrantanopia."
    if (isEqual(val, [0,0,0,1,0,0,1,0]))
        return "Bi-nasal inferior quadrantanopia."
    return "Unknown visual fields combination."
}

export function visualInattention(val: Array<number>): string | undefined {
    if (val === null || isEqual(val, [0,0,0,0,0,0,0,0]))
        return
    if (isEqual(val, [1,0,0,0,1,0,0,0]))
        return "Visual inattention right superior quadrant."
    if (isEqual(val, [0,1,0,0,0,1,0,0]))
        return "Visual inattention left superior quadrant."
    if (isEqual(val, [0,0,1,0,0,0,1,0]))
        return "Visual inattention right inferior quadrant."
    if (isEqual(val, [0,0,0,1,0,0,0,1]))
        return "Visual inattention left inferior quadrant."
    if (isEqual(val, [1,0,1,0,1,0,1,0]))
        return "Visual inattention right side."
    if (isEqual(val, [0,1,0,1,0,1,0,1]))
        return "Visual inattention left side."
    return "Unknown visual inattention combination."
}

interface NihssOptions {
    nihssKey?: string
    nihssID?: number
    /** if true, generates description and NIHSS incomplete warning */
    advancedDesc?: boolean
    heading: string
    deficitsOnly?: boolean
    plain?: boolean
}

export function nihssTitleToDisplay(title: string): string {
    return title === NIHSS_24_72_HRS_TITLE ? NIHSS_24_72_HRS_DISPLAY : title
}

export function nihssLabelText(nihssId: number | null): string {
    const label = find(store.direct.getters.templates.allNihssLabels, ['id', nihssId])
    return label ? nihssTitleToDisplay(label.title) : '-'
}

/**
 * Returns the NIHSS description. Either returns an associated label, or derives it from assessed_at/updated_at
 * @param nihss 
 */
export function nihssDesc(nihss: NIHSS): string {
    if (nihss.stage === 'ADMISSION')
        return NIHSS_ADM_DISPLAY

    if (nihss.label)
        return nihssLabelText(nihss.label)

    let desc: string = ''

    const dt_s = nihss.assessed_at || nihss.updated_at
    desc = utils.niceDateShort(dt_s)

    let stageLabel: string = ''
    if (nihss.stage === 'NURSE_REVIEW')
        stageLabel = 'Nurse'
    else if (nihss.stage === 'WARD')
        stageLabel = 'Ward'

    if (!!stageLabel)
        desc = `${desc} [${stageLabel}]`

    return desc || ''
}

/**
 * Returns at least the NIHSS description. Includes the score if the NIHSS is complete.
 * @param nihss 
 */
export function nihssDescAndScore(nihss: NIHSS): string {
    const plainDesc = nihssDesc(nihss)
    return nihss.complete ? `${plainDesc} (${nihss.score})` : plainDesc
}

export function nihss(
    { stay }: TextDetails,
    { nihssKey, nihssID, advancedDesc, heading, deficitsOnly, plain } : NihssOptions
): string {
    if (!stay) return ''

    let nihss: NIHSS | undefined
    if (nihssID)
        nihss = find(stay.nihss_set, { id: nihssID })
    else if (nihssKey)
        nihss = get(stay, nihssKey)

    if (!nihss) return ''

    const boldIssues = store.direct.state.session.ux_nihss_bold

    if (deficitsOnly === undefined)
        deficitsOnly = false

    function bold(base_str: string): string {
        if (boldIssues)
            return plain ? `\\<${base_str}\\>` : `**${base_str}**`
        return base_str
    }

    const headerLines: string[] = []
    if (heading)
        headerLines.push(`**${heading}**:`)

    let scoreLine = `NIHSS score - ${nihss.score}`

    if (advancedDesc) {
        const desc = nihssDesc(nihss)
        if (desc)
            headerLines.push(`${desc}:`)

        if (!nihss.complete)
            scoreLine += `. [Incomplete. ${nihss.questions_completed || 0}/24 fields answered]`
    }

    headerLines.push(scoreLine)

    let lines: string[] = []

    // Consciousness
    if (nihss.consciousness) {
        const consciousnessTextMap = {
            "ALERT": "Alert.",
            "MINOR_STIMULATION": bold("Drowsy."),
            "PAINFUL_STIMULI": bold("Decreased LOC."),
            "UNRESPONSIVE": bold("Coma.")
        }
        const consciousnessText = get(consciousnessTextMap, nihss.consciousness, bold("Unknown consciousness value."))

        if (!(deficitsOnly && nihss.consciousness === 'ALERT'))
            lines.push(consciousnessText)
    }

    // Language
    if (nihss.language) {
        const languageTextMap = {
            "NORMAL": "No aphasia.",
            "LOSS_OF_FLUENCY": bold("Mild-to-moderate aphasia."),
            "SEVERE_APHASIA": bold("Severe aphasia."),
            "UNABLE_TO_SPEAK": bold("Mute.")
        }
        const languageText = get(languageTextMap, nihss.language, bold("Unknown aphasia value."))

        if (!(deficitsOnly && nihss.language === 'NORMAL'))
            lines.push(languageText)
    }

    // Dysarthria
    if (nihss.dysarthria) {
        const dysarthriaTextMap = {
            "NORMAL": "No dysarthria.",
            "MILD": bold("Dysarthric, but intelligible speech."),
            "SEVERE": bold("Severe dysarthria."),
        }
        const dysarthriaText = get(dysarthriaTextMap, nihss.dysarthria, bold("Unknown dysarthria value."))

        if (!(deficitsOnly && nihss.dysarthria === 'NORMAL'))
            lines.push(dysarthriaText)
    }

    // Age & month
    let ageMonthText: string[] = []

    if (nihss.age === false && !deficitsOnly) {
        ageMonthText.push("Oriented to age.")
    } else if (nihss.age === true) {
        ageMonthText.push(bold("Not oriented to age."))
    }

    if (nihss.month === false && !deficitsOnly) {
        ageMonthText.push("Oriented to month.")
    } else if (nihss.month === true) {
        ageMonthText.push(bold("Not oriented to month."))
    }

    if (ageMonthText.length) {
        lines.push(ageMonthText.join(" "))
    }

    // Eyes & fist
    let eyesFistText: string[] = []

    if (nihss.close_open_eyes === false && !deficitsOnly) {
        eyesFistText.push("Able to open and close eyes on command.")
    } else if (nihss.close_open_eyes === true) {
        eyesFistText.push(bold("Unable to open and close eyes on command."))
    }

    if (nihss.make_a_fist === false && !deficitsOnly) {
        eyesFistText.push("Able to open and close fist on command.")
    } else if (nihss.make_a_fist === true) {
        eyesFistText.push(bold("Unable to open and close fist on command."))
    }

    if (eyesFistText.length) {
        lines.push(eyesFistText.join(" "))
    }

    // Visual fields
    let visionText: string[] = []
    if (nihss.visual_fields_normal && !deficitsOnly) {
        visionText.push("No visual field loss.")
    } else if (nihss.visual_fields) {
        const vfText = visualFields(nihss.visual_fields)
        if (vfText) visionText.push(bold(vfText))
    }

    // Visual inattention
    if (nihss.visual_inattention_normal && !deficitsOnly) {
        visionText.push("No visual neglect.")
    } else if (nihss.visual_inattention) {
        const viText = visualInattention(nihss.visual_inattention)
        if (viText) visionText.push(bold(viText))
    }

    // Gaze
    const gazeTextMap: { [key: string]: string } = {
        "UNABLE_TO_LOOK_RIGHT": bold("Unable to look completely to the right."),
        "UNABLE_TO_LOOK_LEFT": bold("Unable to look completely to the left."),
        "FORCED_DEVIATION_TO_RIGHT": bold("Forced eye deviation to the right."),
        "FORCED_DEVIATION_TO_LEFT": bold("Forced eye deviation to the left."),
        "NORMAL": "Normal gaze."
    }
    if (nihss.gaze) {
        const gazeText = get(gazeTextMap, nihss.gaze, bold("Unknown gaze value."))

        if (!(deficitsOnly && nihss.gaze === 'NORMAL'))
            visionText.push(gazeText)
    }

    if (visionText.length) lines.push(visionText.join(" "))

    // Face strength
    let faceText: string[] = []
    function faceParalysisText(value: string, side: string): string {
        const facialTextMap = {
            "MINOR_PARALYSIS": `Minor ${side.toLowerCase()} facial weakness.`,
            "PARTIAL_PARALYSIS": `${side} partial facial palsy (UMN pattern).`,
            "TOTAL_PARALYSIS": `${side} complete facial palsy (LMN pattern).`
        }
        return get(facialTextMap, value, "Unknown facial strength value.")
    }

    if (nihss.facial_strength_left === "NORMAL" && nihss.facial_strength_right === "NORMAL" && !deficitsOnly) {
        faceText.push("No facial palsy.")
    } else {
        if (nihss.facial_strength_right && nihss.facial_strength_right !== "NORMAL") {
            faceText.push(bold(faceParalysisText(nihss.facial_strength_right, "Right")))
        }
        if (nihss.facial_strength_left && nihss.facial_strength_left !== "NORMAL") {
            faceText.push(bold(faceParalysisText(nihss.facial_strength_left, "Left")))
        }
    }

    if (faceText.length) lines.push(faceText.join(" "))

    // Arm/leg strength
    function armText(value: string, side: string): string {
        const sideLC = side.toLowerCase()
        const armTextMap = {
            "NO_DRIFT": `No ${sideLC} arm drift.`,
            "MINOR_DRIFT": bold(`${side} arm drift.`),
            "DRIFT": bold(`Able to keep ${sideLC} arm off bed but for <10 sec.`),
            "FALLS_IMMEDIATELY": bold(`Unable to get ${sideLC} arm off bed.`),
            "NO_MOVEMENT": bold(`No movement ${sideLC} arm.`),
        }
        if (deficitsOnly && value === 'NO_DRIFT')
            return ''
        return get(armTextMap, value, bold(`Unknown ${sideLC} arm strength value.`))
    }

    function legText(value: string, side: string): string {
        const sideLC = side.toLowerCase()
        const legTextMap = {
            "NO_DRIFT": `No ${sideLC} leg drift.`,
            "MINOR_DRIFT": bold(`${side} leg drift.`),
            "DRIFT": bold(`Able to keep ${sideLC} leg off bed but for <5 sec.`),
            "FALLS_IMMEDIATELY": bold(`Unable to get ${sideLC} leg off bed.`),
            "NO_MOVEMENT": bold(`No movement ${sideLC} leg.`),
        }
        if (deficitsOnly && value === 'NO_DRIFT')
            return ''
        return get(legTextMap, value, bold(`Unknown ${sideLC} leg strength value.`))
    }

    // Right arm/leg
    let rightSideText: string[] = []
    if (nihss.arm_strength_right) {
        rightSideText.push(armText(nihss.arm_strength_right, "Right"))
    }
    if (nihss.leg_strength_right) {
        rightSideText.push(legText(nihss.leg_strength_right, "Right"))
    }

    if (rightSideText.length) lines.push(filter(rightSideText).join(" "))

    // Left arm/leg
    let leftSideText: string[] = []
    if (nihss.arm_strength_left) {
        leftSideText.push(armText(nihss.arm_strength_left, "Left"))
    }
    if (nihss.leg_strength_left) {
        leftSideText.push(legText(nihss.leg_strength_left, "Left"))
    }

    if (leftSideText.length) lines.push(filter(leftSideText).join(" "))

    // Ataxia arms
    const armAtaxiaText: string[] = []
    if (nihss.ataxia_arm_right === false && !deficitsOnly) {
        armAtaxiaText.push("No limb ataxia present in right arm.")
    } else if (nihss.ataxia_arm_right === true) {
        armAtaxiaText.push(bold("Limb ataxia present in right arm."))
    }

    if (nihss.ataxia_arm_left === false && !deficitsOnly) {
        armAtaxiaText.push("No limb ataxia present in left arm.")
    } else if (nihss.ataxia_arm_left === true) {
        armAtaxiaText.push(bold("Limb ataxia present in left arm."))
    } 

    if (armAtaxiaText.length) lines.push(armAtaxiaText.join(" "))

    // Ataxia legs
    let legAtaxiaText: string[] = []
    if (nihss.ataxia_leg_right === false && !deficitsOnly) {
        legAtaxiaText.push("No limb ataxia present in right leg.")
    } else if (nihss.ataxia_leg_right === true) {
        legAtaxiaText.push(bold("Limb ataxia present in right leg."))
    }

    if (nihss.ataxia_leg_left === false && !deficitsOnly) {
        legAtaxiaText.push("No limb ataxia present in left leg.")
    } else if (nihss.ataxia_leg_left === true) {
        legAtaxiaText.push(bold("Limb ataxia present in left leg."))
    }

    if (legAtaxiaText.length) lines.push(legAtaxiaText.join(" "))

    // Sensation
    let sensationText: string[] = []

    function sensoryLossText(value: string, side: string): string {
        const textMap = {
            "NONE": `No sensory loss on ${side}.`,
            "DULL": bold(`Decreased ${side} sensation.`),
            "SEVERE": bold(`Absent ${side} sensation.`),
        }
        if (deficitsOnly && value === 'NONE')
            return ''
        return get(textMap, value, bold(`Unknown ${side} side sensory loss value.`))
    }
    if (nihss.sensory_loss_right) {
        sensationText.push(sensoryLossText(nihss.sensory_loss_right, "right"))
    }
    if (nihss.sensory_loss_left) {
        sensationText.push(sensoryLossText(nihss.sensory_loss_left, "left"))
    }

    function sensoryInattentionText(value: string, side: string): string {
        const textMap = {
            "NONE": `No sensory neglect on ${side.toLowerCase()}.`,
            "INATTENTION": bold(`${side} sensory neglect.`),
        }
        if (deficitsOnly && value === 'NONE')
            return ''
        return get(textMap, value, bold(`Unknown ${side} side sensory loss value.`))
    }
    if (nihss.sensory_inattention_right) {
        sensationText.push(sensoryInattentionText(nihss.sensory_inattention_right, "Right"))
    }
    if (nihss.sensory_inattention_left) {
        sensationText.push(sensoryInattentionText(nihss.sensory_inattention_left, "Left"))
    }

    if (sensationText.length) lines.push(filter(sensationText).join(" "))

    // Notes
    if (nihss.notes) {
        lines.push(freeTextSubHtmlBreaks(nihss.notes))
    }

    if (nihss.questions_completed || lines.length) {
        const headerLine = headerLines.join('  \n')
        if (headerLine) lines.unshift(headerLine)
        if (plain)
            return doPlainIndent(lines.join('  \n'))
        return lines.join("  \n") + "\n\n"
    }
    return ""

}

export function admissionNihss(textDetails: TextDetails, options?: Partial<NihssOptions>): string {
    return nihss(textDetails, {nihssKey: "admission_nihss", heading: 'On examination', ...options})
}

export function wardRoundNihss(textDetails: TextDetails, options?: Partial<NihssOptions>): string {
    if (textDetails.stay && textDetails.stay.ward_round_nihss)
        return nihss(textDetails, { nihssKey: "ward_round_nihss", heading: 'Exam', ...options })
    return ""
}

export default {
    visualFields,
    visualInattention,
    nihss,
    admissionNihss,
    wardRoundNihss,
}
