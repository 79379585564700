

























import Vue from 'vue'
import stays from "@store/stays"

import { Stay } from 'models/data/stay'
import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'

import Questions from '@stayinputs/Questions.vue'
import TextArea from "@shared/inputs/TextArea.vue"


export default Vue.extend({
    components: {
        Questions,
        TextArea,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        syndrome(): Syndrome | undefined {
            return stays.getSyndrome(this.stay_id)
        },
        allergies: {
            get(): string {
                return this.stay?.historical_meds?.allergies || ''
            },
            set(text: string) {
                stays.extActSetPathValue(this.stay_id, 'historical_meds.allergies', text)
            },
        },
        showAllergies(): boolean {
            return !!this.allergies
        },
        otherMeds: {
            get(): string {
                return this.stay?.historical_meds?.other_meds || ''
            },
            set(text: string) {
                stays.extActSetPathValue(this.stay_id, 'historical_meds.other_meds', text)
            },
        },
    },
})
