/**
 * A store for user settings.
 */
import Vue from 'vue'
import { defineModule } from "direct-vuex"
import { moduleGetterContext as rootModuleGetterContext, moduleActionContext as rootModuleActionContext } from 'store'
import { assign, debounce, each, every, filter, find, get, includes, has, pick, map, mapValues, some } from 'lodash-es'
import utils from 'utils'
import stays from '@store/stays'
import { safeDecoding } from 'utils/decoders'
import { Speciality } from 'models/med_templates/speciality'
import { UserState, userStateDecoder, StayFilters, StayFiltersParams, Hyperacutes } from 'models/store/user'
import { Hospital } from 'models/med_templates/hospital'

/** Pushes profile to the backend server */
function push(payload: UserState) {
    return utils.request.patch("/account/ux_prefs/", false, payload)
}

/** Debounced - pushes profile to the backend server
 *  Implemented this way as a debouncing on the trailing edge initially returns undefined for Promise-like functions
*/
const pushDebounced = debounce((payload: UserState, resolve, reject) => {
    push(payload).then(resolve).catch(reject)
}, 2000)

function getInitState(): UserState {
    return {
        // read-only
        username: '',
        alt_username: '',
        display_name: 'User',
        alt_logins: 0,
        is_alt: false,
        groups: [],
        hospitals: [],
        is_senior: false,
        is_superuser: false,
        is_temp: false,
        pwd_expired: null,

        // superuser-editable-only
        speciality: null,
        telestroke_mode: false,

        // editable, user attribute
        triage_mode: false,

        // actual ux preferences
        anonymise: false,
        appendSig: false,
        asapVizSites: [],
        asmtSidebarDocked: true,
        asmtTextDocked: false,
        breakdowns: {
            hyperacutes: {
                start: null,
                end: null,
                doctors: []
            },
        },
        debug_mode: false,
        extras_mode: false,
        filters: {
            cares: [],
            diagnoses: [],
            doctors: [],
            hospitals: [],
            spaces: [],
            specialities: [],
        },
        hidden_columns: [],
        hidden_stays: [],
        hide_stays: false,
        home_stay_ids: false,
        print_header: true,
        signature: '',
        team: null,
        textAreaContSave: false,
        textOutputPlain: true,
        textOutputWardV1: true,
        typeCheckJSON: false,
    }
}
const initState: UserState = getInitState()

const userModule = defineModule({
    namespaced: true,
    /*
    state: (): UserState => {
        return {
            is_superuser: false,
            groups: [],
        }
    },
    */
    state: (): UserState => {
        return initState
    },
    getters: {
        isDeveloper(...args): boolean {
            const { state, rootState } = moduleGetterContext(args)
            return rootState.session.suspendPrivileges ? false : includes(state.groups, 'Developers')
        },
        isPowerUser(...args): boolean {
            const { state, getters, rootState } = moduleGetterContext(args)
            return rootState.session.suspendPrivileges ? false : (state.is_superuser || getters.isDeveloper)
        },
        canSuspendPrivileges(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.is_superuser || includes(state.groups, 'Developers')
        },
        isClientUserAdmin(...args): boolean {
            const { state } = moduleGetterContext(args)
            return includes(state.groups, 'ClientUserAdmins')
        },
        isTeamsEditor(...args): boolean {
            const { state } = moduleGetterContext(args)
            return includes(state.groups, 'TeamMembersEditors')
        },
        isOnlyClientUserAdmin(...args): boolean {
            const { getters } = moduleGetterContext(args)
            return !getters.isPowerUser && getters.isClientUserAdmin && !getters.isTeamsEditor
        },
        isNurseUser(...args): boolean {
            const { state } = moduleGetterContext(args)
            return includes(state.groups, 'Nurses')
        },
        isTSSiteUser(...args): boolean {
            const { state } = moduleGetterContext(args)
            return includes(state.groups, 'TSSiteUsers')
        },
        betaEnabled(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'BetaUsers')
        },
        canCreateStays(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'StayCreators')
        },
        canChangeHome(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'CanChangeHome')
        },
        canEditPrintHeader(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'CanEditPrintHeader')
        },
        canExportData(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'DataExporters')
        },
        canDoAdvTSActions(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || state.is_senior
        },
        canDataCapture(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'CanDataCapture')
        },
        canPrintDocx(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            return getters.isPowerUser || includes(state.groups, 'CanPrintDocx')
        },
        canUseAuxSite(...args): boolean {
            const { state, getters } = moduleGetterContext(args)
            // ! currently restricted to superusers with the right group
            return getters.isPowerUser && includes(state.groups, 'AuxiliaryUsers')
        },
        hasAdvancedFilters(...args): boolean {
            const { state } = moduleGetterContext(args)
            return includes(state.groups, 'AdvancedFilters')
        },
        fullMode(...args): boolean {
            const { state } = moduleGetterContext(args)
            return !state.is_temp && !state.telestroke_mode
        },
        hospitals(...args): Hospital[] {
            const { state, rootState } = moduleGetterContext(args)
            const allHospitals = rootState.templates.hospitals
            return filter(allHospitals, h => includes(state.hospitals, h.id))
        },
        stayFilters(...args): StayFiltersParams {
            const { state, getters } = moduleGetterContext(args)
            const validFilters: (keyof StayFiltersParams)[] = ['cares', 'diagnoses', 'doctors', 'hospitals', 'spaces', 'specialities']
            const filterObj: StayFilters = pick(state.filters, validFilters)  // clean up/discard unused properties

            return mapValues(filterObj, (val, key) => {
                if (!getters.hasAdvancedFilters) {
                    if (includes(['cares', 'diagnoses'], key))
                        return 'all'
                }
                if (Array.isArray(val) && val.length > 0)
                    return val
                return 'all'
            })
        },
        allSpecialities(...args): Speciality[] {
            const { rootState } = moduleGetterContext(args)
            return rootState.templates.specialities
        },
        specialities(...args): (Speciality | undefined)[] {
            const { getters, state } = moduleGetterContext(args)
            return map(state.filters.specialities, spId => find(getters.allSpecialities, { id: spId }))
        },
        /** Return the speciality if one and only one speciality is selected. */
        speciality(...args): Speciality | undefined {
            const { getters, state } = moduleGetterContext(args)
            const specialities = state.filters.specialities
            let speciality: Speciality | undefined
            if (specialities.length === 1)
                speciality = find(getters.allSpecialities, { id: specialities[0] })
            return speciality
        },
        neuroSelected(...args): boolean {
            const { getters } = moduleGetterContext(args)
            return some(getters.specialities, speciality => speciality?.title.toLowerCase() === 'neurology')
        },
        /**
         * Returns true if:
         * - the system has only one speciality, and it is *Neurology*
         * - *only* Neurology is selected in specialities
         */
        onlyNeuro(...args): boolean {
            const { getters } = moduleGetterContext(args)

            if (getters.allSpecialities.length === 1
                && getters.allSpecialities[0].title.toLowerCase() === 'neurology')
                return true

            return getters.speciality?.title.toLowerCase() === 'neurology' || false
        },
        /** Returns true if *only* Orthopaedics is selected in specialities */
        onlyOrtho(...args): boolean {
            const { getters } = moduleGetterContext(args)
            const speciality = getters.speciality
            return speciality ? speciality.title.toLowerCase() === 'orthopaedics' : false
        },
        /** Returns true if Orthopaedics is selected in specialities */
        orthoSelected(...args): boolean {
            const { getters } = moduleGetterContext(args)
            return some(getters.specialities, speciality => speciality?.title.toLowerCase() === 'orthopaedics')
        },
        textOutputPlain(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.textOutputPlain === undefined ? true : state.textOutputPlain
        },
        textWardV1(...args): boolean {
            const { state } = moduleGetterContext(args)
            return state.textOutputWardV1 === undefined ? true : state.textOutputWardV1
        },
    },
    mutations: {
        updateProfile(state, payload: Partial<UserState>) {
            each(payload, (val, key) => {
                if (has(state, key) && !utils.isEqual(get(state, key), val))
                    Vue.set(state, key, val)
            })
        },
        resetProfile(state) {
            assign(state, getInitState())
        },
        setAnonymise(state, mode: boolean) {
            state.anonymise = mode
        },
        setAsmtSidebarDocked(state, docked: boolean) {
            state.asmtSidebarDocked = docked
        },
        setAsmtTextDocked(state, docked: boolean) {
            state.asmtTextDocked = docked
        },
        setTextOutputPlain(state, mode: boolean) {
            state.textOutputPlain = mode
        },
        setTextOutputWardV1(state, mode: boolean) {
            state.textOutputWardV1 = mode
        },
        setGroups(state, groups: string[]) {
            state.groups = groups
        },
        setSignature(state, signature: string) {
            state.signature = signature
        },
        toggleAppendSig(state) {
            state.appendSig = !state.appendSig
        },
        toggleHideStays(state) {
            state.hide_stays = !state.hide_stays
        },
        toggleStayHide(state, stay_id: number) {
            const i = state.hidden_stays.indexOf(stay_id)
            if (i === -1)
                state.hidden_stays.push(stay_id)
            else
                state.hidden_stays.splice(i, 1)
        },
        toggleHomeStayIds(state) {
            state.home_stay_ids = !state.home_stay_ids
        },
        togglePrintHeader(state) {
            state.print_header = !state.print_header
        },
        toggleTextAreaContSave(state) {
            state.textAreaContSave = !state.textAreaContSave
        },
        toggleTypeCheckJSON(state) {
            state.typeCheckJSON = !state.typeCheckJSON
        },
        updateFilters(state, filters: Partial<StayFilters>) {
            assign(state.filters, filters)
        },
        updateHyperacutes(state, filters: Partial<Hyperacutes>) {
            assign(state.breakdowns.hyperacutes, filters)
        },
    },
    actions: {
        pull(context): Promise<void> {
            const { commit } = moduleActionContext(context)
            return utils.request
                .get('/account/ux_prefs/')
                .then(res => {
                    const data = res.body
                    const profile = safeDecoding(data, userStateDecoder, 'store/user.pull', data.typeCheckJSON || false)
                    commit.updateProfile(profile)
                    return Promise.resolve()
                })
                .catch(err => {
                    utils.handleRequestError(err)
                })
        },
        /** Update the local module state, then push to the backend. */
        updatePush(context, payload?: Partial<UserState>): void {
            const { commit, state } = moduleActionContext(context)

            if (payload) {
                const same = every(Object.keys(payload), key => utils.isEqual(get(payload, key), get(state, key)))
                if (same) return
                commit.updateProfile(payload)
            }

            pushDebounced(
                state,
                (res: any) => {
                    commit.updateProfile(res.body)
                    Vue.toasted.info('User preferences updated.')
                },
                (err: any) => { utils.handleRequestError(err) }
            )
        },
        pushNow(context): Promise<void> {
            const { commit, state } = moduleActionContext(context)
            return push(state)
                .then(res => {
                    commit.updateProfile(res.body)
                    Vue.toasted.info('User preferences updated.')
                })
                .catch(err => {
                    utils.handleRequestError(err)
                })
        },
        /** clears filters.hospitals and pushes immediately to the backend */
        clearHospitals(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            if (!state.filters.hospitals.length) return
            dispatch.updateFilters({ hospitals: [] })
        },
        /** resets hide_stays and pushes immediately to the backend */
        resetHideStays(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            if (state.hide_stays) {
                commit.updateProfile({ hide_stays: true })
                dispatch.pushNow()
            }
        },
        setAnonymise(context, mode: boolean): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.setAnonymise(mode)
            dispatch.updatePush()
        },
        setAsmtSidebarDocked(context, docked: boolean): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.setAsmtSidebarDocked(docked)
            dispatch.updatePush()
        },
        setAsmtTextDocked(context, docked: boolean): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.setAsmtTextDocked(docked)
            dispatch.updatePush()
        },
        setTextOutputWardV1(context, mode: boolean): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.setTextOutputWardV1(mode)
            dispatch.updatePush()
        },
        setTextOutputPlain(context, mode: boolean): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.setTextOutputPlain(mode)
            dispatch.updatePush()
        },
        toggleAppendSig(context): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.toggleAppendSig()
            dispatch.updatePush()
        },
        toggleDebugMode(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            commit.updateProfile({ debug_mode: !state.debug_mode })
            dispatch.updatePush()
        },
        toggleExtrasMode(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            commit.updateProfile({ extras_mode: !state.extras_mode })
            dispatch.updatePush()
        },
        toggleHideStays(context): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.toggleHideStays()
            dispatch.updatePush()
        },
        toggleStayHide(context, stay_id: number): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.toggleStayHide(stay_id)
            dispatch.updatePush()
        },
        toggleHomeStayIds(context): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.toggleHomeStayIds()
            dispatch.updatePush()
        },
        togglePrintHeader(context): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.togglePrintHeader()
            dispatch.updatePush()
        },
        /** Toggle telestroke mode. */
        toggleTSMode(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            commit.updateProfile({ telestroke_mode: !state.telestroke_mode })
            dispatch.pushNow()
                .then(stays.mutTriggerLoadStays)
        },
        /** Toggle triage mode. */
        toggleTriageMode(context): void {
            const { state, commit, dispatch } = moduleActionContext(context)
            commit.updateProfile({ triage_mode: !state.triage_mode })
            dispatch.pushNow()
                .then(stays.mutTriggerLoadStays)
        },
        toggleTypeCheckJSON(context): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.toggleTypeCheckJSON()
            dispatch.updatePush()
        },
        updateFilters(context, filters: Partial<StayFilters>): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.updateFilters(filters)
            dispatch.updatePush()
        },
        updateHyperacutes(context, filters: Partial<Hyperacutes>): void {
            const { commit, dispatch } = moduleActionContext(context)
            commit.updateHyperacutes(filters)
            dispatch.updatePush()
        },
    },
})

export default userModule

const moduleGetterContext = (args: [any, any, any, any]) => rootModuleGetterContext(args, userModule)
const moduleActionContext = (context: any) => rootModuleActionContext(context, userModule)
