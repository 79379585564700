








































import Vue from 'vue'

import DataCaptureTab from './DataCaptureTab.vue'
import Discharge from './ward/Discharge.vue'
import DischargeText from '@text/Discharge.vue'
import AllInvestigations from '@sections/investigations/AllInvestigations.vue'
import TextAreaEnhanced from '@inputs/TextAreaEnhanced.vue'
import WardRound from './ward/WardRound.vue'
import WardText from '@text/Ward.vue'

export default Vue.extend({
    components: {
        DataCaptureTab,
        Discharge,
        DischargeText,
        AllInvestigations,
        TextAreaEnhanced,
        WardRound,
        WardText,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        tab: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
            dischargeOpened: this.tab === 'discharge',
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        asmtVisible(): boolean {
            return this.$store.direct.state.session.asmtVisible
        },
        notesDocked(): boolean {
            return this.$store.direct.state.user.asmtTextDocked
        },
    },
})
