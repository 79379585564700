
























import mixins from 'vue-typed-mixins'
import InputMixin from '@mixins/Input.vue'
import { filter, map } from 'lodash-es'

import stays from '@store/stays'

import Dropdown from '@shared/inputs/Dropdown.vue'
import { Item } from 'models/base'


export default mixins(InputMixin).extend({
    components: {
        Dropdown,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        showInstructions: {
            type: Boolean,
            default: true
        },
    },
    computed: {
        followUpIn: {
            get(): number {
                return this.getFieldVal(this.stay_id, 'discharge.follow_up_time')
            },
            set(val: number) {
                this.setFieldVal(this.stay_id, 'discharge.follow_up_time', val)
            }
        },
        followUpInChoices(): Item[] {
            const speciality = stays.getSpeciality(this.stay_id)
            if (!speciality) return []
            return speciality.follow_up_times
        },
        followUpWith: {
            get(): number {
                return this.getFieldVal(this.stay_id, 'discharge.follow_up_with')
            },
            set(choice: number) {
                this.setFieldVal(this.stay_id, 'discharge.follow_up_with', choice)
            }
        },
        followUpWithOptions(): { title: string, id: number, note?: string }[] {
            let choices = this.$store.direct.state.templates.followUpWith
            const speciality = stays.getSpeciality(this.stay_id)
            if (speciality)
                choices = filter(choices, ['speciality', speciality.id])

            return map(choices, choice => ({
                title: choice.title,
                id: choice.id,
                note: (this.showInstructions && !!choice.instruction) ? choice.instruction : undefined,
            }))
        },
    },
})
