var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.stay
    ? _c(
        "edit-modal",
        {
          attrs: { stay_id: _vm.stay.id },
          scopedSlots: _vm._u(
            [
              {
                key: "text",
                fn: function() {
                  return [
                    _vm.summary
                      ? _c("div", {
                          domProps: { innerHTML: _vm._s(_vm.summary) }
                        })
                      : _c("div", [_vm._v("Nil")])
                  ]
                },
                proxy: true
              }
            ],
            null,
            false,
            1961825327
          )
        },
        [
          _vm._v(" "),
          _c("social-history", { attrs: { stay_id: _vm.stay.id } })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }