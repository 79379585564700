





































import Vue from 'vue'
import utils from 'utils'
export default Vue.extend({
    data() {
        return {
            htmlID: `${utils.getUID()}___print_help`,
        }
    },
    computed: {
        isIE11(): boolean {
            return window.scrawl.isIE11
        },
    },
    mounted() {
        this.$emit('html-id', this.htmlID)
    },
})
