import { Item, OrderedItem, item } from '../base'
import { Diagnosis, diagnoses } from "./diagnosis"
import { QuestionGroup, questionGroup } from './question'
import { string as dstring, number as dnumber, boolean as dboolean, nullable, array, exact, guard } from 'decoders'


export interface Feature extends Item {}

export interface Syndrome extends OrderedItem {
    speciality: number
    diagnoses: Diagnosis[]
    /** default discharge plans IDs */
    discharge_plans: number[]
    features: Feature[]
    baseline_investigations: number[]
    is_rapid_assessment: boolean
    link: string | null
    link_text: string | null
    medication_categories: number[]
    placeholder_txt: string | null
    question_groups: QuestionGroup[]
}

export type Syndromes = Syndrome[]

export interface RapidAssessmentSyndrome extends Item {
    speciality: number
    placeholder_txt: string
    features: Feature[]
    question_groups: QuestionGroup[]
    diagnoses: Diagnosis[]
    is_rapid_assessment: boolean
    link: string | null
    link_text: string | null
}

export const syndrome = exact({
    id: dnumber,
    order: dnumber,
    diagnoses: diagnoses,
    discharge_plans: array(dnumber),
    features: array(item),
    baseline_investigations: array(dnumber),
    is_rapid_assessment: dboolean,
    link: nullable(dstring),
    link_text: nullable(dstring),
    medication_categories: array(dnumber),
    placeholder_txt: nullable(dstring),
    question_groups: array(questionGroup),
    speciality: dnumber,
    title: dstring,
})

const syndromeDecoder = guard(syndrome, { style: 'simple' })
export const syndromesDecoder = guard(array(syndrome), { style: 'simple' })

try { const _syndrome: Syndrome = syndromeDecoder({}) } catch(e) {}
