var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.isTSSiteUser
    ? _c("dropdown", {
        staticClass: "mb-0",
        attrs: {
          label: "Scope",
          items: _vm.TSScope_items,
          valueField: "value"
        },
        model: {
          value: _vm.tsScope_,
          callback: function($$v) {
            _vm.tsScope_ = $$v
          },
          expression: "tsScope_"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }