








































































import mixins from 'vue-typed-mixins'
import { get } from 'lodash-es'

import utils from 'utils'
import { safeDecoding } from 'utils/store'
import stays from '@store/stays'

import { VizData, vizDataDecoder } from 'models/dataviz'

import DelayRouteLeaveMixin from '@shared/DelayRouteLeaveMixin.vue'
import DataTables from './DataTables.vue'
import DatesControls from '../OpsDatesControls.vue'
import DoctorsFilter from '@dashboards/DoctorsFilterIgnorePrefs.vue'
import FilterHospitals from '@shared/FilterHospitals.vue'
import HyperacuteReview from '../HyperacuteReview.vue'
import StayEditModal from './StayEditModal.vue'
import StrokesNurseReviewed from './StrokesNurseReviewed.vue'
import tables from '../tables'


export default mixins(DelayRouteLeaveMixin).extend({
    components: {
        DataTables,
        DatesControls,
        DoctorsFilter,
        FilterHospitals,
        HyperacuteReview,
        StayEditModal,
        StrokesNurseReviewed,
    },
    props: {
        start: {
            type: String,
            required: false,
        },
        end: {
            type: String,
            required: false,
        },
    },
    data() {
        return {
            activeTab: 0,
            data: null as VizData | null,
            loading: false,
            doctors: [] as number[],
        }
    },
    computed: {
        hospitals(): number[] {
            return this.$store.direct.state.user.filters.hospitals
        },
        tables(): Object {
            let dataTables: Object = tables
            return dataTables
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        presentationTypes(): string[] {
            if (!this.data) return []
            return get(this.data, 'presentation_types') || []
        },
        notReady(): boolean {
            return this.loading || this.data === null
        },
        editStayID(): number {
            return this.$store.direct.state.session.dataVizStayID
        },
    },
    watch: {
        // refetch data when the route path changes
        // (ignore query parameters which are used to store which modal is open)
        $route(newRoute, oldRoute) {
            if (oldRoute.path === newRoute.path) return 
            this.pull()
        },
        doctors() {
            this.pull()
        },
    },
    created() {
        const params = this.$route.params
        if (params.start && params.end)
            this.pull()
    },
    beforeDestroy() {
        stays.mutClearStays()
    },
    methods: {
        // TODO confirm fetch data for large datasets
        pull() {
            this.loading = true
            utils
                .request
                .get(`/viz/data/${this.start}/${this.end}/`, {
                    h: this.hospitals,
                    d: this.doctors,
                })
                .then(response => {
                    this.data = safeDecoding(response.body, vizDataDecoder, 'v2/Operations.pull')
                    this.loading = false
                })
                .catch(err => {
                    utils.handleRequestError(err)
                    this.loading = false
                })
                .then(this.loadStays)
        },
        loadStays() {
            if (!this.data)
                return

            const stayIDs = this.data.all_stays
            if (!stayIDs.length) {
                stays.mutClearStays()
                return
            }

            stays.actPullStays({
                url: '/viz/stay/',
                params: { s: stayIDs }
            })
        },
        stayEditModalId(stay_id: number): string {
            return `viz-stay-edit-${stay_id}`
        },
        editingDone() {
            this.pull()
        },
        /*
        editingDone(stay_id: number) {
            this.$store.direct.commit.session.clearDataVizEditMode(stay_id)
            this.pull()
        }
        */
    },
})
