










import mixins from 'vue-typed-mixins'
import StayMixin from '@mixins/Stay.vue'
import { Stay } from 'models/data/stay'

export default mixins(StayMixin).extend({
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
    },
    // all the below methods should be consolidated with home/mixins/Stays.vue as a new mixin
    methods: {
        bed(stay: Stay): string {
            return stay.location?.bed || ''
        },
        finalBed(ward: string, bed: string): string {
            if (this.$store.direct.state.session.ux_location_prune_bed) {
                const regex = new RegExp(`^${ward}_`)
                return bed.replace(regex, '')
            }
            return bed
        },
        room(stay: Stay): string {
            return stay.location?.room || ''
        },
        ward(stay: Stay): string {
            return stay.location?.ward || ''
        },
        altWard(ward: string): string | undefined {
            return this.$store.direct.getters.templates.wardLabelsDict[ward]
        },
        finalWard(ward: string) {
            if (this.$store.direct.state.session.ux_location_alt_ward)
                return this.altWard(ward) || ward
            return ward
        },
        locationDesc(stay: Stay): string {
            let ward = this.ward(stay)
            let bed = this.bed(stay)
            bed = this.finalBed(ward, bed)
            ward = this.finalWard(ward)

            if (!!ward && !!bed)
                return `${ward} - ${bed}`
            if (!!ward)
                return ward
            if (!!bed)
                return bed
            return ''
        },
    }
})
