









































































import mixins from "vue-typed-mixins"
import { filter, find, get } from "lodash-es"
import utils from 'utils'

import { ASMT_ADM, ASMT_NURSE, ASMT_WARD } from 'routers/constants'
import stays from "@store/stays"
import { Stay } from 'models/data/stay'
import { Syndrome } from "models/med_templates/syndrome"
import { ViewMode } from 'models/meta'

import DisplayMixin from "../mixins/Display.vue"

export default mixins(DisplayMixin).extend({
    props: {
        speciality_id: {
            type: Number,
            required: true
        },
        stay_id: {
            type: Number,
            required: true
        },
        viewMode: {
            type: String,
            default: ViewMode.normal,
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            routeModalId: `${uid}___choose_route`,
            routeModalVisible: false,
            updating: false,
        }
    },
    computed: {
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        debugMode(): boolean {
            return this.$store.direct.state.user.debug_mode
        },
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        normalMode(): boolean {
            return this.viewMode === ViewMode.normal
        },
        rapidAsmtMode(): boolean {
            return this.viewMode === ViewMode.rapid_asmt
        },
        userCanChooseAsmt(): boolean {
            return !this.telestrokeMode
        },
        syndromes() {
            const templateGetters = this.$store.direct.getters.templates
            const syndromes: readonly Syndrome[] = this.normalMode ? templateGetters.allSyndromes : templateGetters.rapidAsmtSyndromes
            return filter(syndromes, { speciality: this.speciality_id })
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        staySyndromeID: {
            get(): number {
                if (this.normalMode)
                    return this.stay?.syndrome || -1
                else if (this.rapidAsmtMode)
                    return this.stay?.rapid_assessment_syndrome || -1
                return -1
            },
            set(id: number) {
                this.setSyndromeAndRedirect(id)
            }
        },
        staySyndrome(): Syndrome | undefined {
            return find(this.syndromes, ['id', this.staySyndromeID])
        },
        admissionStage(): boolean {
            return this.stay?.stage === "ADMISSION"
        },
        wardStage(): boolean {
            return this.stay?.stage === "WARD"
        },
        nextViewName(): string | undefined {
            switch (this.viewMode) {
                case ViewMode.normal:
                    if (this.telestrokeMode) return this.isTSSiteUser ? ASMT_WARD : ASMT_ADM
                    if (this.admissionStage) return ASMT_ADM
                    else if (this.wardStage) return ASMT_WARD
                    break
                case ViewMode.rapid_asmt:
                    return ASMT_NURSE
            }
            return
        },
        syncMonitorInterval(): number {
            return this.$store.direct.state.session.syncMonitorInterval
        },
        savePending(): boolean {
            return stays.state.stayChanged
        },
    },
    methods: {
        tabSelected(syndromeID: number) {
            return this.staySyndromeID === syndromeID
        },
        resetDiagnosis() {
            // Clear diagnosis value
            const diagnosisKey = this.normalMode ?  'diagnosis' : 'rapid_assessment_diagnosis'
            stays.actSetPathValue({ id: this.stay_id, path: diagnosisKey, val: null })
        },
        setSyndrome(syndromeID: number | null): Promise<void> {
            const syndromePath = this.normalMode ? 'syndrome' : 'rapid_assessment_syndrome'
            const currentSyndrome = get(this.stay, syndromePath)

            if (syndromeID === currentSyndrome)
                this.goToDefaultView()

            // Clear diagnosis if the syndrome has changed
            this.resetDiagnosis()
            return this.normalMode ?
                stays.extActUpdateSyndrome(this.stay_id, syndromeID)
                : stays.extActUpdateRapidAssessmentSyndrome(this.stay_id, syndromeID)
        },
        setSyndromeAndRedirect(syndromeID: number) {
            this.updating = true
            const unknownStage = this.stay?.stage === 'UNKNOWN'

            this.setSyndrome(syndromeID)
            .then(res => {
                setTimeout(() => {
                    const timer = setInterval(() => {
                        if (!this.savePending) {
                            clearInterval(timer)
                            this.updating = false
                            if (this.userCanChooseAsmt && this.normalMode && unknownStage)
                                this.$root.$emit('bv::show::modal', this.routeModalId)
                            else
                                this.goToDefaultView()
                        }
                    }, 100)
                }, this.syncMonitorInterval * 1.5)
            })
        },
        goToView(name: string | undefined) {
            if (name)
                this.$router.push({ name, params: { stay_id: `${this.stay_id}` } })
        },
        goToDefaultView() {
            this.isTSSiteUser ? this.goToWard() : this.goToView(this.nextViewName)
        },
        goToAdmission() {
            this.hideRouteModal()
            this.goToView(ASMT_ADM)
        },
        goToWard() {
            this.hideRouteModal()
            this.goToView(ASMT_WARD)
        },
        hideRouteModal() {
            if (this.routeModalVisible)
                this.$root.$emit('bv::hide::modal', this.routeModalId)
        },
    },
})
