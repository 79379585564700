import { map } from "lodash-es"
import { enumKeys } from 'models/_helpers'
import {
    string as dstring,
    number as dnumber,
    boolean as dboolean,
    constant, exact, guard,
    either, either4, either5, either6,
    optional, oneOf, array,
    dict,
} from 'decoders'


export enum PropType {
    UNKNOWN = '?',
    BOOL = 'boolean',
    STR = 'string',
    TEXT = 'text',
    INT = 'integer',
    FLOAT = 'float',
    DECIMAL = 'decimal',
    NUMBER = 'number',
    SINGLE = 'choice',
    MULTI = 'list',
    FIELD = 'field',
    DATE = 'date',
    DATETIME = 'datetime',
    TIMESTAMP = 'timestamp',
    NESTED = 'nested object',
    QUESTIONS = 'questions',
}

const PropType_keys = enumKeys(PropType)
export const PropType_values = map(PropType_keys, k => PropType[k])

export interface PropChoice {
    display_name: string
    value: number | string
    hide?: boolean
}

export interface PropChoice_Ext extends Omit<PropChoice, 'value'> {
    value: number | string | boolean
}


export const propChoice = exact({
    display_name: dstring,
    value: either(dnumber, dstring),
    hide: optional(dboolean),
})

const propChoiceDecoder = guard(propChoice)

try { const _propChoice: PropChoice = propChoiceDecoder({}) } catch(e) {}


export interface BaseProp {
    type: PropType
    required: boolean
    read_only: boolean
    label: string
    help_text?: string
}

export interface FiniteStringProp extends BaseProp {
    type: PropType.STR
    max_length: number
}

export interface IntegerProp extends BaseProp {
    type: PropType.INT
    min_value: number
    max_value: number
}

export interface ChoiceProp extends BaseProp {
    type: PropType.SINGLE
    choices: PropChoice[]
}

export interface ListProp extends BaseProp {
    type: PropType.MULTI
    child: BaseProp | FiniteStringProp | IntegerProp | ChoiceProp
}

type UnnestedProp = BaseProp | FiniteStringProp | IntegerProp | ChoiceProp | ListProp

export interface UnnestedPropLookup {
    [k: string]: UnnestedProp
}

export interface NestedPropLvl2 extends BaseProp {
    children: UnnestedPropLookup
}

export type AnyProp = UnnestedProp | NestedPropLvl2

export interface AnyPropLookup {
    [k: string]: AnyProp
}

export interface NestedProp extends BaseProp {
    children: AnyPropLookup
}

export interface ChildProp {
    type: PropType.NESTED
    required: boolean
    read_only: boolean
    children: AnyPropLookup
}

export interface HasChildProp extends BaseProp {
    type: PropType.FIELD
    child: ChildProp
}

/** a generic prop definition for easier typing */
export interface Prop extends BaseProp {
    choices?: PropChoice[]
    max_length?: number
    min_value?: number
    max_value?: number
    children?: { [k: string]: AnyProp }
}

const basePropDef = {
    type: oneOf(PropType_values),
    required: dboolean,
    read_only: dboolean,
    label: dstring
}

export const baseProp = exact(basePropDef)

export const finiteStringProp = exact({
    ...basePropDef,
    type: constant<PropType.STR>(PropType.STR),
    max_length: dnumber
})

const integerProp = exact({
    ...basePropDef,
    type: constant<PropType.INT>(PropType.INT),
    min_value: dnumber,
    max_value: dnumber
})

export const choiceProp = exact({
    ...basePropDef,
    type: constant<PropType.SINGLE>(PropType.SINGLE),
    choices: array(propChoice)
})

export const listProp = exact({
    ...basePropDef,
    type: constant<PropType.MULTI>(PropType.MULTI),
    child: either4(baseProp, finiteStringProp, integerProp, choiceProp)
})

const unnestedProp = either5(baseProp, finiteStringProp, integerProp, choiceProp, listProp)

const nestedPropLvl2 = exact({
    ...basePropDef,
    children: dict(unnestedProp)
})

const anyProp = either6(baseProp, finiteStringProp, integerProp, choiceProp, listProp, nestedPropLvl2)

export const nestedPropDef = {
    ...basePropDef,
    children: dict(anyProp)
}

export const nestedProp = exact(nestedPropDef)

export const nestedPropDecoder = guard(nestedProp, { style: 'simple' })

export const hasChildProp = exact({
    ...basePropDef,
    type: constant<PropType.FIELD>(PropType.FIELD),
    child: exact({
        type: constant<PropType.NESTED>(PropType.NESTED),
        required: dboolean,
        read_only: dboolean,
        children: dict(anyProp)
    })
})

export const hasChildPropDecoder = guard(hasChildProp, { style: 'simple' })

// sanity checks that decoders/guards are not missing properties
try { const _baseProp: BaseProp = guard(baseProp)({}) } catch(e) {}
try { const _finiteStringProp: FiniteStringProp = guard(finiteStringProp)({}) } catch(e) {}
try { const _integerProp: IntegerProp = guard(integerProp)({}) } catch(e) {}
try { const _choiceProp: ChoiceProp = guard(choiceProp)({}) } catch(e) {}
try { const _listProp: ListProp = guard(listProp)({}) } catch(e) {}
try { const _unnestedProp: UnnestedProp = guard(unnestedProp)({}) } catch(e) {}
try { const _nestedPropLvl2: NestedPropLvl2 = guard(nestedPropLvl2)({}) } catch(e) {}
try { const _anyProp: AnyProp = guard(anyProp)({}) } catch(e) {}
try { const _nestedProp: NestedProp = nestedPropDecoder({}) } catch(e) {}
try { const _hasChildProp: HasChildProp = guard(hasChildProp)({}) } catch(e) {}
