var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    { attrs: { "no-body": "" } },
    [
      _c(
        "b-card-header",
        {
          directives: [
            {
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: _vm.uid,
              expression: "uid"
            }
          ],
          staticClass: "d-flex align-items-start r-clickable"
        },
        [
          _vm._t("title", function() {
            return [
              _c("h5", { staticClass: "d-inline-block mb-0" }, [
                _vm._v(_vm._s(_vm.title))
              ])
            ]
          }),
          _vm._v(" "),
          _c("collapse-indicator", {
            staticClass: "ml-2 mt-1",
            attrs: { opened: _vm.opened_ }
          })
        ],
        2
      ),
      _vm._v(" "),
      _c("b-card-body", [
        _c("div", { staticClass: "row" }, [
          _vm.summaryHTML
            ? _c(
                "div",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle",
                      value: _vm.uid,
                      expression: "uid"
                    }
                  ],
                  staticClass: "r-clickable",
                  class: { "col-3": _vm.opened_, "col-12": !_vm.opened_ }
                },
                [
                  _c("div", {
                    domProps: { innerHTML: _vm._s(_vm.summaryHTML) }
                  })
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "col" },
            [
              _c(
                "b-collapse",
                {
                  attrs: { id: _vm.uid },
                  on: {
                    show: function($event) {
                      _vm.opened_ = true
                    },
                    hidden: function($event) {
                      _vm.opened_ = false
                    }
                  }
                },
                [_vm._t("default")],
                2
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }