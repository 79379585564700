import { filter, find, forEach, includes, map } from 'lodash-es'
import { LBR, PBR } from './constants'
import { doPlainIndent } from './helpers'
import { TextDetails } from './interfaces'
import { Stay } from 'models/data/stay'
import { Diagnosis } from 'models/med_templates/diagnosis'
import store from 'store'


function mgmtPlanText(stay: Stay, diagnosis: Diagnosis, plain: boolean = true): string {
    if (!stay.management_plan) return ''

    const steps = stay.management_plan.steps
    const plan = diagnosis.management_plan

    const allLines: string[] = []

    forEach(plan, section => {
        const checkedSteps = filter(section.steps, step => includes(steps, step.id))

        if (!checkedSteps.length)
            return

        const lines = map(checkedSteps, step => step.title)
        let title = `${section.title}:`
        title = plain ? title : `**${title}**`
        lines.unshift(title)
        let text = lines.join(LBR)
        text = plain ? doPlainIndent(text) : text
        allLines.push(text)
    })

    return allLines.length ? (allLines.join(PBR) + PBR) : ""
}

export function managementPlan({ stay }: TextDetails, plain: boolean = true): string {
    if (!stay) return ''

    const diagnosis = find(store.direct.getters.templates.allDiagnoses, ["id", stay.diagnosis])
    if (!diagnosis) return ""

    return mgmtPlanText(stay, diagnosis, plain)
}

export function RAMgmtPlan({ stay }: TextDetails): string {
    if (!stay) return ""

    const syndrome = find(store.direct.getters.templates.rapidAsmtSyndromes, ["id", stay.rapid_assessment_syndrome])
    if (!syndrome) return ""

    const diagnosis = find(syndrome.diagnoses, ["id", stay.rapid_assessment_diagnosis])
    if (!diagnosis) return ""

    return mgmtPlanText(stay, diagnosis)
}
