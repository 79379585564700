








import Vue from "vue"
import {  debounce } from "lodash-es"
import tinymce, { Editor, RawEditorSettings } from 'tinymce5'
import 'tinymce5/icons/default'
import 'tinymce5/themes/silver/theme'
import "tinymce5/plugins/textpattern"
import 'tinymce5/plugins/print'
import * as moment from 'moment'
import * as commonmark from "commonmark"
import utils from 'utils'

import { ASMT_WARD, ASMT_ADM } from 'routers/constants'
import stays from '@store/stays'
import { Stay } from 'models/data/stay'
import CopyBtnHighlighter from './CopyBtnHighlighter.vue'
import PrintHelp from './PrintHelp.vue'

interface Data {
    uid: string
    printHelpHtmlID: string
    editor: Editor | null
    height_: number
}

const editorDebounceMS = 500
const copyWaitMS = editorDebounceMS * 1.5

export default Vue.extend({
    components: {
        CopyBtnHighlighter,
        PrintHelp,
    },
    props: {
        htmlID: {
            type: String,
            default: "texteditor"
        },
        height: {
            type: Number,
            default: -1  // pixels
        },
        markup: {
            type: String,
            default: ''
        },
        customHtml: {
            type: String,
            default: ''
        },
        stay_id: {
            type: Number,
            required: false
        },
        readOnly: {
            type: Boolean,
            default: true
        },
        useSignature: {
            type: Boolean,
            default: true
        },
    },
    data(): Data {
        const uid = utils.getUID()
        return {
            uid,
            printHelpHtmlID: '',
            editor: null,
            height_: this.height,
        }
    },
    computed: {
        isIE11(): boolean {
            return window.scrawl.isIE11
        },
        isNurseUser(): boolean {
            return this.$store.direct.getters.user.isNurseUser
        },
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        editorLoaded(): boolean {
            return this.editor !== null
        },
        setEditorContent: function(): any {
            return debounce(this.$_setEditorContent, editorDebounceMS)
        },
        cmReader() {
            return new commonmark.Parser()
        },
        cmWriter() {
            return new commonmark.HtmlRenderer()
        },
        /** content HTML ID */
        cHtmlID(): string {
            if (!this.htmlID || this.htmlID === "texteditor" || this.htmlID === "")
                return `${this.uid}___texteditor`
            return this.htmlID
        },
        finalMarkup(): string {
            const finalMarkup = (this.$store.direct.state.user.appendSig && this.useSignature)
                ? `${this.markup}\n\n${this.$store.direct.state.user.signature}`
                : this.markup
            return finalMarkup
        },
        useCustomHtml(): boolean {
            return this.customHtml.length > 0
        },
        finalHTML(): string {
            return this.useCustomHtml ? this.customHtml : this.cmWriter.render(this.cmReader.parse(this.finalMarkup))
        },
    },
    watch: {
        editor: {
            handler: function(obj: Editor | null) {
                this.setEditorContent(this.finalHTML)
            },
            immediate: true
        },
        finalHTML(val: string) {
            this.setEditorContent(val)
        },
        height(val: number) {
            this.height_ = this.height
        },
        height_: {
            handler: function(val: number) {
                this.refreshEditor()
            },
            immediate: true
        },
        readOnly() {
            this.refreshEditor()
        },
    },
    mounted() {
        window.setTimeout(() => {
            if (this.height_ <= 0) {
                this.height_ = 2000
            }
        }, 1000)
    },
    /**
     * this is available via vue-router - it's not part of the basic Vue lifecycle.
     * using this instead of beforeDestroy since this runs before this component's
     * elements are actually destroyed.
     * editor.remove() runs a save operation, which tries to write data out to the
     * underlying textarea, which then fails if the textrea is already removed.
     */
    beforeRouteLeave() {
        this.removeEditor()
    },
    methods: {
        initEditor(): void {
            const self = this
            const enableDocX = window.scrawl.editorDocX === 'true'
            const exportButtons = enableDocX ? 'copyall downloaddocx' : 'copyall'
            let toolbar = this.readOnly
                ? `${exportButtons} | customprint printhelp`
                : `undo redo | styleselect | bold italic underline | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | ${exportButtons} | customprint printhelp`

            switch (this.$route.name) {
                case ASMT_ADM:
                    toolbar += ' | plainformat'
                    break
                case ASMT_WARD:
                    if (!this.isNurseUser)
                        toolbar += ' | wardgroupissues'
                    toolbar += ' | plainformat'
                    break
            }

            const options: RawEditorSettings = {
                height: self.height_,
                min_height: 1,
                selector: `#${this.cHtmlID}`,

                base_url: `${window.scrawl.baseURL}/static/tinymce5`,
                content_css: `${window.scrawl.baseURL}/static/css/tinymce5.css`,
                // icons_url: `${window.scrawl.baseURL}/static/tinymce5/icons/default/icons.js`,
                // skin: 'oxide',
                // skin_url: `${window.scrawl.baseURL}/static/tinymce5/skins/ui/oxide`,
                init_instance_callback(editor: Editor) {
                    self.editor = editor
                },

                menubar: false,
                plugins: 'print',
                toolbar,

                setup: (editor) => {
                    editor.ui.registry.addButton("copyall", {
                        text: "Copy All",
                        onAction: () => {
                            window.setTimeout(() => {
                                self.$nextTick(() => {
                                    editor.selection.select(editor.getBody(), true)
                                    editor.execCommand("copy")
                                    editor.selection.collapse(true)
                                    Vue.toasted.info("Copied to clipboard")
                                    this.$emit("text-copied", editor.getContent())
                                })
                            }, copyWaitMS)
                        }
                    })

                    editor.ui.registry.addButton('downloaddocx', {
                        text: 'DocX',
                        tooltip: 'Save as Word document (experimental)',
                        onAction: () => {
                            window.setTimeout(() => {
                                self.$nextTick(() => {
                                    const content = editor.getContent()
                                    utils.request
                                    .post('/html-docx/', false, { html: content })
                                    .responseType('blob')
                                    .then(res => {
                                        const mime = res.header['content-type']
                                        const blob = new Blob([res.body], { type: mime })
                                        const dlLink = document.createElement('a')
                                        dlLink.href = window.URL.createObjectURL(blob)
                                        const prefix = this.stay ? `mrn_${this.stay.patient.mrn}_` : ''
                                        dlLink.download = `${prefix}dt_${moment().format('YYYYMMDD_HHmm')}_notes.docx`
                                        document.body.appendChild(dlLink)
                                        dlLink.click()
                                        document.body.removeChild(dlLink)
                                        this.$emit("text-copied", content)
                                    })
                                    .catch(err => {
                                        utils.handleRequestError(err)
                                    })
                                })
                            }, copyWaitMS)
                        },
                    })

                    editor.ui.registry.addButton('customprint', {
                        text: 'Print',
                        tooltip: 'Print (experimental): This should work as expected with modern browsers. Support for older browsers cannot be guaranteed.',
                        onAction: () => {
                            editor.execCommand('mcePrint')
                        },
                    })

                    editor.ui.registry.addButton('printhelp', {
                        text: 'Print Help',
                        tooltip: 'Print Help',
                        icon: 'help',
                        onAction: () => {
                            this.$bvModal.show(this.printHelpHtmlID)
                        }
                    })

                    editor.ui.registry.addToggleButton('wardgroupissues', {
                        text: 'Group Issues',
                        onSetup(api) {
                            api.setActive(self.$store.direct.getters.user.textWardV1)
                            return api => { }
                        },
                        onAction(api) {
                            const mode = !self.$store.direct.getters.user.textWardV1
                            self.$store.direct.dispatch.user.setTextOutputWardV1(mode)
                            api.setActive(mode)
                        }
                    })

                    editor.ui.registry.addToggleButton('plainformat', {
                        text: 'Plain',
                        onSetup(api) {
                            api.setActive(self.$store.direct.getters.user.textOutputPlain)
                            return api => { }
                        },
                        onAction(api) {
                            const mode = !self.$store.direct.getters.user.textOutputPlain
                            self.$store.direct.dispatch.user.setTextOutputPlain(mode)
                            api.setActive(mode)
                        }
                    })
                },
            }

            tinymce.init(options)
        },
        removeEditor() {
            if (this.editor)
                this.editor.remove()
        },
        refreshEditor() {
            if (this.height_ > 0) {
                Vue.toasted.show('[technical] Refreshing editor...')
                this.removeEditor()
                this.$nextTick(() => this.initEditor())
            }
        },
        $_setEditorContent(val: string) {
            if (this.editor) {
                this.editor.setContent(val)
                this.editor.save()
            }
        }
    },
})
