var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("text-base", {
    staticClass: "scrwl-h100",
    attrs: {
      stay_id: _vm.stay_id,
      markup: _vm.markup,
      modalOkTitle: "Mark Admission Complete",
      tag: "adm",
      customMarkComplete: _vm.markAdmissionComplete,
      showExtras: _vm.showExtras
    },
    scopedSlots: _vm._u([
      {
        key: "modalContent",
        fn: function() {
          return [
            _c("p", [_vm._v("Do you want to mark the admission as complete?")])
          ]
        },
        proxy: true
      },
      {
        key: "extras",
        fn: function() {
          return [
            [
              _c("hr"),
              _vm._v(" "),
              _c("h6", [_vm._v("Formatting options")]),
              _vm._v(" "),
              _c("div", [
                _c("div", { staticClass: "pretty p-switch p-fill" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.usePlain,
                        expression: "usePlain"
                      }
                    ],
                    attrs: { type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.usePlain)
                        ? _vm._i(_vm.usePlain, null) > -1
                        : _vm.usePlain
                    },
                    on: {
                      change: function($event) {
                        var $$a = _vm.usePlain,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.usePlain = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.usePlain = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.usePlain = $$c
                        }
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "state p-info" }, [
                    _c("label", [_vm._v("Plain")])
                  ])
                ])
              ])
            ]
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }