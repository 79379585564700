




















import Vue from 'vue'
import { find } from 'lodash-es'

import { Stay } from 'models/data/stay'
import { QuestionCategory } from 'models/med_templates/question'
import { Syndrome } from 'models/med_templates/syndrome'

import stays from '@store/stays'

import AdmissionTime from '@stayinputs/AdmissionTime.vue'
import CareClassification from '@ward/CareClassification.vue'
import LastSeenWell from '@admission/LastSeenWell.vue'
import Questions from '@stayinputs/Questions.vue'
import ThrombolysisTime from '@sections/ThrombolysisTime.vue'

export default Vue.extend({
    components: {
        AdmissionTime,
        CareClassification,
        LastSeenWell,
        Questions,
        ThrombolysisTime,
    },
    props: {
        stay_id: {
            type: Number,
            required: true
        },
        isRapidAssessment: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            QuestionCategory,
        }
    },
    computed: {
        stay(): Stay | undefined {
            return stays.state.stays[this.stay_id]
        },
        isNeurologyStay(): boolean {
            if (!this.stay) return false
            return this.stay.speciality_title.toLowerCase().trim() === 'neurology'
        },
        isThrombolysis(): boolean {
            return stays.isThrombolysis(this.stay_id)
        },
        syndrome(): Syndrome | undefined {
            if (!this.stay)
                return
            if (this.isRapidAssessment)
                return find(this.$store.direct.getters.templates.rapidAsmtSyndromes, ['id', this.stay.rapid_assessment_syndrome])
            else
                return find(this.$store.direct.getters.templates.allSyndromes, ['id', this.stay.syndrome])
        },
    },
})
