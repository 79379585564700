var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("questions", {
        attrs: {
          category: _vm.QuestionCategory.HISTORICAL_MEDS,
          parentObject: _vm.syndrome,
          stay_id: _vm.stay_id
        }
      }),
      _vm._v(" "),
      _c("text-area", {
        staticClass: "mt-3",
        attrs: {
          "non-unique-id": "other-historical-meds",
          label: "Other medications",
          rows: "5"
        },
        model: {
          value: _vm.otherMeds,
          callback: function($$v) {
            _vm.otherMeds = $$v
          },
          expression: "otherMeds"
        }
      }),
      _vm._v(" "),
      _vm.showAllergies
        ? _c("text-area", {
            staticClass: "mt-3",
            attrs: {
              "non-unique-id": "allergies",
              label: "Allergies",
              rows: "5"
            },
            model: {
              value: _vm.allergies,
              callback: function($$v) {
                _vm.allergies = $$v
              },
              expression: "allergies"
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }