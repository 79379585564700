






















import mixins from "vue-typed-mixins"
import utils from 'utils'
import DisplayMixin from "@mixins/Display.vue"
import Editor from "@shared/inputs/TextEditor5.vue"

export default mixins(DisplayMixin).extend({
    components: {
        Editor,
    },
    props: {
        editorHeight: {
            type: Number,
            required: false
        },
        stay_id: {
            type: Number,
            required: true
        },
        markup: {
            type: String,
            required: true
        },
        doConfirm: {
            type: Boolean,
            default: true
        },
        modalOkTitle: {
            type: String,
            default: 'OK'
        },
        customMarkComplete: {
            type: Function,
            default: () => (() => {})
        },
        useSignature: {
            type: Boolean,
            default: true
        },
        showExtras: {
            type: Boolean,
            default: true,
        },
        tag: {
            type: String,
            default: 'rn'
        },
    },
    data() {
        const uid = utils.getUID()
        return {
            confirmHtmlId: `${uid}___conf_modal`,
            notes: '',
            finalHeight: 0,
            readOnly: true,
            showExtras_local: this.showExtras,
        }
    },
    computed: {
        telestrokeMode(): boolean {
            return this.$store.direct.state.user.telestroke_mode
        },
        isTSSiteUser(): boolean {
            return this.$store.direct.getters.user.isTSSiteUser
        },
        RN_recentNote(): string {
            return this.$store.direct.getters.session.RN_recentNote
        },
    },
    mounted() {
        if (this.editorHeight !== undefined)
            this.finalHeight = this.editorHeight
        else {
            const elemHeight = (this.$el as HTMLElement).offsetHeight
            const docHeight = document.body.offsetHeight * 0.6
            this.finalHeight = elemHeight < docHeight ? docHeight : elemHeight
        }
    },
    watch: {
        tag: {
            handler: function(val: string) {
                this.$store.direct.commit.session.RN_tag(val)
                this.$store.direct.commit.session.RN_refreshRecentNotes(this.stay_id)
            },
            immediate: true
        }
    },
    methods: {
        confirmComplete(text: string) {
            if (this.$store.direct.state.session.RN_selected)
                return
            if (this.telestrokeMode && !this.isTSSiteUser)
                return
            this.notes = text
            if (this.doConfirm)
                this.$root.$emit('bv::show::modal', this.confirmHtmlId)
        },
        markComplete() {
            this.customMarkComplete()
            this.$store.direct.commit.session.RN_addRecentNote({ stay_id: this.stay_id, notes: this.notes })
        },
        toggleShowExtras(): void {
            this.showExtras_local = !this.showExtras_local
        },
    },
})
