



























import Vue from 'vue'
import utils from 'utils'
import CollapseIndicator from '@shared/CollapseIndicator.vue'

export default Vue.extend({
    components: {
        CollapseIndicator,
    },
    props: {
        title: {
            type: String,
            default: '?'
        },
        summaryHTML: {
            type: String,
            default: ''
        },
        opened: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            uid: utils.getUID(),
            opened_: this.opened,
        }
    },
})
