var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "card-lapsible",
    { attrs: { title: "Background" } },
    [
      _c(
        "card-lapsible-v-2",
        {
          attrs: {
            title: "Past Medical/Surgical History",
            summaryHTML: _vm.backgroundHTML
          }
        },
        [_c("medical-history", { attrs: { stay_id: _vm.stay_id } })],
        1
      ),
      _vm._v(" "),
      _c(
        "card-lapsible-v-2",
        { attrs: { title: "Medications", summaryHTML: _vm.medsHTML } },
        [_c("historical-medications", { attrs: { stay_id: _vm.stay_id } })],
        1
      ),
      _vm._v(" "),
      _c(
        "card-lapsible-v-2",
        { attrs: { title: "Social History", summaryHTML: _vm.socialHTML } },
        [_c("social-history", { attrs: { stay_id: _vm.stay_id } })],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }